import { createContext } from 'react'
import { OrderItem } from '@typesApp/order'
import { UseFormReturn } from 'react-hook-form'
import { ContactLensFormFields } from '@views/ProductDetails/components/ContactLensSelection/contactLens.schema'

export type RegisterFormParams = {
  trigger: UseFormReturn<ContactLensFormFields>['trigger']
  getValues: UseFormReturn<ContactLensFormFields>['getValues']
  orderItem: OrderItem
  isValid: boolean
}

type RegisterFormCallback = (params: RegisterFormParams) => void

export type CartContextType = {
  createPrescriptionDetails: () => Promise<void>
  registerForm: RegisterFormCallback
  unregisterForm: (orderItemId: string) => void
  validateAllForms: () => Promise<boolean>
  orderItems: OrderItem[]
  orderId: string
  formIsValid: boolean
  validated: boolean
}

export const CartContext = createContext<CartContextType>({
  createPrescriptionDetails: () => {
    return Promise.resolve()
  },
  orderItems: [],
  registerForm: () => {},
  unregisterForm: () => {},
  validateAllForms: () => Promise.resolve(true),
  orderId: '',
  formIsValid: true,
  validated: false,
})
