import React, { forwardRef, useEffect, useState } from 'react'

type ShrinkableOptions = {
  shouldShrink?: boolean
  onFocus?: () => void
  onBlur?: () => void
  name: string
  label: string
}

export const withShrinkableLabel = <Props extends { name: string; label: string; value?: string }>(
  Component: React.ComponentType<ShrinkableOptions>
) => {
  return forwardRef<unknown, Props>((props, ref) => {
    const [focused, setFocused] = useState(false)

    return (
      <Component
        shouldShrink={focused || !!props.value}
        onFocus={() => {
          setFocused(true)
        }}
        onBlur={() => {
          setFocused(false)
        }}
        {...props}
        ref={ref}
      />
    )
  })
}
