import React, { FC, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import { CommerceEnvironment } from '../../../../constants/common'
import { GridContainer, GridItem } from '../../../../components/UI'
import personService from '../../../../foundation/apis/transaction/person.service'
import { PERSONAL_INFO_FORM_FIELDS } from '../../../../constants/user'
import { useSite } from '../../../../foundation/hooks/useSite'
import {
  EMAIL_VALIDATION_PATTERN,
  PHONE_NUMBER_VALIDATION_PATTERN,
  FormControlDateField,
  FormControlRadioGroup,
  FormControlTextField,
  FormControlPhoneNumberField,
} from '../../../../components/UI/Form'
import { StyledFormContainer, StyledHeading } from './PersonalInformationForm.style'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import { SelectOption } from '../../../../components/UI/Form/types'

export interface PersonalInformationFormProps {}

const PersonalInformationForm: FC<PersonalInformationFormProps> = ({}) => {
  const { t } = useTranslation()
  const { mySite } = useSite()
  const { langCode } = useStoreIdentity()
  const DATE_FORMAT = CommerceEnvironment.dateFormat[mySite.locale]
  const formFields = personService.getPersonFormFields(PERSONAL_INFO_FORM_FIELDS)

  const genders = t('PersonalInformationSection.Gender.Options', { returnObjects: true })
  const genderOptions = useMemo<SelectOption[]>(() => {
    return Object.keys(genders).map(key => ({ value: key, label: genders[key] }))
  }, [langCode])

  const formFieldsMap = useMemo(
    () =>
      formFields.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.fieldName]: { required: cur.mandatory },
        }),
        {}
      ),
    [formFields]
  )

  return (
    <StyledFormContainer>
      <GridContainer>
        <GridItem xs={12}>
          <StyledHeading>{t('PersonalInformationSection.FullNameLabel')}</StyledHeading>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormControlTextField
            name="firstName"
            label={t('PersonalInformationSection.Name') || ''}
            inputRegex={/^\D*$/}
            rules={{
              required: {
                value: formFieldsMap['firstName'].required,
                message: t('PersonalInformationSection.Msgs.Empty.firstName'),
              },
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormControlTextField
            name="lastName"
            label={t('PersonalInformationSection.LastName') || ''}
            inputRegex={/^\D*$/}
            rules={{
              required: {
                value: formFieldsMap['lastName'].required,
                message: t('PersonalInformationSection.Msgs.Empty.lastName'),
              },
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <StyledHeading>{t('PersonalInformationSection.ContactInformationLabel')}</StyledHeading>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormControlTextField
            name="email1"
            disabled={true}
            label={t('PersonalInformationSection.EmailAddress') || ''}
            rules={{
              required: {
                value: formFieldsMap['email1'].required,
                message: t('PersonalInformationSection.Msgs.Empty.email1'),
              },
              pattern: {
                value: EMAIL_VALIDATION_PATTERN,
                message: t('PersonalInformationSection.Msgs.Empty.email1'),
              },
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormControlPhoneNumberField
            name="phone1"
            label={t('PersonalInformationSection.PhoneNumber') || ''}
            rules={{
              required: {
                value: formFieldsMap['phone1'].required,
              },
              pattern: {
                value: PHONE_NUMBER_VALIDATION_PATTERN,
                message: t('PersonalInformationSection.Msgs.InvalidPhoneNumber1'),
              },
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12}>
          <StyledHeading>{t('PersonalInformationSection.YourBirthdayLabel')}</StyledHeading>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormControlDateField
            name="dateOfBirth"
            label={t('PersonalInformationSection.DateOfBirth') || ''}
            placeholder={t('PersonalInformationSection.DateOfBirthDescription') || ''}
            description={t('PersonalInformationSection.DateOfBirthDescription') || ''}
            rules={{
              required: {
                value: formFieldsMap['dateOfBirth'].required,
              },
              validation: {
                format: DATE_FORMAT.format,
                message: t('PersonalInformationSection.Msgs.InvalidDateOfBirth'),
              },
            }}
          />
        </GridItem>
      </GridContainer>
      <GridContainer gap={16}>
        <GridItem xs={12} sx={{ marginBottom: 1 }}>
          <StyledHeading>{t('PersonalInformationSection.Gender.Description')}</StyledHeading>
        </GridItem>
        <GridItem xs={12} sm={6}>
          <FormControlRadioGroup data-testid={'personalInformationGender'} name={'gender'} options={genderOptions} />
        </GridItem>
      </GridContainer>
    </StyledFormContainer>
  )
}

export default PersonalInformationForm
