import { useMentionMe } from '@hooks/useMentionMe'
import styles from './styles/index.module.scss'
import MentionMeWrapper from '../MentionMeWrapper'

export const RefereeFind = () => {
  const { isEnabled } = useMentionMe('refereefind', {
    situation: 'checkout',
  })

  return isEnabled ? <MentionMeWrapper id="mmWrappercheckout" className={styles.wrapper} /> : null
}
