import React from 'react'
import { useTheme } from '@mui/material/styles'
import { RxLensDetailsContent, RxLensDetailsTitle, RxLensDetailsWrapper } from './RxLensDetails.style'
import { useTranslation } from 'next-i18next'
import { OrderItemWithRoxProps } from '@typesApp/order'
import FormattedPriceDisplay from '../../../../../components/formatted-price-display'
import { StyledCartRecapDivider } from '../CartRecap.style'
import { RxPrescriptionDetails } from './RxPrescriptionDetails'
import { localStorageUtil } from '../../../../../foundation/utils/storageUtil'
import { v4 as uuid } from 'uuid'
import styles from './styles/CartRecap.module.scss'
import {
  CORRECTION_TYPE,
  FLOW_TYPE_MAP_KEY,
  getLensesReviewObject,
  getRxValuesFromRxLens,
} from '../../../../../components/PrescriptionLenses/RxUtils'
import { getRxLensPrice, getRxLensDiscountedPrice } from '../../../../../utils/rx'
import { calculatePercentageDiscount, parseNumber } from '../../../../../foundation/algolia/algoliaPrice'
import {
  ProductCurrentPriceContainer,
  ProductPriceCurrentPrice,
  ProductPriceDiscountBox,
  ProductPriceInitialPrice,
} from '../../../../ProductDetails/components/ProductPrice.style'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { isEmpty } from 'lodash-es'
import { getSite } from '@foundation/hooks/useSite'
import config from '@configs/index'

export const RxLensDetails: React.FC<{
  orderRxItem: OrderItemWithRoxProps
}> = ({ orderRxItem }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const mySite = getSite()

  const lensesReview = getLensesReviewObject(
    !!config.rxc.isDataLightEnabled
      ? orderRxItem.roxableServices
        ? orderRxItem.roxableServices?.[0]
        : orderRxItem
      : orderRxItem,
    mySite
  )

  const rxLensDetails = orderRxItem.roxableAttributes || {}

  const rxFLowTypeString = localStorageUtil.get(FLOW_TYPE_MAP_KEY) || '[]'
  const rxObject = getRxValuesFromRxLens(orderRxItem.roxableServices ? orderRxItem.roxableServices[0] : {}, {
    fallbackValue: '',
  })
  let correctionType = rxLensDetails?.correctionType || ''
  if (correctionType.toUpperCase() !== CORRECTION_TYPE.PROGRESSIVE) {
    if (rxObject?.leftAdd || rxObject?.rightAdd) {
      correctionType = CORRECTION_TYPE.READING
    }
  }

  let rxFlowTypeMap = new Map(JSON.parse(rxFLowTypeString))

  rxFlowTypeMap.set(orderRxItem?.orderItemId, correctionType.toUpperCase())
  localStorageUtil.set('rxFlowTypeMap', JSON.stringify(Array.from(rxFlowTypeMap.entries())))

  const isNonPrescription = orderRxItem?.roxableAttributes?.correctionType?.toLowerCase() !== 'non_prescription'

  const lensInitialPrice = getRxLensPrice(orderRxItem.roxableServices)
  const lensDiscountedPrice = getRxLensDiscountedPrice(orderRxItem.roxableServices)

  const lensDiscountPercentage = calculatePercentageDiscount(
    parseNumber(lensInitialPrice),
    parseNumber(lensDiscountedPrice)
  )

  const hasInitialPriceDiscount = lensDiscountPercentage > 0

  return (
    <>
      <RxLensDetailsWrapper>
        <div className={styles.rxLensDetailsTitleWrapper}>
          <RxLensDetailsTitle
            expandIcon={<SVGIcon library="arrow" name="arrow-up" color={theme.palette.text.dark.primary} />}
            aria-controls="rx-lenses-details-content"
            id="rx-lenses-details-header"
          >
            {t('CartRecap.Labels.YourLenses')}
          </RxLensDetailsTitle>
          <div className={styles.rxLensPriceContainer}>
            <div className={styles.rxLensPrices}>
              {hasInitialPriceDiscount && (
                <ProductPriceInitialPrice>
                  <FormattedPriceDisplay min={lensInitialPrice} currency={orderRxItem.currency} />
                </ProductPriceInitialPrice>
              )}
              <ProductCurrentPriceContainer>
                <ProductPriceCurrentPrice isCompact>
                  <FormattedPriceDisplay
                    min={hasInitialPriceDiscount ? lensDiscountedPrice : lensInitialPrice}
                    currency={orderRxItem.currency}
                  />
                </ProductPriceCurrentPrice>
              </ProductCurrentPriceContainer>
            </div>
            {hasInitialPriceDiscount && (
              <ProductPriceDiscountBox>
                {Math.round(lensDiscountPercentage)}
                {t('ProductTile.Labels.off')}
              </ProductPriceDiscountBox>
            )}
          </div>
        </div>

        <RxLensDetailsContent>
          {(!isEmpty(lensesReview) && (
            <div className={styles.attributesListContainer}>
              {lensesReview.map(reviewItem => {
                return (
                  <div className={styles.attributesListItem} key={`${uuid()}_${lensesReview[reviewItem.key]}`}>
                    <div> {reviewItem.key}</div>
                    <div> {reviewItem.description}</div>
                  </div>
                )
              })}
            </div>
          )) ||
            (!!rxLensDetails && (
              <div className={styles.attributesListContainer}>
                {Object.keys(rxLensDetails).map(key => {
                  return (
                    <div className={styles.attributesListItem} key={`${uuid()}_${rxLensDetails[key]}`}>
                      <span>{(key === 'correctionType' && correctionType?.toLowerCase()) || rxLensDetails[key]}</span>
                    </div>
                  )
                })}
              </div>
            ))}
        </RxLensDetailsContent>
      </RxLensDetailsWrapper>
      {isNonPrescription && (
        <>
          <StyledCartRecapDivider />
          <RxPrescriptionDetails orderItem={orderRxItem} />
        </>
      )}
    </>
  )
}
