import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { Control, FormProvider, useForm, useWatch } from 'react-hook-form'
import { PaymentMethodSelectionProps } from '../PaymentMethodSelection'
import { GridContainer, GridItem, PreLoader } from '../../../../../components/UI'
import { creditCardNumberSelector } from '../../../../../features/order/selector'
import { setCreditCardNumber } from '../../../../../features/order/slice'
import { CreditCardFormDataType } from '@typesApp/checkout'
import { StyledCheckoutActions } from '../../../Checkout.style'
import { CardProviderIcon, useCardValidation } from '../../../../../components/UI/CreditCardField'
import { FormControlMaskTextField, FormControlTextField } from '../../../../../components/UI/Form'
import { creditCardFieldValidationConfig } from '../../../../PaymentMethods/config/CreditCardFieldsConfig'
import {
  PaymentMethodSelectionCVVHint,
  PaymentMethodSelectionCVVImage,
  PaymentMethodSelectionCVVLink,
  PaymentMethodSelectionCVVText,
} from '../../../payment/Payment.style'
import { useExpiryDate } from '../../../payment/useExpiryDate'
import { useCardPaymentMethodListener } from './useCardPaymentMethodListener'
import { FormControlCheckboxField } from '../../../../../components/UI/Form/FormControlCheckboxField'
import { loginStatusSelector } from '../../../../../redux/selectors/user'
import { StyledPaymentCTA } from './CardPaymentForm.style'
import config from '@configs/index'

type CardPaymentFormData = Pick<
  CreditCardFormDataType,
  'cc_account' | 'cc_nameoncard' | 'expiration_date' | 'cc_cvc' | 'createWallet'
>

type CardPaymentFormProps = Pick<
  PaymentMethodSelectionProps,
  'paymentMethodId' | 'paymentsList' | 'paymentProcessing' | 'onSubmit' | 'togglePayOption'
>

export const CardPaymentForm: React.FC<CardPaymentFormProps> = ({
  paymentMethodId,
  paymentsList,
  paymentProcessing,
  onSubmit: onSubmitPayment,
  togglePayOption,
}) => {
  const { t: translate } = useTranslation('', { keyPrefix: 'PaymentMethod.CreditCardForm' })
  const dispatch = useDispatch()
  const cardNameFieldRef = useRef<HTMLDivElement | null>(null)
  const creditCardNumber = useSelector(creditCardNumberSelector)
  const isLoggedIn = useSelector(loginStatusSelector) || false
  const validationConfig = creditCardFieldValidationConfig()
  const [isCVVHintOpen, setIsCVVHintOpen] = useState(false)
  const { formatExpiryDate } = useExpiryDate()
  const { isValidCardNumber, isValidExpiryDate, isValidCVV } = useCardValidation()
  const cvvImgPath = `${config.publicUrl}images/common/cvv-code.png`
  const expiryDateMask: string[] = translate('expiryDateMask', { returnObjects: true }) || ['M', 'M', 'Y', 'Y']

  const initialValues: CardPaymentFormData = {
    cc_account: creditCardNumber || '',
    expiration_date: '',
    cc_cvc: '',
    cc_nameoncard: '',
    createWallet: false,
  }

  const methods = useForm<CardPaymentFormData>({
    defaultValues: {
      ...initialValues,
    },
  })

  const onSubmit = (formData: CardPaymentFormData) => {
    const data = patchData(formData)

    if (onSubmitPayment && !paymentProcessing) {
      const cardNumber = methods.getValues('cc_account')
      dispatch(setCreditCardNumber(cardNumber || ''))
      onSubmitPayment(data)
    }
  }

  const patchData = (formData: CardPaymentFormData): CardPaymentFormData => {
    const data = {
      ...formData,
      expiration_date: formatExpiryDate(formData.expiration_date),
    }
    return data
  }

  const paymentMethod = useCardPaymentMethodListener(methods.control, paymentsList)

  useEffect(() => {
    if (paymentMethod && togglePayOption) {
      togglePayOption(paymentMethod.paymentMethodName)
    }
  }, [paymentMethod])

  useEffect(() => {
    if (cardNameFieldRef?.current) {
      const input = cardNameFieldRef.current.querySelector<HTMLInputElement>('input[name="cc_nameoncard"]')
      setTimeout(() => {
        input?.focus()
      }, 500)
    }
  }, [paymentMethodId])

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} id="credit-card-payment-form">
          <GridContainer gap={16}>
            <GridItem xs={12} sm={10} md={12} lg={10} ref={cardNameFieldRef}>
              <FormControlTextField
                name="cc_nameoncard"
                label={translate('Labels.cardHolderName')}
                description={translate('Labels.cardHolderName-description')}
                inputRegex={/^\D*$/}
                rules={{
                  required: {
                    value: validationConfig.cardHolderName.required,
                    message: translate('Msgs.Empty.cardHolderName'),
                  },
                  pattern: {
                    value: validationConfig.cardHolderName.regExp,
                    message: translate('Msgs.Empty.cardHolderName'),
                  },
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={10} md={12} lg={10}>
              <FormControlMaskTextField
                name="cc_account"
                label={translate('Labels.cardNumber')}
                inputProps={{ maxLength: 19 }}
                format="#### #### #### ####"
                invalidValuesRegexp={/\D/g}
                customInputProps={{
                  endAdornment: <CreditCardAdornment control={methods.control} />,
                }}
                rules={{
                  required: {
                    value: validationConfig.cardNumber.required,
                    message: translate('Msgs.Empty.cardNumber'),
                  },
                  validate: (value: string) => {
                    return isValidCardNumber(value) || translate('Msgs.Invalid.cardNumber')
                  },
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={5} lg={4} xl={3}>
              <FormControlMaskTextField
                name="expiration_date"
                label={translate('Labels.expiryDate')}
                description={translate('Labels.expiryDate-description')}
                format="## / ##"
                invalidValuesRegexp={/\D/g}
                rules={{
                  required: {
                    value: validationConfig.expiryDate.required,
                    message: translate('Msgs.Empty.expiryDate'),
                  },
                  validate: (value: string) => {
                    return isValidExpiryDate(value, expiryDateMask) || translate('Msgs.Invalid.expiryDate')
                  },
                }}
              />
            </GridItem>
            <GridItem xs={12}>
              <GridContainer gap={16}>
                <GridItem xs={12} sm={5} lg={4} xl={3}>
                  <FormControlTextField
                    name="cc_cvc"
                    label={translate('Labels.cvv')}
                    description={
                      <PaymentMethodSelectionCVVLink onClick={() => setIsCVVHintOpen(!isCVVHintOpen)}>
                        {translate('Labels.cvvHelpText')}
                      </PaymentMethodSelectionCVVLink>
                    }
                    inputProps={{ maxLength: 4 }}
                    inputRegex={/^(\d{0,4})+$/}
                    rules={{
                      required: {
                        value: validationConfig.cvv.required,
                        message: translate('Msgs.Empty.cvv'),
                      },
                      pattern: {
                        value: validationConfig.cvv.regExp,
                        message: translate('Msgs.Invalid.cvv'),
                      },
                      validate: (cvv: string) => {
                        return isValidCVV(methods.getValues('cc_account'), cvv) || translate('Msgs.Invalid.cvv')
                      },
                    }}
                  />
                </GridItem>
                {isCVVHintOpen && (
                  <PaymentMethodSelectionCVVHint xs={12} sm={7} lg={8} xl={9}>
                    <PaymentMethodSelectionCVVImage src={cvvImgPath} alt={'CVV Hint'} />
                    <PaymentMethodSelectionCVVText>{translate('Msgs.CVVHint')}</PaymentMethodSelectionCVVText>
                  </PaymentMethodSelectionCVVHint>
                )}
              </GridContainer>
            </GridItem>
            {isLoggedIn && (
              <GridItem xs={12} sm={10} md={12} lg={10}>
                <FormControlCheckboxField
                  name="createWallet"
                  rules={{
                    required: false,
                  }}
                  label={translate('Labels.savePaymentMethod')}
                />
              </GridItem>
            )}
          </GridContainer>
        </form>
      </FormProvider>
      <SubmitAction isBusy={paymentProcessing} />
    </>
  )
}

export interface CreditCardAdornmentProps {
  control: Control<CardPaymentFormData>
}

const CreditCardAdornment: React.FC<CreditCardAdornmentProps> = ({ control }) => {
  const cardNumber = useWatch({ control, name: 'cc_account' })
  return <CardProviderIcon cardNumber={cardNumber || ''} />
}

export interface SubmitActionProps {
  isBusy: boolean
}

const SubmitAction: React.FC<SubmitActionProps> = ({ isBusy = false }) => {
  const { t } = useTranslation()
  const buttonText = t('Payment.Actions.Next')
  const idText = buttonText.replace(' ', '_')

  return (
    <StyledCheckoutActions>
      <StyledPaymentCTA
        form="credit-card-payment-form"
        type="submit"
        data-element-id={`X_Checkout_${idText}-CTA`}
        data-testid={'completeOrder'}
        variant="primary"
        disabled={isBusy}
      >
        {isBusy && <PreLoader fill={'light'} withButton />}
        {buttonText}
      </StyledPaymentCTA>
    </StyledCheckoutActions>
  )
}
