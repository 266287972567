import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { EMPTY_STRING } from '../../../constants/common'
import * as userAction from '../../../redux/actions/user'
import { SKIP_WC_TOKEN_HEADER } from '../../../foundation/constants/common'
import { RESET_ERROR_ACTION } from '../../../redux/actions/error'
import { SIGNIN } from '../../../constants/routes'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { useYupValidationResolver } from '../../../utils/validationResolver'
import { Box, Button } from '@mui/material'
import { StyledTypography, TextField } from '../../../components/UI'
import { HeaderPaper } from '../../SignUp/styles/RegistrationLayout.style'
import { useRouter } from 'next/router'

export interface IProps {
  errorMessageKey: string
  currentEmail: string
  payloadBase: object
}

export interface FormData {
  logonPassword: string
  logonPasswordNew: string
  logonPasswordVerify: string
}

const defaultValues: FormData = {
  logonPassword: '',
  logonPasswordNew: '',
  logonPasswordVerify: '',
}

const LogonAndChangePasswordForm: React.FC<IProps> = ({ errorMessageKey, currentEmail, payloadBase }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const router = useRouter()

  const schema = yup.object().shape({
    logonPassword: yup.string().required(t('Msgs.InvalidFormat')),
    logonPasswordNew: yup.string().matches(/^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9]{6,}$/, t('Msgs.InvalidPassword')),
    logonPasswordVerify: yup.string().oneOf([yup.ref('logonPasswordNew'), null], t('Msgs.PasswordNotMatch')),
  })

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<FormData>({
    defaultValues,
    resolver: useYupValidationResolver(schema),
  })

  const handleLogonAndChangePasswordSubmit = (data: FormData) => {
    const { logonPassword, logonPasswordNew, logonPasswordVerify } = data
    dispatch(
      userAction.LOGON_AND_CHANGE_PASSWORD_ACTION({
        body: {
          logonId: currentEmail,
          logonPassword,
          logonPasswordNew,
          logonPasswordVerify,
        },
        [SKIP_WC_TOKEN_HEADER]: true,
        skipErrorSnackbar: true,
        ...payloadBase,
      })
    )
  }

  const goToSignInPage = () => {
    dispatch(RESET_ERROR_ACTION())
    router.push(SIGNIN)
  }

  return (
    <>
      <HeaderPaper>
        <StyledTypography component="h1" variant="h4" className="bottom-margin-1" data-testid={'changePasswordTitle'}>
          {t('SignIn.ChangPassword')}
        </StyledTypography>
        <StyledTypography component="p" className="error">
          {t(errorMessageKey)}
        </StyledTypography>
      </HeaderPaper>
      <div>
        <form noValidate name="changePasswordForm" onSubmit={handleSubmit(handleLogonAndChangePasswordSubmit)}>
          <TextField
            {...register('logonPassword')}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            autoFocus
            name="password"
            label={t('SignIn.CurrentPassword')}
            inputProps={{
              maxLength: 100,
              type: 'password',
            }}
            error={!!errors.logonPassword}
            helperText={errors.logonPassword?.message}
          />
          <TextField
            {...register('logonPasswordNew')}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t('SignIn.Password')}
            type="password"
            placeholder={EMPTY_STRING}
            error={!!errors.logonPasswordNew}
            helperText={errors.logonPasswordNew?.message}
          />
          <TextField
            {...register('logonPasswordVerify')}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={t('SignIn.PasswordVerify')}
            type="password"
            placeholder={EMPTY_STRING}
            error={!!errors.logonPasswordVerify}
            helperText={errors.logonPasswordVerify?.message}
          />
          <Box mt={2}>
            <Button color={'primary'} type="submit">
              {t('SignIn.Submit')}
            </Button>
            <Button color="secondary" style={{ float: 'right' }} onClick={() => goToSignInPage()}>
              {t('SignIn.Cancel')}
            </Button>
          </Box>
        </form>
      </div>
    </>
  )
}

export default LogonAndChangePasswordForm
