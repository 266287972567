import * as userAction from '../../../redux/actions/user'

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import useBreakpoints from '../../../hooks/useBreakpoints'
import {
  StyledSignInContainer,
  StyledForgotYourPasswordLink,
  StyledFYPLinkWrapper,
  StyledSignInButton,
  StyledSignInSubtitle,
  StyledSignInTitle,
  StyledErrorLoginMessage,
  StyledHeader,
  SignInContentDiv,
} from '../styles/SignInForm.style'
import { useTranslation } from 'next-i18next'
import { ForgotPassword } from '../../../components/ForgotPassword/ForgotPassword'
import { useCSRForUser } from '../../../foundation/hooks/useCSRForUser'
import { RESET_ERROR_ACTION } from '@redux/actions/error'
import { genericErrorSelector } from '../../../redux/selectors/error'
import { FormControlTextField, FormControlPasswordField, EMAIL_VALIDATION_PATTERN } from '../../../components/UI/Form'
import { ModalDialog } from '../../../components/UI/ModalDialogV2'
import { PreLoader } from '../../../components/UI'
import { VIRTUAL_PAGE_VIEW_EVENT_ID, sendAnalyticEventRaw } from '../../../foundation/analytics/tealium/lib'
import { ANALYTICS_PAGE_TYPE, useAnalyticsData } from '../../../foundation/hooks/useAnalyticsData'
import { LoginAndRegistrationData } from '../../../foundation/analytics/tealium/interfaces'
import { CART } from '../../../constants/routes'
import { cartSelector } from '../../../features/order/selector'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { useSearchParams } from 'next/navigation'
import { sendLoginEvent } from '@foundation/analytics/tealium/lib'
import { loginStatusSelector, userDetailsSelector } from '@redux/selectors/user'
export interface SignInFormProps {
  setCurrentEmail: (email: string) => void
  payloadBase: object
}

export interface FormData {
  email: string
  password: string
}

const defaultValues: FormData = {
  email: '',
  password: '',
}

const SignInForm: React.FC<SignInFormProps> = ({ setCurrentEmail, payloadBase }) => {
  const { ...analyticsData } = useAnalyticsData(ANALYTICS_PAGE_TYPE.LOGIN_AND_REGISTER)
  const searchParams = useSearchParams()
  const { isDesktop } = useBreakpoints()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { isAuthenticationError } = useCSRForUser()
  const error = useSelector(genericErrorSelector)
  const isErrorLogin = isAuthenticationError(error)
  const [isForgotPasswordOpen, setForgotPasswordOpen] = useState(false)
  const [isStepResetPassWordOpen, setStepResetPasswordOpen] = useState(false)
  const [busy, setBusy] = useState(false)
  const cart = useSelector(cartSelector)
  const { langCode } = useStoreIdentity()
  const handleOpen = () => setForgotPasswordOpen(true)
  const handleClose = () => setForgotPasswordOpen(false)
  const handleOpenStepResetPassword = () => {
    setForgotPasswordOpen(true)
    setStepResetPasswordOpen(true)
  }
  const userLoggedIn = useSelector(loginStatusSelector) || false
  const userDetails = useSelector(userDetailsSelector)
  const commonAnalyticsData = useAnalyticsData(ANALYTICS_PAGE_TYPE.HOME)

  useEffect(() => {
    const dataToSend: LoginAndRegistrationData = {
      ...analyticsData,
      id: VIRTUAL_PAGE_VIEW_EVENT_ID,
      Page_Type: 'Login&Register',
      Page_Design: 'Login or Register',
      Page_Section1: 'Account',
      Order_CartId: cart?.orderId || '',
      Order_CartUrl: `/${langCode}/${CART}`,
    }
    sendAnalyticEventRaw(dataToSend)
  }, [])

  const validationUrlParams = searchParams.get('validation')

  const methods = useForm<FormData>({
    defaultValues,
  })

  useEffect(() => {
    validationUrlParams !== null && handleOpenStepResetPassword()
  }, [validationUrlParams])

  useEffect(() => {
    const hasError = Boolean(error?.errorCode) || Boolean(error?.errorKey)
    if (hasError && busy) {
      setBusy(false)
    }
  }, [busy, error])

  useEffect(() => {
    if (userLoggedIn && userDetails) {
      sendLoginEvent(userDetails, commonAnalyticsData)
    }
  }, [userLoggedIn, userDetails])

  const onSubmit = (data: FormData) => {
    dispatch(RESET_ERROR_ACTION())
    setCurrentEmail(data.email)
    setBusy(true)

    dispatch(
      userAction.LOGIN_REQUESTED_ACTION({
        body: {
          logonId: data.email,
          logonPassword: data.password,
        },
        ...payloadBase,
      })
    )
  }

  return (
    <StyledSignInContainer>
      <StyledHeader>
        <StyledSignInTitle data-testid={'loginTitle'}>
          {isDesktop ? t('SignInPage.Title') : t('SignInPage.TitleMob')}
        </StyledSignInTitle>
        <StyledSignInSubtitle>{t('SignInPage.Subtitle')}</StyledSignInSubtitle>
      </StyledHeader>

      <ForgotPasswordModal
        open={isForgotPasswordOpen}
        onClose={handleClose}
        openResetPassword={isStepResetPassWordOpen}
      />

      <SignInContentDiv>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            {isErrorLogin && !busy && (
              <StyledErrorLoginMessage>{t('SignInPage.Messages.ErrorLogin')}</StyledErrorLoginMessage>
            )}

            <FormControlTextField
              name="email"
              label={t('SignInPage.Fields.Email.Text')}
              rules={{
                required: {
                  value: true,
                  message: t('SignInPage.Fields.Email.Empty'),
                },
                pattern: {
                  value: EMAIL_VALIDATION_PATTERN,
                  message: t('SignInPage.Fields.Email.Invalid'),
                },
              }}
            />
            <FormControlPasswordField
              name="password"
              label={t('SignInPage.Fields.Password.Text')}
              autoComplete="current-password"
              id="outlined-password-input"
              rules={{
                required: {
                  value: true,
                  message: t('SignInPage.Fields.Password.Empty'),
                },
              }}
            />
            <StyledFYPLinkWrapper>
              <StyledForgotYourPasswordLink onClick={handleOpen}>
                {t('SignInPage.CTA.ForgotPassword')}
              </StyledForgotYourPasswordLink>
            </StyledFYPLinkWrapper>
            <div>
              <StyledSignInButton
                data-element-id="X_X_LoginForm_Login"
                data-testid={'loginSubmit'}
                type="submit"
                disabled={busy}
                variant="primary"
              >
                {busy && <PreLoader fill={'light'} withButton />}
                {t('SignInPage.CTA.SignIn')}
              </StyledSignInButton>
            </div>
          </form>
        </FormProvider>
      </SignInContentDiv>
    </StyledSignInContainer>
  )
}

export interface ForgotPasswordModalProps {
  open: boolean
  openResetPassword: boolean
  onClose: () => void
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({ open, openResetPassword, onClose }) => {
  return (
    <ModalDialog
      onClose={onClose}
      hideFooter
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ForgotPassword handleClose={onClose} openStepResetPassword={openResetPassword} />
    </ModalDialog>
  )
}

export default SignInForm
