import React, { useCallback, useRef } from 'react'
import PrescriptionLenses from './PrescriptionLenses'
import { useDispatch, useSelector } from 'react-redux'
import { CART } from '../../constants/routes'
import { useRouter } from 'next/router'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { setAddToCartBusy, startEditingCartItem } from '../../features/cartui/cartuiSlice'
import { useEditCartItemParams } from '../../hooks/useEditCartItemParams'
import { currentProductBundleSelector } from '../../features/product/selector'
import { useAddOrderItem } from '@views/ProductDetails/hooks/useAddOrderItem'
import { virtualMirrorUtils } from '@utils/virtualMirrorUtils'
import { setPrescriptionLensFormOpen } from '@features/rox/roxSlice'
import { RX_CONF_SELECTOR } from '@constants/common'

export const Prescription: React.FC<{
  setSelectLensesBusy?: React.Dispatch<boolean>
}> = ({ setSelectLensesBusy }) => {
  const { langCode } = useStoreIdentity()
  const router = useRouter()
  const dispatch = useDispatch()
  const { isEditLens } = useEditCartItemParams()

  const { product, orderItem } = useSelector(currentProductBundleSelector)

  const { handleAddToCartLenses } = useAddOrderItem(orderItem, product, product?.partNumber, ['1'])

  const hasAddedToCartRef = useRef(false)

  const addToBag = useCallback(
    async (_, lensObject, _reviewObject, ...args) => {
      // Prevent multiple cart additions

      if (hasAddedToCartRef.current) return

      hasAddedToCartRef.current = true

      try {
        if (isEditLens) {
          dispatch(startEditingCartItem())
        }
        await handleAddToCartLenses(_, lensObject, args)
        virtualMirrorUtils.unmountVirtualMirror()
      } catch (error) {
        // Reset the flag if there's an error
        hasAddedToCartRef.current = false
        console.error('Error adding to cart:', error)
      } finally {
        if (!setSelectLensesBusy) {
          dispatch(setPrescriptionLensFormOpen(false))
        }
      }
    },
    [isEditLens, handleAddToCartLenses, dispatch]
  )

  const onClose = async (isAddToCart?: boolean) => {
    if (isAddToCart) {
      setSelectLensesBusy && setSelectLensesBusy(true)
      if (isEditLens) {
        await router.push(`/${langCode}/${CART}`)
      }
    } else {
      setSelectLensesBusy && setSelectLensesBusy(false)
      dispatch(setAddToCartBusy(false))
      dispatch(setPrescriptionLensFormOpen(false))
      const { pathname, query } = router
      const updatedQuery = { ...query }
      delete updatedQuery.rxcnav
      await router.replace({ pathname, query: updatedQuery }, undefined, { shallow: true })
    }
    window?.RXC?.rxcWidget?.close(`#${RX_CONF_SELECTOR}`)
  }

  if (!product) return null

  return (
    <PrescriptionLenses
      isFromCart={isEditLens}
      product={product}
      onClose={onClose}
      onAddToBag={addToBag}
      categoryHref={product?.seo?.href}
    />
  )
}
