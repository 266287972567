import { useDispatch, useSelector } from 'react-redux'
import { ImageryType } from '../../../components/PrescriptionLenses/PrescriptionLenses'
import { RX_CONF_SELECTOR } from '../../../constants/common'
import { CART } from '../../../constants/routes'
import {
  orderApi,
  useCreatePrescriptionDetailsMutation,
  useLazyGetCartQuery,
  useRemoveOrderItemMutation,
} from '../../../features/order/query'
import { addItem } from '../../../features/order/thunks'
import { doneEditingCartItem, setAddToCartBusy } from '../../../features/cartui/cartuiSlice'
import addContactLenses, { ContactLensesAddItemArgs } from '../../../features/order/thunks/addContactLenses'
import addLensItem, { IAddLensArgs } from '../../../features/order/thunks/addLensItem'
import { productContactLensesDataSelector, productAddToCartEnabledSelector } from '../../../features/product/selector'
import { sendAddToCartEvent } from '../../../foundation/analytics/tealium/lib'
import { useSite } from '../../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { OrderItem } from '../../../types/order'
import { ContactLensesData, IProduct } from '../../../types/product'
import { LensObject } from '../../../types/rxConfigurator'
import { getCurrentProductItemBean, isCLAccessoriesProduct, isContactLensesProduct } from '../../../utils/product'
import { useEditCartItemParams } from '../../../hooks/useEditCartItemParams'
import { getInsuranceEventModule } from '../../../components/DirectBilling'
import Log from '../../../services/Log'
import { ANALYTICS_PAGE_TYPE, getDiscount, useAnalyticsData } from '../../../foundation/hooks/useAnalyticsData'
import { determineAlgoliaPrice, PartNumberAlgoliaPrice } from '../../../foundation/algolia/algoliaPrice'
import guestIdentityService from '../../../foundation/apis/transaction/guestIdentity.service'
import { GUEST_LOGIN_SUCCESS_ACTION } from '../../../redux/actions/user'
import { localStorageUtil, storageSessionHandler } from '../../../foundation/utils/storageUtil'
import { useRouter } from 'next/router'
import { userDetailsSelector } from '@redux/selectors/user'
import { subscriptionConfigSelector } from '@features/subscription/selector'
import { SubscriptionInfo } from '@typesApp/subscription'
import {
  LensesReviewRoxObjectInterface,
  MDT_DISCOUNT_AMOUNT,
  MDT_DISCOUNT_SEGMENT,
} from '@components/PrescriptionLenses/RxUtils'
import { useCustomerSegmentsUtil } from '@utils/Cookies'
import { getIsRoxable } from '@utils/productAttributes'
import { PRODUCT_TYPES_KEYS } from '@constants/product'
import config from '@configs/index'
import { RX_SAVED_LENS_DATA_KEY } from '@constants/rxConfigurator'
import { useHandleContactLensCallback } from '@views/ProductDetails/hooks/useHandleContactLensCallback'

interface AddToCartProps {
  isClItem?: boolean
  isLenses?: boolean
  product?: IProduct
  fromConfigurator?: boolean
  lensObject?: LensObject
  images?: ImageryType
  fromRxPanel?: boolean
  lensesReviewObject?: LensesReviewRoxObjectInterface
}

type OnContactLensAddToCartProps = {
  isLenses: boolean
}

type OnContactLensAddToCart = (props: OnContactLensAddToCartProps) => void

export const useAddOrderItem = (
  productInCart?: OrderItem,
  currentProduct?: IProduct,
  partNumber?: string,
  productQuantity?: string[],
  subscriptionInfo?: SubscriptionInfo,
  algoliaPrices?: PartNumberAlgoliaPrice
) => {
  const { mySite } = useSite()
  const router = useRouter()
  const [updateCart] = useLazyGetCartQuery()

  const { enabled: isSubscriptionEnabled } = useSelector(subscriptionConfigSelector)
  const { langId, langCode } = useStoreIdentity()
  const dispatch = useDispatch()
  const { interceptAddCartItem } = getInsuranceEventModule()
  const contactLensData = useSelector(productContactLensesDataSelector)
  const addToCartEnabled = useSelector(productAddToCartEnabledSelector)
  const [removeOrderItem] = useRemoveOrderItemMutation()
  const isClAccessory = isCLAccessoriesProduct(currentProduct)
  const isCl = isContactLensesProduct(currentProduct)
  const { isEditLens, isEditContactLens } = useEditCartItemParams()
  const { ...analyticsDataForCart } = useAnalyticsData(ANALYTICS_PAGE_TYPE.CART)
  const customerSegments = useCustomerSegmentsUtil()
  const productSubscriptionInfo =
    isSubscriptionEnabled && currentProduct && subscriptionInfo?.identifier === currentProduct.partNumber
      ? subscriptionInfo
      : undefined
  const { onContactLensAddToCart } = useHandleContactLensCallback()

  const buildContactLensesPayload = (
    contactLensData: ContactLensesData,
    discount: { amount: string; customerSegment: string }
  ): ContactLensesAddItemArgs['items'] => {
    const clPayload: ContactLensesAddItemArgs['items'] = !!contactLensData
      ? Object.values(contactLensData).map(entry => {
          let singleLensData = { ...entry }
          let { quantity } = singleLensData
          delete singleLensData.quantity
          if (singleLensData?.x_dominance === '') {
            delete singleLensData.x_dominance
          }
          if (singleLensData.x_axis) {
            singleLensData = {
              ...singleLensData,
              //The BE does not accept values without the leading zeroes, but the FE should hide them
              x_axis: String(singleLensData.x_axis || '0').padStart(3, '0'),
            }
          }
          return {
            x_contactLens: {
              ...singleLensData,
            },
            quantity: quantity || '',
            orderItemExtendAttribute: [
              {
                attributeName: MDT_DISCOUNT_AMOUNT,
                attributeType: 'String',
                attributeValue: discount?.amount ?? '',
              },
              {
                attributeName: MDT_DISCOUNT_SEGMENT,
                attributeType: 'String',
                attributeValue: discount?.customerSegment ?? '',
              },
            ],
          }
        })
      : []
    return clPayload
  }

  let userInfo = useSelector(userDetailsSelector)

  const addItemToCart = async ({
    isClItem,
    isLenses,
    fromConfigurator,
    lensObject,
    product,
    fromRxPanel,
    lensesReviewObject,
  }: AddToCartProps) => {
    const currentUser = storageSessionHandler.getCurrentUserAndLoadAccount()
    const algoliaPrice = algoliaPrices?.[currentProduct?.partNumber ?? '']
      ? algoliaPrices?.[currentProduct?.partNumber ?? '']
      : determineAlgoliaPrice(
          currentProduct?.x_price,
          customerSegments,
          currentProduct?.type === PRODUCT_TYPES_KEYS.SUN ? PRODUCT_TYPES_KEYS.FRAMES : currentProduct?.type,
          getIsRoxable(currentProduct ?? ({} as IProduct)),
          isLenses,
          false
        )

    const { listPrice, offerPrice, segment } = algoliaPrice ?? {}
    if (!currentUser) {
      const guestResponse = await guestIdentityService.login({})

      dispatch(GUEST_LOGIN_SUCCESS_ACTION(guestResponse.data))
    }
    if (isClItem) {
      const contactLensesParams: ContactLensesAddItemArgs = {
        items: contactLensData
          ? buildContactLensesPayload(
              contactLensData,
              getDiscount(
                parseFloat(listPrice?.toString() ?? '0'),
                parseFloat(offerPrice?.toString() ?? '0'),
                segment ?? ''
              )
            )
          : [],
        product: currentProduct,
        subscriptionInfo: productSubscriptionInfo,
        callback: data => {
          sendAddToCartEventConditionally(isLenses ?? false)
          onContactLensAddToCart({ order: data })
        },
        langId: langId,
      }
      dispatch(addContactLenses(contactLensesParams))
    } else if (isLenses) {
      const params: IAddLensArgs = {
        params: {
          catentryId: lensObject?.lensPackage.catEntryId || '',
          langId,
          partnumber: partNumber || '',
          product: currentProduct,
          quantity: ['1'],
          lensesReviewObject,
          lensDiscount: getDiscount(
            parseFloat(lensObject?.lensPackage?.listPrice ?? '0'),
            parseFloat(lensObject?.lensPackage?.offerPrice ?? '0'),
            segment ?? ''
          ),
          frameDiscount: getDiscount(
            parseFloat(listPrice?.toString() ?? '0'),
            parseFloat(offerPrice?.toString() ?? '0'),
            segment ?? ''
          ),
          lensObject,
        },
        callback: () => {
          dispatch(doneEditingCartItem())
          sendAddToCartEventConditionally(isLenses)
          router.push(`/${langCode}/${CART}`)
        },
        siteInfo: mySite,
      }
      dispatch(addLensItem(params))
    } else {
      const accessoryPartNumber = getCurrentProductItemBean(currentProduct)?.partNumber ?? ''
      const params = {
        isClAccessory,
        storeId: mySite.storeID,
        partnumber:
          isClAccessory && accessoryPartNumber ? accessoryPartNumber : product ? product.partNumber : partNumber || '',
        quantity: isClAccessory ? productQuantity || ['1'] : ['1'],
        langId,
        product: currentProduct,
        subscriptionInfo: productSubscriptionInfo,
        discount: getDiscount(
          parseFloat(listPrice?.toString() ?? '0'),
          parseFloat(offerPrice?.toString() ?? '0'),
          segment ?? ''
        ),
        callback: async () => {
          sendAddToCartEventConditionally(isLenses || false)
          if (!router.pathname.includes(CART)) {
            await router.push(`/${langCode}/${CART}`)
            window?.RXC?.rxcWidget?.close(`#${RX_CONF_SELECTOR}`)
          } else {
            updateCart({})
            dispatch(orderApi.util.invalidateTags(['MiniCart']))
          }
        },
      }
      dispatch(addItem(params))
    }
  }

  const sendAddToCartEventConditionally = (isLenses: boolean) => {
    if (!isLenses && currentProduct && productQuantity) {
      sendAddToCartEvent(
        currentProduct ? [currentProduct] : [],
        Number(productQuantity?.[0]) || 1,
        algoliaPrices,
        analyticsDataForCart
      )
    }
  }

  const addToCart = async (props: AddToCartProps = {}) => {
    if (!addToCartEnabled) {
      return
    }

    interceptAddCartItem(async () => await addToCartImpl(props))
  }

  const handleRemoveOrderItemError = () => {
    if (window.RXC) {
      window.RXC.rxcWidget.close(`#${RX_CONF_SELECTOR}`)
    }
    dispatch(doneEditingCartItem())
    router.push(`/${langCode}/${CART}`)
  }

  const addToCartImpl = async ({
    isClItem = isCl,
    isLenses,
    product,
    fromConfigurator,
    lensObject,
    images,
    fromRxPanel,
    lensesReviewObject,
  }: AddToCartProps = {}) => {
    if (isEditContactLens || isEditLens) {
      if (!productInCart) throw Error('Add to cart requires product in cart to be specified in edit mode!')

      dispatch(setAddToCartBusy(true))
      let productToRemove = productInCart
      const storageItem = localStorageUtil.get(RX_SAVED_LENS_DATA_KEY)?.orderItem
      if (isEditLens && !!storageItem) {
        productToRemove = storageItem
      }

      // add item only if remove was successful otherwise we risk corrupting the cart
      removeOrderItem({ orderItem: productToRemove })
        .unwrap()
        .then(() => {
          addItemToCart({
            isClItem,
            isLenses,
            product,
            fromConfigurator,
            lensObject,
            images,
            fromRxPanel,
            lensesReviewObject,
          })
        })
        .catch(error => {
          Log.error('Error removing order item: ' + error)
          handleRemoveOrderItemError()
        })
    } else {
      dispatch(setAddToCartBusy(true))

      await addItemToCart({
        isClItem,
        isLenses,
        product,
        fromConfigurator,
        lensObject,
        images,
        fromRxPanel,
        lensesReviewObject,
      })
    }
  }

  const handleAddToCartLenses = async (_, lensObject: LensObject, ...args) => {
    // when we call the configurator with the cartMode object
    // the position of the image arg, isn't the same as the usual
    const images: ImageryType = args.flat().find(arg => !!arg?.fallbackImage || !!arg?.productImage)

    if (lensObject.lensPackage.type === 'Frame Only') {
      await addToCart({ fromConfigurator: true })
    } else {
      //covers RX2 and Rx3 Objects naming
      const lensesReviewObject = args.flat().find(arg => !!arg?.addOns || !!arg?.AddOns || !!arg?.type || !!arg?.Type)

      await addToCart({
        fromConfigurator: true,
        isLenses: true,
        lensObject,
        lensesReviewObject,
        images,
        fromRxPanel: true,
      })
    }
  }

  return {
    addToCart,
    handleAddToCartLenses,
  }
}
