import styled from '@mui/material/styles/styled'
import Snackbar from '@mui/material/Snackbar'

export const StyledSnackbar = styled(Snackbar)`
  && {
    transform: translateX(0);
    left: 15px;
    bottom: 16px;
    height: 64px;
    opacity: 0.9;
    position: absolute;
    z-index: 999999;
  }
`
