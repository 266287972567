import { StyledTooltip } from '@components/UI'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import React, { ReactElement } from 'react'

type ExemptionTooltipProps = {
  title?: ReactElement
  body: string
  className?: string
}

export function TitleTooltip(props: ExemptionTooltipProps) {
  const { title, body, className } = props
  return (
    <StyledTooltip
      contentVariant={{
        type: 'textonly',
        body,
      }}
      colorVariant="dark"
      placement={'top'}
    >
      <span className={className}>{title ? title : <SVGIcon library="validation" name="info" color={'#000'} />}</span>
    </StyledTooltip>
  )
}
