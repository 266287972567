import { DATE_FORMAT_PATTERN, DATE_OPTIONS } from '@constants/date'
import { format, parse } from 'date-fns'
import dayjs, { Dayjs, ManipulateType } from 'dayjs'
import dayjsBusinessDays from 'dayjs-business-days'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekOfYear from 'dayjs/plugin/weekOfYear'

dayjs.extend(dayjsBusinessDays)
dayjs.extend(customParseFormat)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)

type DatePattern = keyof typeof DATE_FORMAT_PATTERN | string
export interface DayJSType extends Dayjs {
  isBusinessDay(): boolean
  businessDaysAdd(days: number): Dayjs
  businessDaysSubtract(days: number): Dayjs
}

export const formatDate = (date: Date, pattern: DatePattern, options?: DATE_OPTIONS) => {
  if (options && options.locale) {
    dayjs().locale(options.locale)
  }
  // need extra logic for other options
  return dayjs(date).format(pattern.toUpperCase())
}

export const formatRelativeDate = (date1: Date, date2 = new Date()) => {
  const actualDate = dayjs(date1)
  const comparisonDate = dayjs(date2)

  const dayDifference = comparisonDate.diff(actualDate, 'day')

  if (dayDifference === 1) {
    return 'yesterday'
  }

  if (dayDifference < 7) {
    return `last ${actualDate.format('dddd')} at ${actualDate.format('h:mm A')}`
  }

  return actualDate.format('MM/DD/YYYY')
}

export const subDate = (
  params: {
    years?: number
    months?: number
    weeks?: number
    days?: number
    hours?: number
    minutes?: number
    seconds?: number
  },
  date = new Date()
) => {
  let result = dayjs(date)
  for (const [key, value] of Object.entries(params)) {
    if (key && value) {
      result = result.subtract(value, key as ManipulateType)
    }
  }
  return result.toDate()
}

export const parseDate = (dateString: string, pattern: DatePattern, baseDate = new Date()) => {
  const parsedDate = dayjs(dateString, pattern.toUpperCase())
  const base = dayjs(baseDate)

  const year = parsedDate.year() !== base.year() ? parsedDate.year() : base.year()
  const month = parsedDate.month() !== base.month() ? parsedDate.month() : base.month()
  const date = parsedDate.date() !== base.date() ? parsedDate.date() : base.date()
  // Extend this pattern for hours, minutes, seconds, etc., if needed
  return dayjs().year(year).month(month).date(date).toDate()
}

export const addBusinessDaysToDate = (daysToAdd: number, date = new Date()) => {
  const formatedDate = dayjs(date) as DayJSType
  return formatedDate.businessDaysAdd(daysToAdd).toDate()
}

export const getDifferenceInDays = (date1: Date, date2 = new Date()) => {
  const formatedDate1 = dayjs(date1)
  const formatedDate2 = dayjs(date2)
  return formatedDate1.diff(formatedDate2, 'days')
}

const maxFutureDate = new Date()
maxFutureDate.setFullYear(maxFutureDate.getFullYear() + 100)

export const MAX_FUTURE_DATE = maxFutureDate
export const MAX_PAST_DATE = new Date('1900-01-01')

export const ISSUE_DATE_REGEX = /^(0[1-9]|1[0-2])\/\d{4}$/

export const ISSUE_DATE_FORMAT = 'MM/yyyy'

export function toIssueFormat(date?: string) {
  if (!date) {
    return undefined
  }

  return format(new Date(date), ISSUE_DATE_FORMAT)
}

export function parseIssueDate(date?: string | null) {
  if (!date) {
    return undefined
  }

  return parse(date, ISSUE_DATE_FORMAT, new Date())
}

export function issueDateToISO(date?: string | null) {
  return parseIssueDate(date)?.toISOString()
}