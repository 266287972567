import { StyledTextField as TextField } from '@views/Prescriptions/Prescriptions.style'
import React, { forwardRef } from 'react'
import styles from './styles.module.scss'
import { useRxTaxExemption } from '@foundation/hooks/useRxTaxExemption'
import { clsx } from 'clsx'
import { withShrinkableLabel } from './withShrinkableLabel'

type DoctorInputProps = {
  onFocus?: () => void
  onBlur?: () => void
  shouldShrink?: boolean
  name: string
  message?: string
  required?: boolean
  label: string
  value?: string
  onChange?: (value: string) => void
  hasError?: boolean
  isValid?: boolean
  isPristine?: boolean
  errorText?: string
  disabled?: boolean
}

const _DoctorNameInput = forwardRef<HTMLInputElement, DoctorInputProps>(props => {
  const {
    shouldShrink,
    value,
    onChange,
    hasError,
    isValid,
    isPristine,
    label,
    message,
    required,
    disabled,
    onBlur,
    onFocus,
  } = props
  const { requireBasicInfo } = useRxTaxExemption()

  if (requireBasicInfo) {
    return null
  }

  return (
    <div className={styles.inputField}>
      <TextField
        value={value ?? null}
        label={label}
        className={clsx({
          [styles.disabledField]: disabled,
        })}
        onChange={v => {
          if (onChange) {
            onChange(v.target.value)
          }
        }}
        disabled={disabled}
        required={required}
        error={hasError}
        helperText={message}
        isvalid={isValid}
        ispristine={isPristine}
        showvalidationstatus
        inputProps={{ maxLength: 200 }}
        InputLabelProps={{
          shrink: shouldShrink,
        }}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  )
})

export const DoctorNameInput = withShrinkableLabel<DoctorInputProps>(_DoctorNameInput)
