import { Box } from '@mui/material'
import {
  CartRecapData,
  CartRecapDataContent,
  CartRecapItemWrapper,
  CartRecapTop,
  CartRecapImageContainer,
  CartRecapContentContainer,
  CartRecapRightColumn,
  StyledCartRecapDivider,
  CartRecapModelPriceDataRow,
  CartRecapDataRowProductModel,
  CartRecapBrand,
} from '../CartRecap.style'
import { getCLBrand, getModelCode, getModelName } from '../../../../../utils/productAttributes'
import ProductImage from '../../../../../components/ProductImage/ProductImage'
import React from 'react'
import { catentriesSelector } from '../../../../../features/order/selector'
import { useAppSelector } from '../../../../../hooks/redux'
import { useStoreIdentity } from '../../../../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'next-i18next'
import { useContactLensCartItemData } from '../../../../../hooks/useContactLensCartItemData/useContactLensCartItemData'
import { ContactLensQuantity } from './ContactLensQuantity'
import { LensDetailsSection } from './ContactLensDetails.style'
import { OrderItemContactLensData } from '@typesApp/cart'
import { CART_PRODUCT_IMAGE_WIDTH } from './constants'
import { useRouter } from 'next/router'
import { OrderRecapClItemLensDetails } from '@components/order-recap/OrderRecapClItemLensDetails'
import styles from '../styles/CartRecap.module.scss'
import { CL_EYE_LEFT, CL_EYE_RIGHT } from '@constants/product'
import { OrderItem, OrderUnavailableItem } from '@typesApp/order'

export interface ContactLensQuantityChangeOptions {
  quantity: string
  orderItemId: string
  eye: string
  contactLensData: OrderItemContactLensData
}

const CartRecapClItemUnavailable: React.FC<{
  unavailableItem: OrderUnavailableItem
  unavailableSiblingItem?: OrderUnavailableItem
}> = ({ unavailableItem, unavailableSiblingItem }) => {
  const { productId } = unavailableItem
  const { t: translate } = useTranslation('', { keyPrefix: 'CartRecap.Labels' })
  const router = useRouter()
  const { basePath } = useStoreIdentity()
  const catentries = useAppSelector(catentriesSelector)
  const product = catentries && productId && catentries[productId]
  const { orderItemClData } = useContactLensCartItemData({
    orderItem: unavailableItem as unknown as OrderItem,
  })
  const { orderItemClData: orderSiblingItemClData } = useContactLensCartItemData({
    orderItem: unavailableSiblingItem as unknown as OrderItem,
  })
  const siblingEye = !!orderItemClData?.[CL_EYE_LEFT].data ? CL_EYE_LEFT : CL_EYE_RIGHT
  if (orderItemClData && orderSiblingItemClData) {
    orderItemClData[siblingEye] = orderSiblingItemClData[siblingEye]
  }
  const productBrandName = getCLBrand(unavailableItem) || ''
  const productModelName = getModelName(unavailableItem)
  const productBaseModelCode = getModelCode(unavailableItem)

  const attachments = unavailableItem?.attachments || []
  const { href } = unavailableItem.seo || (product && product.seo) || { href: '' }

  const onImageClicked = () => {
    const url = `${basePath}${href}`
    router.push(url)
  }

  return (
    <Box>
      <CartRecapItemWrapper data-testid={'checkoutCartItem'}>
        <p className={styles.itemUnavailableBanner}>{translate('ProductIsNotAvailable')}</p>
        <CartRecapContentContainer>
          <CartRecapImageContainer>
            <Box>
              <ProductImage
                attachments={attachments}
                data-element-id="X_X_Prods_ProdLink"
                width={CART_PRODUCT_IMAGE_WIDTH}
                usage={'PDP'}
                onClick={onImageClicked}
              />
            </Box>
          </CartRecapImageContainer>
          <CartRecapRightColumn>
            <CartRecapData>
              <CartRecapTop>
                <CartRecapBrand>
                  {productBrandName} {productModelName}
                </CartRecapBrand>
              </CartRecapTop>

              <CartRecapDataContent>
                {orderItemClData && (
                  <>
                    <StyledCartRecapDivider />
                    <ContactLensQuantity
                      orderItemClData={orderItemClData}
                      listPrice={0}
                      offerPrice={0}
                      percentageDiscount={0}
                      currency={''}
                      displayPrice={false}
                      isDisabled={true}
                    />
                  </>
                )}
                <CartRecapModelPriceDataRow>
                  <CartRecapDataRowProductModel>{productBaseModelCode}</CartRecapDataRowProductModel>
                </CartRecapModelPriceDataRow>

                {orderItemClData && (
                  <LensDetailsSection>
                    <OrderRecapClItemLensDetails
                      orderItemClData={orderItemClData}
                      showQuantityPerEye={false}
                      alwaysShowPrescription={false}
                    />
                    <StyledCartRecapDivider />
                  </LensDetailsSection>
                )}

                <p className={styles.itemUnavailablePrice}>{translate('NotAvailable')}</p>
              </CartRecapDataContent>
            </CartRecapData>
          </CartRecapRightColumn>
        </CartRecapContentContainer>
      </CartRecapItemWrapper>
    </Box>
  )
}

export default CartRecapClItemUnavailable
