import React, { FC } from 'react'

import { useTranslation } from 'next-i18next'
import { CommerceEnvironment } from '../../constants/common'
import { TaxValues } from '../../constants/order'
import orderService from '../../foundation/apis/transaction/order.service'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { Cart, IOrderDetails, OrderItem } from '../../types/order'
import { OrderTaxSummaryContainer, OrderTaxSummaryHeader } from './OrderTaxSummary.style'
import { OrderTaxSummaryLine } from './OrderTaxSummaryLine'

export interface OrderTaxSummaryProps {
  order: Cart | IOrderDetails | null
  orderId?: string
  orderCurrency?: string
  salesTax?: number | string
  shippingTax?: number | string
  orderItems: OrderItem[]
  labelFontSize?: number
  boldLabel?: boolean
  valueFontSize?: number
  showHeader?: boolean
  showLabelSeparator?: boolean
}

const OrderTaxSummary: FC<OrderTaxSummaryProps> = ({
  order,
  orderId,
  orderCurrency,
  salesTax,
  shippingTax,
  orderItems,
  labelFontSize,
  boldLabel,
  valueFontSize,
  showHeader = false,
  showLabelSeparator = false,
}) => {
  const { t } = useTranslation()
  const totalSalesTax = Number(salesTax || 0)
  const { country } = useStoreIdentity()
  const itemizedTaxes = CommerceEnvironment.itemizedTaxCountryCodes.includes(country)

  let taxValues: TaxValues = { provincial: 0, federal: 0 }
  const province = (orderItems && orderItems[0]?.stateOrProvinceName) || undefined

  if (itemizedTaxes && orderId) {
    const allOrderItems = order?.orderItem || []
    taxValues = orderService.getOrderTaxes(order, orderId, allOrderItems, itemizedTaxes)
  }
  const totalShippingTax = Number(shippingTax || 0)
  const labelOptions = { separator: showLabelSeparator ? ':' : '' }

  return (
    <OrderTaxSummaryContainer>
      {showHeader && <OrderTaxSummaryHeader>{t('OrderTaxSummary.Header')}:</OrderTaxSummaryHeader>}
      {!itemizedTaxes && !!totalSalesTax && (
        <OrderTaxSummaryLine
          data-testid={'orderSalesTax'}
          label={t('OrderTaxSummary.TaxType.Sales', labelOptions)}
          tax={totalSalesTax}
          currency={orderCurrency}
          labelFontSize={labelFontSize}
          boldLabel={boldLabel}
          valueFontSize={valueFontSize}
        />
      )}
      {itemizedTaxes && taxValues && (
        <OrderTaxSummaryLine
          data-testid={'orderFederalSalesTax'}
          label={t(`OrderTaxSummary.TaxType.Federal.${province}`, labelOptions)}
          tax={taxValues.federal}
          currency={orderCurrency}
          labelFontSize={labelFontSize}
          boldLabel={boldLabel}
          valueFontSize={valueFontSize}
        />
      )}
      {(itemizedTaxes && !!taxValues?.provincial && (
        <OrderTaxSummaryLine
          data-testid={'orderProvincialSalesTax'}
          label={t(`OrderTaxSummary.TaxType.Provincial.${province}`, labelOptions)}
          tax={taxValues.provincial}
          currency={orderCurrency}
          labelFontSize={labelFontSize}
          boldLabel={boldLabel}
          valueFontSize={valueFontSize}
        />
      )) ||
        null}
      {!itemizedTaxes && !!totalShippingTax && (
        <OrderTaxSummaryLine
          data-testid={'orderShippingTax'}
          label={t('OrderTaxSummary.TaxType.Shipping', labelOptions)}
          tax={totalShippingTax}
          currency={orderCurrency}
          labelFontSize={labelFontSize}
          boldLabel={boldLabel}
          valueFontSize={valueFontSize}
        />
      )}
    </OrderTaxSummaryContainer>
  )
}

export { OrderTaxSummary }
