import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

export const useRouterChange = () => {
  const router = useRouter()
  const [isRouterChange, setRouterChange] = useState(false)

  const handleRouteChange = useCallback((url: string) => {
    const currentPath = `/${router.locale}${router.asPath}`.split('?')[0].toLowerCase()
    const nextPath = url.split('?')[0].toLowerCase()
    if (currentPath !== nextPath) {
      setRouterChange(true)
    }
  }, [router.asPath, router.locale])


  const handleRouteChangeComplete = useCallback(() => {
    setRouterChange(false)
  }, [])

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [handleRouteChange, handleRouteChangeComplete, router.events])

  return { isRouterChange }
}
