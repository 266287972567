import config from '@configs/index'
import { useDispatch } from 'react-redux'
import { LOAD_SUBSCRIPTION_CONFIG_REQUEST_ACTION } from '@redux/actions/subscription'
import { useSite } from '@foundation/hooks/useSite'
export const useSubscription = () => {
  const { subscriptionEnabledLocales, subscriptionAllowedProducts } = config
  const dispatch = useDispatch()
  const enabledLocales = subscriptionEnabledLocales?.split(',') ?? []
  const { mySite } = useSite()
  const currentLocale = mySite?.locale.replace('_', '-')

  dispatch(
    LOAD_SUBSCRIPTION_CONFIG_REQUEST_ACTION({
      enabled: enabledLocales.indexOf(currentLocale) > -1,
      allowedProducts: subscriptionAllowedProducts,
    })
  )
}
