import React from 'react'
import { useTheme } from '@mui/material'
import { IconButton } from '../IconButton'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { useTranslation } from 'next-i18next'

export interface ToggleShowPasswordProps {
  showPassword: boolean
  onClick: () => void
}

export const ToggleShowPassword: React.FC<ToggleShowPasswordProps> = ({
  showPassword,
  onClick,
}: ToggleShowPasswordProps) => {
  const { t } = useTranslation()

  const theme = useTheme()
  const buttonLabel = showPassword
    ? 'ToggleShowPassword.HidePassword'
    : 'ToggleShowPassword.ShowPassword'

  return (
    <IconButton onClick={onClick} aria-label={t(buttonLabel)}>
      {showPassword && <SVGIcon library="account" name="show" color={theme.palette.text.dark.primary} />}
      {!showPassword && <SVGIcon library="account" name="hide" color={theme.palette.text.dark.primary} />}
    </IconButton>
  )
}

