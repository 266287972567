import { RTK_QUERY_STATUS } from '@constants/common'
import { LensAvailabilityResponse } from '@typesApp/rxConfigurator'
import { getColorCode, getIsRoxable } from '@utils/productAttributes'
import { useGetLensAvailabilityQuery } from './lensAvailabilityApi'
import { IProduct } from '@typesApp/product'
import config from '@configs/index'

export type LensAvailabilityResponseType = {
  isFulfilled: boolean
  lensAvailabilityInfo: LensAvailabilityResponse | undefined
  hasAvailability: boolean
}
export const useLensAvailability = (product: IProduct | undefined): LensAvailabilityResponseType => {
  let hasAvailability = false
  const isRoxable = product ? getIsRoxable(product) : false
  //TODO: we will remove the feature flag in the sprint after deployment when the featire is confirmed in production with no issues
  const ignoreLensAvailabilityApi = !!config.rxc?.ignoreAvailabilityApi || !(!!product && !!isRoxable)
  const { data: lensAvailabilityInfo, status: queryStatus, isError, isSuccess } = useGetLensAvailabilityQuery(
    { model: product?.manufacturer ?? '' },
    { skip: ignoreLensAvailabilityApi, refetchOnMountOrArgChange: true }
  )
  const isFulfilled =
    ignoreLensAvailabilityApi || queryStatus === RTK_QUERY_STATUS.FULFILLED || queryStatus === RTK_QUERY_STATUS.REJECTED
  if (!!lensAvailabilityInfo && product) {
    const productColorCode = getColorCode(product)
    const availStatus = lensAvailabilityInfo[`${product.manufacturer}__${productColorCode.replace('/', '_')}`]
    hasAvailability = (availStatus?.availability && !!isRoxable) || false
  } else if (!!isRoxable && isSuccess) {
    hasAvailability = true
  } else if (isError) {
    hasAvailability = false
  }

  return {
    isFulfilled,
    hasAvailability,
    lensAvailabilityInfo,
  }
}
