import { ISSUE_DATE_FORMAT, ISSUE_DATE_REGEX, parseIssueDate } from '@utils/dateUtils'
import { isValid, parse } from 'date-fns'
import * as yup from 'yup'

export const ContactLensValidationSchema = yup.object({
  issue: yup
    .string()
    .nullable()
    .when('$issueIsRequired', {
      is: true,
      then: schema => schema.optional().nullable().default(undefined),
      otherwise: schema =>
        schema
          .required('Required')
          .matches(ISSUE_DATE_REGEX)
          .test('valid-date', value => {
            if (!value) return false
            const parsed = parseIssueDate(value)
            return isValid(parsed)
          }),
    }),

  doctor: yup
    .string()
    .nullable()
    .when('$doctorIsRequired', {
      is: true,
      then: schema => schema.optional().nullable().default(undefined),
      otherwise: schema => schema.required('Required'),
    }),
  isAcknowledged: yup
    .boolean()
    .nullable()
    .when('$requiresAcknowledgement', {
      is: true,
      then: schema => schema.required('Required').oneOf([true]),
      otherwise: schema => schema.optional().nullable().default(false),
    }),
})

export type ContactLensFormFields = yup.InferType<typeof ContactLensValidationSchema>
