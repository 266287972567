import React from 'react'
import { StyledButtonAsLink } from '@components/UI'
import { isWunderkindEnabledForLocale, WunderkindConversionMultiPixel } from '@components/Wunderkind/Wunderkind'
import { orderDetailsSelector, orderItemsSelector } from '@features/order/selector'
import { setOpenModalSignIn, setOpenModalRegistration } from '@features/ui/action'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { loginStatusSelector } from '@redux/selectors/user'
import {
  OrderConfirmationEmail,
  OrderConfirmationHeader,
  OrderConfirmationLabel,
  OrderConfirmationLabelWrapper,
  OrderConfirmationThankYou,
} from '@views/Checkout/Checkout.style'
import { useDispatch, useSelector } from 'react-redux'
import { useMentionMe } from '@hooks/useMentionMe'
import { useTranslation } from 'next-i18next'

const OrderConfirmationPostPurchase: React.FC<{ isUserRegistered: boolean }> = ({ isUserRegistered }) => {
  const userLoggedIn = useSelector(loginStatusSelector)
  const dispatch = useDispatch()

  const orderDetails = useSelector(orderDetailsSelector)
  const orderItems = useSelector(orderItemsSelector)
  const userEmail = orderItems?.find(item => !!item)?.email1
  const { langCode } = useStoreIdentity()
  const { t } = useTranslation()

  const mentionMePayload = {
    situation: 'postpurchase',
    email: userEmail ?? '',
    firstname: orderDetails?.x_firstName ?? '',
    surname: orderDetails?.x_lastName ?? '',
    order_number: orderDetails?.orderId ?? '',
    order_subtotal: orderDetails?.totalProductPrice ?? '',
    order_currency: orderDetails?.totalProductPriceCurrency ?? '',
    order_discount_amount: orderDetails?.totalAdjustment ?? '',
    order_item_count: orderItems.length.toString() ?? '',
  }

  useMentionMe('referreroffer', mentionMePayload)

  // TODO Update styled components to css modules
  return (
    <OrderConfirmationHeader>
      <OrderConfirmationThankYou data-testid={'orderConfirmationTitle'}>
        {t('OrderConfirmation.Msgs.ThankYou')}
      </OrderConfirmationThankYou>
      <OrderConfirmationLabelWrapper>
        <OrderConfirmationLabel>
          {`${t('OrderConfirmation.Msgs.SendInfo')}`}
          <OrderConfirmationEmail data-testid={'orderEmail'}>{`${userEmail}.`}</OrderConfirmationEmail>
        </OrderConfirmationLabel>
      </OrderConfirmationLabelWrapper>
      <OrderConfirmationLabel>
        {!userLoggedIn &&
          (isUserRegistered ? (
            <StyledButtonAsLink onClick={() => dispatch(setOpenModalSignIn(true))}>
              {t('OrderConfirmation.Msgs.LoginCta')}
            </StyledButtonAsLink>
          ) : (
            <StyledButtonAsLink onClick={() => dispatch(setOpenModalRegistration(true))}>
              {t('OrderConfirmation.Msgs.CreateAnAccount')}
            </StyledButtonAsLink>
          ))}
        {!userLoggedIn && t('OrderConfirmation.Msgs.RegistrationBenefit')}
      </OrderConfirmationLabel>
      {orderDetails && orderItems?.length > 0 && isWunderkindEnabledForLocale(langCode) && (
        <WunderkindConversionMultiPixel orderDetails={orderDetails} orderItem={orderItems[0]} langCode={langCode} />
      )}
    </OrderConfirmationHeader>
  )
}

export default OrderConfirmationPostPurchase
