import { createContext } from 'react'
import { OrderItem } from '@typesApp/order'

export type CarItemFormContextType = {
  orderItem: OrderItem
  unregisterForm: () => void
}

export const CarItemFormContext = createContext<CarItemFormContextType>({
  orderItem: {} as OrderItem,
  unregisterForm: () => {},
})
