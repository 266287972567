import config from '@configs/index'
import { MentionMeType } from './types'
import queryString from 'query-string'

export const getUrl = (path: MentionMeType, query: Record<string, string>) => {
  const baseUrl = config.mentionMe.baseUrl
  const apiVersion = config.mentionMe.apiVersion
  const partnerCode = config.mentionMe.partnerCode

  const updatedUrl = queryString.stringifyUrl(
    {
      url: `${baseUrl}${apiVersion}/${path}/${partnerCode}`,
      query,
    },
    {
      encode: true,
    }
  )

  return updatedUrl
}

export const convertLocaleToUpperCase = (locale: string): string => {
  const [language, country] = locale.split('_')
  return `${language}_${country.toUpperCase()}`
}

export const checkForValues = (payload: Record<string, string>) => {
  return Object.values(payload).every(value => value)
}
