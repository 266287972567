import { useSite } from '@foundation/hooks/useSite'
import { MentionMeType } from './types'
import { checkForValues, convertLocaleToUpperCase, getUrl } from './utils'
import { useCallback, useEffect, useState } from 'react'
import config from '@configs/index'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { usePathname } from 'next/navigation'
import { CHECKOUT_PAYMENT, CHECKOUT_SHIPPING, ORDER_CONFIRMATION } from '@constants/routes'

declare global {
  interface Window {
    MentionMeFiredTags?: Record<string, boolean>
  }
}

const disabledPages = [CHECKOUT_SHIPPING, CHECKOUT_PAYMENT]
const mentionMeTags = {
  dashboard: 'embeddashboard',
  referreroffer: 'embedlandingpage',
  refereefind: 'linkcheckout',
  referrerofferTYPage: 'popuppostpurchase',
}

type MentionMeResult = {
  isEnabled: boolean
  url: string
  scriptLoading: boolean
}

export const useMentionMe = (path: MentionMeType, payload: Record<string, string>): MentionMeResult => {
  const pathname = usePathname()
  const { mySite } = useSite()
  const { langCode } = useStoreIdentity()
  const isEnabled = config.mentionMe.enabledLocales.includes(langCode)
  const [firstTagLoaded, setFirstTagLoaded] = useState(false)
  const [scriptLoading, setScriptLoading] = useState(isEnabled)
  const locale = convertLocaleToUpperCase(mySite.locale)

  const url =
    isEnabled && checkForValues(payload)
      ? getUrl(path, {
          ...payload,
          locale,
        })
      : ''

  const clearTag = useCallback(() => {
    if (typeof window === 'undefined' || !window.MentionMeFiredTags) return

    const tag = mentionMeTags[pathname.includes(ORDER_CONFIRMATION) ? 'referrerofferTYPage' : path]
    if (window.MentionMeFiredTags[tag] === true) {
      delete window.MentionMeFiredTags[tag]
    }
  }, [path, pathname])

  const createAndAppendScript = useCallback(
    (scriptUrl: string) => {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = scriptUrl
      script.onload = () => setScriptLoading(false)
      script.onerror = () => setScriptLoading(false)
      document.body.appendChild(script)
      return script
    },
    [setScriptLoading]
  )

  useEffect(() => {
    if (!isEnabled || !url || firstTagLoaded || disabledPages.includes(pathname)) {
      return undefined
    }

    setFirstTagLoaded(true)
    clearTag()

    const script = createAndAppendScript(url)

    return () => {
      if (script) {
        document.body.removeChild(script)
      }
    }
  }, [clearTag, createAndAppendScript, firstTagLoaded, isEnabled, pathname, url])

  if (!isEnabled) {
    return {
      isEnabled: false,
      url: '',
      scriptLoading: false,
    }
  }

  return {
    isEnabled: true,
    url,
    scriptLoading,
  }
}
