import React, { ReactNode } from 'react'
import { useTranslation } from 'next-i18next'
import { Stack } from '@mui/material'
import useBreakpoints from '../../../hooks/useBreakpoints'
import { IOrderDetails } from '../../../types/order'
import DateService from '../../../services/DateService'
import OrderService from '../../../foundation/apis/transaction/order.service'
import { OrderDetailsHeaderContainer, OrderDetailsHeaderRow, StyledReturnButton } from './OrderDetailsHeader.style'
import { ORDER_RETURN, ORDER_RETURN_SELECT } from '../../../constants/routes'
import { Field } from '../../../components/UI/Field'
import { useAppDispatch } from '../../../hooks/redux'
import { useSite } from '../../../foundation/hooks/useSite'
import { setReturnOrderId } from '@features/orderReturn/slice'
import styles from '../styles/NewOrderDetails.module.scss'
import ReorderButton from '../../../views/Reorder/ReorderButton'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { downloadInvoice, isOrderInvoiceAvailable } from '@features/orderhistory/utils'
import { DEFAULT_DATE_FORMAT } from '@views/Subscription/constants'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

interface OrderDetailsHeaderProps {
  orderDetails: IOrderDetails
}

export const NewOrderDetailsHeader: React.FC<OrderDetailsHeaderProps> = ({ orderDetails }) => {
  const { t: translate } = useTranslation()
  const dispatch = useAppDispatch()
  const { mySite } = useSite()
  const { langCode } = useStoreIdentity()
  const { isMobile } = useBreakpoints()
  const isInvoiceAvailable = isOrderInvoiceAvailable(orderDetails)
  const isReturnEligible = OrderService.isReturnEligible(orderDetails, mySite.xStoreCfg.n1SiteGoliveDate)
  const orderDate = DateService.getLocalDate(orderDetails?.placedDate || '', DEFAULT_DATE_FORMAT)
  const isReorderEligible =
    OrderService.isReorderEligible(orderDetails) && OrderService.hasReorderableItem(orderDetails.orderItem)

  const trackingUrls = orderDetails?.xtrackingUrls
  const firstTrackingUrl = trackingUrls ? Object.values(trackingUrls)[0] : null
  const orderTracker = firstTrackingUrl ? (
    <a href={firstTrackingUrl} className={styles.orderTrackerLink} target="_blank" rel="noopener noreferrer">
      {translate('Order.TrackOrder')}
    </a>
  ) : null

  const fields = {
    // TODO: ONLINE Add Online Badge when purchase type feature becomes available
    // orderMethod:  <OrderBadge label={translate('Order.Online')} />,
    orderId: <Field label={translate('Order.OrderNumber')} value={orderDetails.orderId} />,
    orderDate: <Field label={translate('Order.OrderDate')} value={orderDate} />,
    orderStatus: (
      <Field label={translate('Order.Status')} value={translate(`Order.Status_.${orderDetails.orderStatus}`)} renderFn={() => orderTracker}/>
    ),
  }

  const actions = {
    viewReceipt: isInvoiceAvailable ? (
      <button onClick={() => downloadInvoice(mySite.storeId, orderDetails.orderId)} className={styles.downloadBtn}>
        <SVGIcon library="misc3" name="download" size={16} />
        <span className={styles.downloadBtnText}>{translate('Order.DownloadEReceipt')}</span>
      </button>
    ) : null,

    returnCta: isReturnEligible ? (
      <StyledReturnButton
        href={`/${langCode}/${ORDER_RETURN}/${ORDER_RETURN_SELECT}`}
        onClick={() => dispatch(setReturnOrderId(orderDetails.orderId))}
        filltype="outline"
        className="cta"
        fullWidth={isMobile}
      >
        {translate('Order.Return')}
      </StyledReturnButton>
    ) : null,

    reorderCta: isReorderEligible ? (
      <ReorderButton
        label={translate('OrderDetails.Actions.OrderAgain')}
        orderId={orderDetails.orderId}
        isRX={OrderService.isRoxable(orderDetails.orderExtendAttribute)}
      />
    ) : null,
  }

  const Layout = isMobile ? MobileLayout : DefaultLayout

  return (
    <OrderDetailsHeaderContainer>
      <Layout fields={fields} actions={actions} />
      {/* TODO: Potentially bring back return message & CTA
        <OrderDetailsIssuesCTAContainer>
          <StyledDetailsIssues dangerouslySetInnerHTML={{ __html: t('Order.OrderIssues') }} />

          {isReturnEligible && (
            <LinkAsButton
              href={`${BASE}/${ORDER_RETURN}/${ORDER_RETURN_SELECT}`}
              onClick={() => dispatch(setReturnOrderId(orderDetails.orderId))}
              filltype="outline"
              className="cta"
            >
              {t('Order.CreateReturn')}
            </LinkAsButton>
          )}
        </OrderDetailsIssuesCTAContainer>
      */}
    </OrderDetailsHeaderContainer>
  )
}

interface LayoutProps {
  fields: {
    // TODO: ONLINE Add Online Badge when purchase type feature becomes available
    // orderMethod: ReactNode
    orderId: ReactNode
    orderDate: ReactNode
    orderStatus: ReactNode
  }
  actions: {
    viewReceipt: ReactNode
    returnCta: ReactNode
    reorderCta: ReactNode
  }
}

const DefaultLayout: React.FC<LayoutProps> = props => {
  const { fields, actions } = props

  return (
    <>
      {/* TODO: ONLINE Add Online Badge when purchase type feature becomes available
      <OrderDetailsHeaderRow>
        {fields.orderMethod}
        {actions.viewReceipt}
      </OrderDetailsHeaderRow>
      */}

      <OrderDetailsHeaderRow>
        {fields.orderId}
        {actions.viewReceipt}
      </OrderDetailsHeaderRow>

      <OrderDetailsHeaderRow>
        <Stack direction={'column'} rowGap={2}>
          {fields.orderDate}
          {fields.orderStatus}
        </Stack>

        <Stack direction={'row'} columnGap={2}>
          {actions.returnCta}
          {actions.reorderCta}
        </Stack>
      </OrderDetailsHeaderRow>
    </>
  )
}

const MobileLayout: React.FC<LayoutProps> = props => {
  const { fields, actions } = props

  return (
    <>
      {/* TODO: ONLINE Add Online Badge when purchase type feature becomes available
      {/* <OrderDetailsHeaderRow>{fields.orderMethod}</OrderDetailsHeaderRow> */}

      <OrderDetailsHeaderRow>{fields.orderId}</OrderDetailsHeaderRow>

      <OrderDetailsHeaderRow>{fields.orderDate}</OrderDetailsHeaderRow>

      <OrderDetailsHeaderRow>{fields.orderStatus}</OrderDetailsHeaderRow>

      <OrderDetailsHeaderRow addTopMargin>{actions.viewReceipt}</OrderDetailsHeaderRow>

      <Stack direction={'column'} rowGap={2}>
        {actions.reorderCta}
        {actions.returnCta}
      </Stack>
    </>
  )
}
