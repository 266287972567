import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'next-i18next'
import { useRelatedProducts, useTrendingFacets, useTrendingItems } from '@algolia/recommend-react'
import { SwiperProps } from 'swiper/react'
import clsx from 'clsx'

import { catentriesSelector, orderItemsSelector } from '@features/order/selector'
import { initIndexName, recommendClient } from '@foundation/algolia/algoliaConfig'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'

// TYPES
import { IAlgoliaHit, IProduct } from '@typesApp/product'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'

// UTILS
import { localeLangCountryUtil } from '@utils/countryUtil'
import { getProductsPartNumbers, isAccessories, isCLAccessories, isContactLensesProduct } from '@utils/product'

import { ALGOLIA_RECOMMENDATION_MODELS } from './constants'
import { CmsCarousel, CmsCarouselProps } from '@components/Cms/CmsComponents-CSS/CmsCarousel/CmsCarousel'
import styles from './styles/AlgoliaRecommendations.module.scss'
import frequentlyBoughtStyles from './styles/FrequentlyBoughtTogether.module.scss'
import { PRODUCT_TYPES_MAP } from '@constants/product'
import { getProductType } from '@utils/productAttributes'

export interface AlgoliaRecommendationsProps {
  item: ICMCollection
  currentProduct?: IProduct
  isGrouped: boolean
  sliderProps?: SwiperProps
}

const { FREQUENTLY_BOUGHT_TOGETHER, RELATED_PRODUCTS, TRENDING_FACETS, TRENDING_ITEMS } = ALGOLIA_RECOMMENDATION_MODELS
const MAX_RECOMMENDED_ITEMS = 6

export const ALGOLIA_SUGGESTED_PRODUCTS_PARTIAL_ID = 'youMayAlsoLikeCarousel-'
type ProductRecord<TObject> = TObject & {
  objectID: string
  _score?: number
}
export type RecommendationType = {
  status: string
  recommendations: ProductRecord<any>[]
}

export const useGetRecommendedProducts = (
  action: string | undefined,
  indexName: string,
  productsPartNumber: string[],
  facetName?: string,
  maxRecommendations?: number
): RecommendationType => {
  const options = {
    indexName,
    maxRecommendations: maxRecommendations ?? MAX_RECOMMENDED_ITEMS,
    recommendClient,
  }
  const objectIDs = productsPartNumber ? [...new Set(productsPartNumber)] : []

  const actions = {
    [FREQUENTLY_BOUGHT_TOGETHER]: () =>
      useRelatedProducts({
        ...options,
        objectIDs,
        queryParameters: {
          ruleContexts: ['frequently-bought'],
        },
      }),

    [RELATED_PRODUCTS]: () =>
      useRelatedProducts({
        ...options,
        objectIDs,
        queryParameters: {
          ruleContexts: ['related-products'],
        },
      }),

    [TRENDING_FACETS]: () =>
      useTrendingFacets({
        ...options,
        facetName: facetName ? facetName : '',
      }),

    [TRENDING_ITEMS]: () =>
      useTrendingItems({
        ...options,
      }),

    default: () => {
      return { status: 'idle', recommendations: [] }
    },
  }
  const result = actions[action || RELATED_PRODUCTS]()

  return {
    status: result.status,
    recommendations: result.recommendations,
  }
}

const AlgoliaRecommendations: React.FC<AlgoliaRecommendationsProps> = ({ item, currentProduct, sliderProps }) => {
  const { langCode } = useStoreIdentity()
  const langCountry = localeLangCountryUtil(langCode)

  const { t: translate } = useTranslation()
  const carouselTitle = item.title ?? translate('ProductGrid.Recommendations.youMayAlsoLike')

  const orderItems = useSelector(orderItemsSelector)
  const catentries = useSelector(catentriesSelector)

  let productsPartNumber: string[] = []
  if (currentProduct) {
    productsPartNumber = getProductsPartNumbers([currentProduct])
  } else if (orderItems?.length) {
    productsPartNumber = orderItems
      .map(item => {
        const validProductType = PRODUCT_TYPES_MAP[getProductType(item).toLowerCase()]
        if (isContactLensesProduct(item)) {
          const productItem = catentries?.[item.productId]
          return productItem?.items?.[0]?.partNumber ?? ''
        } else if (validProductType) {
          return item.partNumber
        } else {
          return ''
        }
      })
      .filter(v => v) // removes empty strings
  }

  const indexName = initIndexName({ locale: langCountry, isRecommendations: true })
  const idAction = item.idAction?.toLocaleLowerCase()

  const recommendationProducts = useGetRecommendedProducts(
    idAction,
    indexName,
    productsPartNumber,
    item.facetName
  ).recommendations

  if (!Array.isArray(recommendationProducts) || recommendationProducts.length <= 0) return null

  const recommendationsWithPrices = recommendationProducts
    .map((hit: Partial<IAlgoliaHit>) => ({
      ...hit,
      x_price: { ...hit.prices },
    }))
    .filter(hit => orderItems.every(o => o.partNumber !== hit.objectID))

  let styleOverride: CmsCarouselProps['styleOverride'] = [undefined, undefined]

  let customSliderProps = sliderProps ?? {}
  let productTileProps: CmsCarouselProps['productTileProps'] = {
    alignPriceCenter: true,
  }
  if (idAction === FREQUENTLY_BOUGHT_TOGETHER) {
    customSliderProps = {
      ...customSliderProps,
      breakpoints: {
        768: {
          slidesPerGroup: 2,
          slidesPerView: 2,
        },
        1280: {
          slidesPerGroup: 3,
          slidesPerView: 3,
        },
      },
      slidesPerGroup: 1,
      slidesPerView: 1.5,
    }
    productTileProps = {
      ...productTileProps,
      addToCartId: 'BoughtTogether_AddCart',
      addToCartTestId: 'CartAddToCart',
      isAddToCartEnabled: product => {
        const productType = getProductType(product)
        return isCLAccessories(productType) || isAccessories(productType)
      },
    }

    styleOverride = [frequentlyBoughtStyles, undefined]
  }

  if (idAction === 'trendingfacets') {
    return <>{/* N.B.: PUT HERE THE TRENDING FACETS COMPONENT */}</>
  }

  return (
    <div
      id={`${ALGOLIA_SUGGESTED_PRODUCTS_PARTIAL_ID}${currentProduct?.partNumber}`}
      className={clsx(idAction !== FREQUENTLY_BOUGHT_TOGETHER && styles.wrapper)}
      data-analytics_available_call="0"
    >
      <CmsCarousel
        products={recommendationsWithPrices}
        productTileProps={productTileProps}
        description={item.description}
        title={carouselTitle}
        sliderProps={customSliderProps}
        styleOverride={styleOverride}
        isAlgolia
      />
    </div>
  )
}

export default AlgoliaRecommendations
