import React from 'react'
import { Box, MenuItem } from '@mui/material'
import { PreLoader, TextField } from '../../../../../components/UI'
import {
  CartRecapData,
  CartRecapDataContent,
  CartRecapItemWrapper,
  CartRecapTop,
  CartRecapImageContainer,
  CartRecapContentContainer,
  CartRecapRightColumn,
  StyledCartRecapDivider,
  CartRecapModelPriceDataRow,
  CartRecapDataRowProductModel,
  CartRecapBrand,
} from '../CartRecap.style'
import {
  ContactLensQuantitySelectLabel,
  ContactLensQuantitySelectWrapper,
  ContactLensQuantitySelectContainer,
  StyledTextField,
} from './ContactLensDetails.style'
import { getCLBrand, getModelCode, getModelName } from '../../../../../utils/productAttributes'
import { OrderRecapUnavailableItemProps } from '@typesApp/order'
import ProductImage from '../../../../../components/ProductImage/ProductImage'
import { useStoreIdentity } from '../../../../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'next-i18next'
import { CART_PRODUCT_IMAGE_WIDTH } from './constants'
import { useRouter } from 'next/router'
import styles from '../styles/CartRecap.module.scss'

const CartRecapClItemUnavailable: React.FC<OrderRecapUnavailableItemProps> = props => {
  const { unavailableItem } = props
  const { t: translate } = useTranslation('', { keyPrefix: 'CartRecap.Labels' })
  const totalBoxes = parseInt(unavailableItem?.quantity || '1')

  const router = useRouter()
  const { basePath } = useStoreIdentity()

  const productBrandName = getCLBrand(unavailableItem) || ''
  const productModelName = getModelName(unavailableItem)
  const productBaseModelCode = getModelCode(unavailableItem)
  const attachments = unavailableItem?.attachments || []
  const { href } = unavailableItem?.seo || { href: '' }

  const onImageClicked = () => {
    const url = `${basePath}${href}`
    router.push(url)
  }

  return (
    <CartRecapItemWrapper data-testid={'checkoutCartItem'}>
      <p className={styles.itemUnavailableBanner}>{translate('ProductIsNotAvailable')}</p>

      <CartRecapContentContainer>
        <CartRecapImageContainer>
          <Box>
            <ProductImage
              attachments={attachments}
              data-element-id="X_X_Prods_ProdLink"
              width={CART_PRODUCT_IMAGE_WIDTH}
              usage={'PDP'}
              onClick={onImageClicked}
            />
          </Box>
        </CartRecapImageContainer>
        <CartRecapRightColumn>
          <CartRecapData>
            <CartRecapTop>
              <CartRecapBrand>
                {productBrandName} {productModelName}
              </CartRecapBrand>
            </CartRecapTop>

            <CartRecapDataContent isClAccessories>
              {unavailableItem && (
                <ContactLensQuantitySelectContainer>
                  <StyledCartRecapDivider />
                  <ContactLensQuantitySelectWrapper>
                    <ContactLensQuantitySelectLabel>{translate('Boxes')}</ContactLensQuantitySelectLabel>
                    <StyledTextField defaultValue={totalBoxes} select className="text-field-overwrite" disabled>
                      <MenuItem value={totalBoxes}>{totalBoxes}</MenuItem>
                    </StyledTextField>
                  </ContactLensQuantitySelectWrapper>
                </ContactLensQuantitySelectContainer>
              )}
              <CartRecapModelPriceDataRow>
                <CartRecapDataRowProductModel>{productBaseModelCode}</CartRecapDataRowProductModel>
              </CartRecapModelPriceDataRow>

              <p className={styles.itemUnavailablePrice}>{translate('NotAvailable')}</p>
            </CartRecapDataContent>
          </CartRecapData>
        </CartRecapRightColumn>
      </CartRecapContentContainer>
    </CartRecapItemWrapper>
  )
}

export default CartRecapClItemUnavailable
