import { useGetReviewCountForSkuQuery } from '@features/trustPilot/trustPilotApi'
import { useEffect, useState } from 'react'

type UseGetReviewsProps = {
  sku: string
  locale: string
}

export const useGetReviews = ({ sku, locale }: UseGetReviewsProps) => {
  const [isError, setIsError] = useState(false)

  const {
    data: numberOfReviews,
    isLoading: isReviewsLoading,
    isError: fetchingReviewsError,
  } = useGetReviewCountForSkuQuery(
    {
      sku,
      locale,
    },
    {
      skip: isError,
    }
  )

  useEffect(() => {
    if (fetchingReviewsError) {
      setIsError(true)
    }
  }, [fetchingReviewsError])

  return {
    numberOfReviews,
    isReviewsLoading,
  }
}
