import Axios, { Canceler } from 'axios'
import { CheckoutStep } from '../../../types/checkout'
import { OrderDate, SectionSubtitle, SectionTitle, SectionWrapper } from '../Checkout.style'
import React, { FC, ReactNode } from 'react'
import { StyledButtonAsLink, StyledTypography } from '../../../components/UI'
import { cartSelector, orderCompleteSelector, orderDetailsSelector } from '../../../features/order/selector'
import { useDispatch, useSelector } from 'react-redux'

import CheckoutRecapItem from './CheckoutRecapItem'
import { LOGOUT_REQUESTED_ACTION } from '../../../redux/actions/user'
import { loginStatusSelector } from '../../../redux/selectors/user'
import { setOpenModalSignIn } from '../../../features/ui/action'
import { useTranslation } from 'next-i18next'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { CART } from '../../../constants/routes'
import { removeWishList } from '../../../features/wishList/slice'
import { CHECKOUT_STEPS } from '../../../constants/common'
import { getInsuranceEventModule } from '../../../components/DirectBilling'
import { useRouter } from 'next/navigation'
import Shipping from '../Shipping'
import Payment from '../payment/Payment'
import OrderConfirmation from '@views/OrderConfirmation/OrderConfirmation'
import { subscriptionConfigSelector } from '@features/subscription/selector'
import ReorderSummary from '../reorder-summary/ReorderSummary'
import { CartRecapContainer } from '@views/Cart/components/CartRecap/CartRecap.style'
import { IS_REORDER_SUCCESS } from '@foundation/constants/common'
import { localStorageUtil } from '@foundation/utils/storageUtil'

export interface CheckoutMainProps {
  activeStep?: string
  identityServiceLoading: boolean
  userIsRegistered: boolean
  shippingZipCode?: string
  children?: ReactNode
  setReorderBusy: React.Dispatch<React.SetStateAction<boolean>>
}

const CheckoutMain: FC<CheckoutMainProps> = ({ activeStep, ...props }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const router = useRouter()
  const { langCode } = useStoreIdentity()
  const { interceptLogout } = getInsuranceEventModule()
  const userLoggedIn = !!useSelector(loginStatusSelector)
  const cart = useSelector(cartSelector)
  const orderDetails = useSelector(orderDetailsSelector)
  const { lastSubscribedOrderId } = useSelector(subscriptionConfigSelector)
  const orderComplete =
    useSelector(orderCompleteSelector) || (!!lastSubscribedOrderId && lastSubscribedOrderId === orderDetails?.orderId)
  const showOrderNumber = lastSubscribedOrderId !== orderDetails?.orderId
  const localOrderDate = new Date(cart?.lastUpdateDate || orderDetails?.lastUpdateDate || '')
  const orderDate = localOrderDate?.toLocaleDateString(langCode, { year: 'numeric', month: 'short', day: '2-digit' })
  const isZero = parseFloat(cart?.grandTotal) === 0
  const isReorderSuccessful = localStorageUtil.get(IS_REORDER_SUCCESS)
  const isReorderWithNoAvailableProducts = activeStep === CHECKOUT_STEPS.REORDER_SUMMARY && !isReorderSuccessful
  const CancelToken = Axios.CancelToken
  let cancels: Canceler[] = []
  const payloadBase = {
    cancelToken: new CancelToken(function executor(c) {
      cancels.push(c)
    }),
  }

  const logOutUser = () => {
    const callback = () => {
      let payload = {
        ...payloadBase,
      }
      dispatch(LOGOUT_REQUESTED_ACTION(payload))
      dispatch(removeWishList())
      router.push(`/${langCode}/${CART}`)
    }

    interceptLogout(callback)
  }

  const steps: CheckoutStep[] = [
    {
      stepLeafs: [CHECKOUT_STEPS.SHIPPING],
      stepNumber: 0,
      stepTitle: t('Shipping.Title'),
      showSubtitle: true,
      showTitle: true,
      stepRecapComponent: () => (
        <>
          <CartRecapContainer>
            <CheckoutRecapItem isOrderComplete={orderComplete} />
          </CartRecapContainer>
        </>
      ),
    },
    ...(activeStep === CHECKOUT_STEPS.REORDER_SUMMARY
      ? [
          {
            stepLeafs: [CHECKOUT_STEPS.REORDER_SUMMARY],
            stepNumber: 1,
            stepTitle: t('ReorderSummary.Title'),
            showSubtitle: true,
            showTitle: true,
          },
        ]
      : []),
    {
      stepLeafs: [CHECKOUT_STEPS.PAYMENT, CHECKOUT_STEPS.ORDER_CONFIRMATION],
      stepNumber: activeStep === CHECKOUT_STEPS.REORDER_SUMMARY ? 2 : 1,
      stepTitle: isZero ? t('Payment.TitleFree') : t('Payment.Title'),
      showSubtitle: true,
      showTitle: activeStep !== CHECKOUT_STEPS.ORDER_CONFIRMATION,
    },
  ]
  return (
    <>
      {orderComplete && !isReorderWithNoAvailableProducts && (
        <OrderDate>
          <div>
            {showOrderNumber && (
              <>
                <StyledTypography
                  data-testid={'orderNumber'}
                  margin={'0 8px 0 0'}
                  fontSize={16}
                  lineHeight="24px"
                  fontWeight="bold"
                >
                  {t('Checkout.Labels.orderNumber')}:
                </StyledTypography>
                {orderDetails?.orderId}
              </>
            )}
          </div>
          <div>
            <StyledTypography margin={'0 8px 0 0'} fontSize={16} lineHeight="24px" fontWeight="bold">
              {t('Checkout.Labels.orderDate')}:{' '}
            </StyledTypography>
            {orderDate}
          </div>
        </OrderDate>
      )}
      {steps.map(
        ({
          stepLeafs,
          stepNumber,
          stepTitle,
          showSubtitle,
          showTitle,
          stepIntroComponent: IntroComponent,
          stepRecapComponent: RecapComponent,
        }) => {
          const isActiveStep = !!activeStep && stepLeafs.includes(activeStep)
          const hasSubtitle = showSubtitle && !userLoggedIn && isActiveStep && stepNumber === 0
          const isLogged = userLoggedIn && isActiveStep && stepNumber === 0
          return (
            <SectionWrapper key={stepTitle}>
              {showTitle && isActiveStep && !orderComplete && (
                <SectionTitle data-testid={`${activeStep}Title`}>{stepTitle}</SectionTitle>
              )}
              {isActiveStep && IntroComponent && <IntroComponent />}
              {isLogged && (
                <SectionSubtitle>
                  {`${t('OrderConfirmation.Msgs.WelcomeBack')} `}
                  <StyledButtonAsLink onClick={() => logOutUser()}>
                    {t('OrderConfirmation.Actions.Logout')}
                  </StyledButtonAsLink>
                </SectionSubtitle>
              )}
              {hasSubtitle && (
                <SectionSubtitle data-element-id="X_X_Cart_SignIn">
                  {`${t('OrderConfirmation.Msgs.LoginQuestion')} `}
                  <StyledButtonAsLink onClick={() => dispatch(setOpenModalSignIn(true))}>
                    {t('OrderConfirmation.Msgs.LoginCta')}
                  </StyledButtonAsLink>
                </SectionSubtitle>
              )}
              {isActiveStep && activeStep === CHECKOUT_STEPS.SHIPPING && <Shipping />}
              {isActiveStep && activeStep === CHECKOUT_STEPS.PAYMENT && <Payment />}
              {isActiveStep && activeStep === CHECKOUT_STEPS.REORDER_SUMMARY && (
                <ReorderSummary setReorderBusy={props.setReorderBusy} />
              )}
              {isActiveStep && activeStep === CHECKOUT_STEPS.ORDER_CONFIRMATION && <OrderConfirmation />}
              {isActiveStep && !orderComplete && props.children}
              {Boolean(orderComplete) && !isReorderWithNoAvailableProducts && !!RecapComponent && <RecapComponent />}
            </SectionWrapper>
          )
        }
      )}
    </>
  )
}

export default CheckoutMain
