import { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react'
import { CartContext, RegisterFormParams } from '@views/Cart/contexts/CartContextProvider/CartContext'
import { useRxTaxExemption } from '@foundation/hooks/useRxTaxExemption'
import { OrderItem } from '@typesApp/order'
import { useCreateRxConfiguration } from './useCreateRxConfiguration'
import { useCreateContactLensPrescription } from './useCreateContactLensPrescription'

export type CartContextProviderProps = PropsWithChildren<{
  orderItems: OrderItem[]
  orderId: string
}>

export function CartContextProvider(props: CartContextProviderProps) {
  const { children, orderItems, orderId } = props
  const [formIsValid, setFormIsValid] = useState(true)
  const rxTaxExemption = useRxTaxExemption()
  const [validated, setValidated] = useState(false)
  const [createContactLensPrescription] = useCreateContactLensPrescription()
  const [createRxConfiguration] = useCreateRxConfiguration()

  const formRefs = useRef<Record<string, RegisterFormParams>>({})

  const createPrescriptionDetails = async () => {
    if (!rxTaxExemption.isEnabled) {
      return
    }

    const registeredForms = Object.values(formRefs.current)
    await createContactLensPrescription(registeredForms)
    await createRxConfiguration(registeredForms)
  }

  const registerForm = (form: RegisterFormParams) => {
    formRefs.current[form.orderItem.orderItemId] = form
    validateAllForms()
  }

  const unregisterForm = (orderItemId: string) => {
    delete formRefs.current[orderItemId]
    validateAllForms()
  }

  const validateAllForms = async () => {
    const registeredForms = Object.values(formRefs.current)

    if (!rxTaxExemption.isEnabled) {
      return true
    }

    if (!registeredForms.length) {
      setFormIsValid(true)
      return true
    }

    if (!validated) {
      setValidated(true)
    }

    const promises = registeredForms.map(form => form.trigger())
    const results = await Promise.all(promises)
    const result = results.every(result => result)

    setFormIsValid(result)
    return result
  }

  return (
    <CartContext.Provider
      value={{
        orderId,
        orderItems,
        validated,
        formIsValid,
        registerForm,
        unregisterForm,
        validateAllForms,
        createPrescriptionDetails,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

export function useCartContext() {
  const context = useContext(CartContext)

  if (!context) {
    throw new Error('useCartContext must be used within a CartContextProvider')
  }

  return context
}
