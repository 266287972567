import React from 'react'
import { useTranslation } from 'next-i18next'
import { Button, PreLoader } from '../../../../../components/UI'

export interface KlarnaPaymentMethodContentProps {
  klarnaPaymentRef?: React.RefObject<HTMLDivElement>
  isBusy: boolean
  onSubmit: () => void
}

export const KlarnaPaymentMethodContent: React.FC<KlarnaPaymentMethodContentProps> = ({
  klarnaPaymentRef,
  isBusy,
  onSubmit,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <div ref={klarnaPaymentRef} />
      <Button
        data-element-id={'X_Checkout_Klarna-CTA'}
        data-testid={'completeOrder'}
        variant="primary"
        disabled={isBusy}
        onClick={onSubmit}
        sx={{ marginLeft: 0 }}
      >
        {isBusy && <PreLoader fill={'light'} withButton />}
        {t('Payment.Actions.NextKlarna')}
      </Button>
    </>
  )
}
