import { RootReducerState } from '@redux/rootReducer'
import { createSelector } from '@reduxjs/toolkit'

export const wishlistEnabledSelector = (state: RootReducerState): boolean =>
  !!state?.site?.currentSite?.xStoreCfg?.wishlistEnabled

export const isWishlistLoadingSelector = (state: RootReducerState): boolean => !!state?.wishList?.isLoading

export const wishlistExternalIdSelector = (state: RootReducerState) =>
  state?.wishList?.GiftList?.[0]?.externalIdentifier ?? ''

export const wishlistItemsSelector = (state: RootReducerState) => state?.wishList?.GiftList?.[0]?.item ?? []

export const wishlistItemSelector = (partNumber: string) =>
  createSelector(wishlistItemsSelector, items =>
    items ? items?.find(item => item?.partNumber === partNumber) : undefined
  )

export const wishlistItemsCountSelector = (state: RootReducerState) => state?.wishList?.GiftList?.[0]?.item?.length ?? 0

export const wishlistProductsSelector = (state: RootReducerState) => state?.wishList?.x_data?.productDetails ?? null
