import React, { useState } from 'react'
import { MODAL_INFO_ARTICLE_CROP_TYPE } from '../../../../constants/ui'
import { TMedia } from '@typesApp/cmsPlacement/Media'
import { ICMArticle } from '@typesApp/cmsPlacement/CMArticle'
import CMSCommonMedia from '@components/Cms/CmsComponents/CmsCommonMedia'
import {
  StyledTextModuleWrapper,
  StyledTitle,
  TextContainer,
  StyledTextModuleContainer,
  StyledTextModuleLearnMoreContainer,
} from '../TextModule.style'
import LoadingSkeleton from '@components/Cms/CmsComponents/CmsCommonMedia/LoadingSkeleton'
import clsx from 'clsx'
import learnMoreStyles from '../styles/LearnMoreArticle.module.scss'

const TextModuleArticle: React.FC<{
  item: ICMArticle
  backgroundColor: React.CSSProperties['backgroundColor']
  isLearnMoreArticle?: boolean
}> = ({ item, backgroundColor, isLearnMoreArticle }) => {
  const [isMediaLoaded, setIsMediaLoaded] = useState(isLearnMoreArticle ? false : true)
  const title = item?.title || ''
  const detailText = item?.detailText || ''
  const media: TMedia = (item?.media && item?.media[0]) || null
  const showMediaContent = isLearnMoreArticle && media
  const TextModuleContainer = isLearnMoreArticle ? StyledTextModuleLearnMoreContainer : StyledTextModuleContainer
  const showDetailText = !media && detailText !== ''

  return (
    <>
      {!isMediaLoaded && !!media && isLearnMoreArticle && (
        <>
          <LoadingSkeleton height="250px" />
          <LoadingSkeleton height="calc(100vh - 250px)" />
        </>
      )}
      <StyledTextModuleWrapper istextoverlay={0} display={(isMediaLoaded || showDetailText) ? 'block' : 'none'}>
        <TextModuleContainer>
          {title && title !== '.' && (
            <StyledTitle data-cm-metadata={`[{"_":"properties.${title}"}]`} textAlign={'center'}>
              {title}
            </StyledTitle>
          )}
          {showMediaContent && (
            <div className={clsx('media-container-wrap', learnMoreStyles.mediaContainerWrap)}>
              <CMSCommonMedia
                type={MODAL_INFO_ARTICLE_CROP_TYPE}
                media={media}
                isLazy={false}
                onImageLoad={() => {
                  if (isLearnMoreArticle) setIsMediaLoaded(true)
                }}
              />
            </div>
          )}
          {detailText && (
            <TextContainer dangerouslySetInnerHTML={{ __html: detailText }} teaserBackground={backgroundColor} />
          )}
        </TextModuleContainer>
      </StyledTextModuleWrapper>
    </>
  )
}

export default TextModuleArticle
