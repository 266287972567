import config from '@configs/index'
import { useSite } from '@foundation/hooks/useSite'

export function useRxTaxExemption() {
  const { mySite } = useSite()
  const enabledLocales = config.rxTaxExemptionLocales || []
  const basicInfoLocales = config.rxTaxExemptionBasicFieldsLocales || []
  const isEnabled = enabledLocales.includes(mySite.locale)
  const basicFieldsRequired = basicInfoLocales.includes(mySite.locale)
  const requiresAcknowledgement = ['en_au', 'en_nz'].includes(mySite.locale)

  return {
    isEnabled,
    requireBasicInfo: isEnabled && basicFieldsRequired,
    showFullForm: isEnabled && !basicFieldsRequired,
    requiresAcknowledgement,
  }
}
