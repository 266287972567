import React, { FC, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  cartSelector,
  catentriesSelector,
  isShippingAsBillingSelector,
  orderCompleteSelector,
  orderDetailsSelector,
  orderItemsSelector,
  orderSelector,
} from '../../../features/order/selector'
import {
  CheckoutSummaryContainer,
  CheckoutSummaryLabel,
  ContinueShoppingButton,
  DeliverySummaryContainer,
  HandlingFeeSummaryContainer,
  PaymentSummaryContainer,
  ShippingSummaryContainer,
  StyledDiscountPriceValue,
  StyledFormattedPriceDisplay,
  TitleDeliverySummaryContent,
  Total,
  TotalSummaryContainer,
  TotalSummaryRow,
} from './CheckoutSummary.style'
import { StyledTooltip, StyledTypography } from '../../../components/UI'
import CheckoutPaymentMethod from './CheckoutPaymentMethod'
import { useAppSelector } from '../../../hooks/redux'
import { pdpTaxesSelector } from '../../../redux/selectors/site'
import FormattedPriceDisplay from '../../../components/formatted-price-display'
import { useTranslation } from 'next-i18next'
import { AddressFormData } from '../../../types/form'
import { userDetailsSelector, userEmailSelector } from '../../../redux/selectors/user'
import addressUtil from '../../../utils/addressUtil'
import { OrderItem } from '../../../types/order'
import { LinkAsButton } from '../../../components/UI/Button/LinkAsButton'
import { OrderTaxSummary } from '../../../components/OrderTaxSummary'
import { StyledRecapContainer } from '../../../components/order-recap/OrderRecap.style'
import OrderService from '../../../foundation/apis/transaction/order.service'
import { isRxCart, parseOrderItems } from '../../../utils/isRxOrder'
import { ANALYTICS_PAGE_TYPE, useAnalyticsData } from '../../../foundation/hooks/useAnalyticsData'
import { getCatEntriesAlgoliaPrices } from '../../../foundation/algolia/algoliaPrice'
import { DiscountedHandlingFee } from '@components/OrderTotalSummary/HandlingFeeSummary'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
import { useCustomerSegmentsUtil } from '@utils/Cookies'

const buildAddressFormData = (dataCollection): AddressFormData => {
  const formData: AddressFormData = {
    addressId: dataCollection?.addressId || '',
    addressType: dataCollection?.addressType,
    fullName: `${dataCollection?.firstName || ''} ${dataCollection?.lastName || ''}`,
    addressLine: `${addressUtil.getAddressWithDelimiter(dataCollection?.addressLine[0]).addressLine2 || ''}, ${
      dataCollection?.city || ''
    }, ${dataCollection?.state || ''} ${dataCollection?.zipCode || ''}`,
    email1: dataCollection?.email1 || '',
    phone1: dataCollection?.phone1 || '',
  }

  return formData
}

const CheckoutSummary: FC = () => {
  const { t } = useTranslation()
  const analyticsDataForTYP = useAnalyticsData(ANALYTICS_PAGE_TYPE.CHECKOUT_SUMMARY_AND_CONFIRMATION)
  const email = useSelector(userEmailSelector)
  const orderItems = useSelector(orderItemsSelector)
  const isTaxAvailable = useAppSelector(pdpTaxesSelector)
  const order = useSelector(orderSelector)
  const orderDetails = useSelector(orderDetailsSelector)
  const orderComplete = useSelector(orderCompleteSelector)
  const cart = useSelector(cartSelector)
  const catEntries = useSelector(catentriesSelector)
  const isShippingAsBilling = useSelector(isShippingAsBillingSelector)
  const [shippingFormData, setShippingFormData] = useState<AddressFormData>({})
  const [billingFormData, setBillingFormData] = useState<AddressFormData>({})
  const hiddenAddressFormRows = ['addressId', 'addressType']
  const hiddenBillingAddressFormRows = ['addressId', 'addressType', 'email1', 'phone1']
  const userDetails = useSelector(userDetailsSelector)
  const IS_RX_CART = isRxCart(cart?.orderExtendAttribute)
  const PARSED_ORDER_ITEMS = IS_RX_CART ? parseOrderItems(orderItems) : orderItems
  const customerSegment = useCustomerSegmentsUtil()
  const algoliaPrices = getCatEntriesAlgoliaPrices(customerSegment, catEntries)

  useEffect(() => {
    const shippingAddressData: OrderItem | undefined = !!orderDetails
      ? orderDetails?.orderItem.find(orderItem => !!orderItem)
      : orderItems[0]
    const billingAddressData = orderDetails?.paymentInstruction[0] || shippingAddressData

    setShippingFormData(buildAddressFormData(shippingAddressData))
    setBillingFormData(buildAddressFormData(billingAddressData))
  }, [userDetails, orderItems, orderDetails])

  const shippingName = OrderService.getDisplayShippingName(orderItems)
  const totals = useMemo(() => OrderService.getOrderTotals(orderDetails), [orderDetails])

  const isFreeHandling = totals.handlingFeeTotal <= 0
  const isDiscountedHandling = totals.handlingFeeAmount !== totals.handlingFeeTotal

  return (
    <CheckoutSummaryContainer>
      <ShippingSummaryContainer>
        <div>
          <CheckoutSummaryLabel fontWeight={'bold'}>{t('Shipping.Labels.ShippingMethod')}:</CheckoutSummaryLabel>
        </div>
        <div>
          <CheckoutSummaryLabel>{shippingName}</CheckoutSummaryLabel>
          <CheckoutSummaryLabel textAlign={'right'}>
            <StyledFormattedPriceDisplay
              data-testid={'orderShippingTotal'}
              min={totals.shipping}
              currency={totals.shippingCurrency}
            />
          </CheckoutSummaryLabel>
        </div>

        {totals.handlingFeeAmount > 0 && (
          <HandlingFeeSummaryContainer>
            <CheckoutSummaryLabel>
              <span>{t('OrderTotalSummary.Labels.HandlingFee.Text')}</span>
              <StyledTooltip
                contentVariant={{
                  type: 'title+text',
                  title: t('OrderTotalSummary.Labels.HandlingFee.Tooltip.Title'),
                  body: t('OrderTotalSummary.Labels.HandlingFee.Tooltip.Body'),
                }}
                colorVariant={'white'}
                placement={'top'}
              >
                <span>
                  <SVGIcon library="validation" name="info" />
                </span>
              </StyledTooltip>
            </CheckoutSummaryLabel>

            <CheckoutSummaryLabel>
              {isDiscountedHandling ? (
                <>
                  <DiscountedHandlingFee>
                    {isFreeHandling ? (
                      t('OrderTotalSummary.Labels.HandlingFee.Free')
                    ) : (
                      <StyledFormattedPriceDisplay min={totals.handlingFeeTotal} currency={totals.grandTotalCurrency} />
                    )}
                  </DiscountedHandlingFee>
                  <s>
                    <StyledFormattedPriceDisplay min={totals.handlingFeeAmount} currency={totals.grandTotalCurrency} />
                  </s>
                </>
              ) : (
                <StyledFormattedPriceDisplay min={totals.handlingFeeTotal} currency={totals.shippingCurrency} />
              )}
            </CheckoutSummaryLabel>
          </HandlingFeeSummaryContainer>
        )}
      </ShippingSummaryContainer>
      <DeliverySummaryContainer>
        <TitleDeliverySummaryContent>
          <CheckoutSummaryLabel fontWeight={'bold'}>
            {t('ShippingAndBillingRecap.shippingRecapTitle')}:
          </CheckoutSummaryLabel>
          {isShippingAsBilling && (
            <StyledTypography fontWeight={'bold'} fontSize={10} isUppercased={true}>
              {t('Shipping.Labels.UsedAsBilling')}
            </StyledTypography>
          )}
        </TitleDeliverySummaryContent>
        {Object.keys(shippingFormData)
          .filter(row => !hiddenAddressFormRows.includes(row))
          .map(key => (
            <CheckoutSummaryLabel key={key}>{shippingFormData[key]}</CheckoutSummaryLabel>
          ))}
      </DeliverySummaryContainer>
      {!isShippingAsBilling && (
        <DeliverySummaryContainer>
          <TitleDeliverySummaryContent>
            <CheckoutSummaryLabel fontWeight={'bold'}>
              {t('ShippingAndBillingRecap.billingRecapTitle')}:
            </CheckoutSummaryLabel>
          </TitleDeliverySummaryContent>
          {Object.keys(billingFormData)
            .filter(row => !hiddenBillingAddressFormRows.includes(row))
            .map(key => (
              <CheckoutSummaryLabel key={key}>{billingFormData[key]}</CheckoutSummaryLabel>
            ))}
        </DeliverySummaryContainer>
      )}
      <PaymentSummaryContainer>
        <CheckoutSummaryLabel fontWeight={'bold'}>{t('Payment.Labels.PaymentMethod')}:</CheckoutSummaryLabel>
        <CheckoutPaymentMethod orderDetails={orderDetails} />
      </PaymentSummaryContainer>
      {isTaxAvailable && (
        <StyledRecapContainer>
          <OrderTaxSummary
            orderId={orderDetails?.orderId}
            order={orderDetails}
            orderCurrency={orderDetails?.totalSalesTaxCurrency}
            orderItems={orderItems}
            showHeader={true}
            shippingTax={totals.shippingTax}
            salesTax={totals.tax}
          />
        </StyledRecapContainer>
      )}
      <TotalSummaryContainer>
        {totals.hasInsurance && (
          <TotalSummaryRow>
            <StyledTypography>{t('OrderTotalSummary.Labels.InsuredCoverage')}</StyledTypography>
            <StyledDiscountPriceValue data-testid={'orderInsuranceTotal'}>
              -
              <FormattedPriceDisplay min={totals.insuranceAmount} currency={totals.insuranceCurrency} />
            </StyledDiscountPriceValue>
          </TotalSummaryRow>
        )}
        {totals.hasPromotion && (
          <TotalSummaryRow>
            <StyledTypography>{t('OrderTotalSummary.Labels.PromotionApplied')}</StyledTypography>
            <StyledDiscountPriceValue data-testid={'orderDiscount'}>
              -
              <FormattedPriceDisplay min={totals.promotionAmount} currency={totals.promotionCurrency} />
            </StyledDiscountPriceValue>
          </TotalSummaryRow>
        )}
        <TotalSummaryRow>
          <StyledTypography fontWeight={'bold'} fontSize={20} lineHeight={'28px'}>
            {t('Order.TotalPrice')}
          </StyledTypography>
          <Total data-testid={'orderTotal'} fontWeight={'bold'} fontSize={20} lineHeight={'28px'}>
            <FormattedPriceDisplay min={totals.grandTotal} currency={totals.grandTotalCurrency} />
          </Total>
        </TotalSummaryRow>
      </TotalSummaryContainer>
      <ContinueShoppingButton>
        <LinkAsButton data-element-id="Checkout_ContinueShopping" type="button" variant="primary" href="/">
          {t('Checkout.Actions.ContinueShopping')}
        </LinkAsButton>
      </ContinueShoppingButton>
      <div></div>
    </CheckoutSummaryContainer>
  )
}

export default CheckoutSummary
