import { CarItemFormContext } from './CartItemContext'
import { PropsWithChildren, useCallback, useContext, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useCartContext } from '@views/Cart/contexts/CartContextProvider/CartContextProvider'
import { OrderItem } from '@typesApp/order'
import {
  ContactLensFormFields,
  ContactLensValidationSchema,
} from '@views/ProductDetails/components/ContactLensSelection/contactLens.schema'
import { useRxTaxExemption } from '@foundation/hooks/useRxTaxExemption'

type CartItemFormProviderProps = PropsWithChildren<{
  orderItem: OrderItem
  showForm: boolean
  defaultValues?: {
    doctor?: string
    issue?: string
  }
  required?: boolean
}>

export function CartItemFormProvider(props: CartItemFormProviderProps) {
  const { children, orderItem, showForm, defaultValues, required } = props
  const rxTaxExemption = useRxTaxExemption()
  const cartContext = useCartContext()

  const context = {
    issueIsRequired: required,
    doctorIsRequired: required || rxTaxExemption.requireBasicInfo,
  }
  const formMethods = useForm<ContactLensFormFields>({
    context,
    mode: 'onChange',
    resolver: yupResolver(ContactLensValidationSchema),
    defaultValues: defaultValues || {
      doctor: '',
      issue: null,
    },
  })

  const { isDirty, isValid } = formMethods.formState
  const memoizedOrderItem = useMemo(() => orderItem, [orderItem.orderItemId])

  const unregisterForm = useCallback(() => {
    if (!rxTaxExemption.isEnabled) {
      return
    }
    cartContext.unregisterForm(memoizedOrderItem.orderItemId)
  }, [memoizedOrderItem.orderItemId])

  useEffect(() => {
    unregisterForm()

    if (!showForm) {
      return () => {
        unregisterForm()
      }
    }

    cartContext.registerForm({
      orderItem: memoizedOrderItem,
      trigger: formMethods.trigger,
      getValues: formMethods.getValues,
      isValid: isValid,
    })

    if (isDirty && cartContext.validated) {
      cartContext.validateAllForms()
    }

    return () => {
      unregisterForm()
    }
  }, [isValid, showForm, isDirty, cartContext.validated])

  return (
    <CarItemFormContext.Provider
      value={{
        orderItem,
        unregisterForm,
      }}
    >
      <FormProvider {...formMethods}>
        <form noValidate>{children}</form>
      </FormProvider>
    </CarItemFormContext.Provider>
  )
}

export function useCartItemFormContext() {
  return useContext(CarItemFormContext)
}
