import { useTranslation } from 'next-i18next'
import styles from './styles/index.module.scss'
import { useMentionMe } from '@hooks/useMentionMe'
import { useSelector } from 'react-redux'
import { userDetailsSelector } from '@redux/selectors/user'
import { MentionMeWrapper } from '@components/MentionMe'

export const Referrals = () => {
  const { t } = useTranslation()
  const userDetails = useSelector(userDetailsSelector)

  const email = userDetails?.email1
  const firstname = userDetails?.firstName
  const surname = userDetails?.lastName

  const mentionMePayload = {
    email: email ?? '',
    firstname: firstname ?? '',
    surname: surname ?? '',
    situation: 'dashboard',
  }

  const { isEnabled } = useMentionMe('dashboard', mentionMePayload)

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>{t('Referrals.Title')}</div>
      {isEnabled ? <MentionMeWrapper id="mmWrapperdashboard" /> : <div>{t('Referrals.NotAvailable')}</div>}
    </div>
  )
}
