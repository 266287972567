import React, { useState } from 'react'
import { Logo } from '../../../../../components/UI'
import { useStoreIdentity } from '../../../../../foundation/hooks/useStoreIdentity'
import styled from '@mui/material/styles/styled'
import { getCheckoutPaths } from '../../../../../utils/routeUtils'
import OrderRecapCartEditConfirmDialog from '../../../../../components/order-recap/OrderRecapEditBag'
import * as ROUTES from '../../../../../constants/routes'

const NavLogoWrapper = styled('a', {
  name: 'NavLogo',
  slot: 'Wrapper',
})(() => ({
  display: 'inherit',
}))

const NavigationLogo: React.FC<{
  width?: number
  height?: number
}> = props => {
  const { width = 100, height = 38 } = props
  const { langCode } = useStoreIdentity()
  // const match = useMatch(window.location.pathname)
  const checkoutPaths = getCheckoutPaths(langCode)
  const isShippingPage = false //match?.pathname === checkoutPaths.shipping
  const isPaymentPage = false //match?.pathname === checkoutPaths.payment

  const [isEditOpen, setIsEditOpen] = useState<boolean>(false)
  const handleOpen = (): void => setIsEditOpen(true)
  const handleClose = (): void => setIsEditOpen(false)

  return (
    <>
      <NavLogoWrapper
        href={!isShippingPage && !isPaymentPage ? `/${langCode}` : '#'}
        aria-label="Homepage"
        data-element-id="MainNav_Logo"
        data-testid={'logo'}
        onClick={!isShippingPage && !isPaymentPage ? undefined : handleOpen}
      >
        <Logo type="primary" width={width} height={height} />
      </NavLogoWrapper>
      <OrderRecapCartEditConfirmDialog route={ROUTES.CART} open={isEditOpen} handleClose={handleClose} />
    </>
  )
}

export default NavigationLogo
