type MentionMeWrapperProps = {
  id?: 'mmWrapperdashboard' | 'mmWrapperlandingpage' | 'mmWrappercheckout'
  className?: string
}

const MentionMeWrapper = ({ id, className }: MentionMeWrapperProps) => {
  return <div id={id ?? 'mmWrapper'} className={className}></div>
}

export default MentionMeWrapper
