import React from 'react'
import clsx from 'clsx'
import styled from '@mui/material/styles/styled'

// Components
import { CmsCountdown } from '@components/Cms/CmsComponents-CSS/CmsCountdown'
import { CmsIcon } from '@components/Cms/CmsComponents-CSS/CmsIcon'
import { CmsCta } from '@components/Cms/CmsComponents-CSS/CmsCta'
import { CmsProductTile } from '@components/Cms/CmsComponents-CSS/CmsProductTile'
// Styles
import { ProductTileContentBoxWithMargin } from '@components/Cms/CmsPlacement/CmsBanner/BoxWithMargin/BoxWithMarginBanner.style'
import {
  StyledCTAContainer,
  StyledPreTitle,
  StyledTextModuleContainer,
  StyledTextModuleFullWidthBannerWrapper,
  StyledTextModuleFullWidthWrapper,
  StyledTextModuleWrapper,
  StyledTitle,
  TextContainer,
} from '../TextModule.style'
import { cmsApiService } from '@foundation/apis/cms/cms.ssr.service'
// Types
import { IBannerType, IPlacement, TeaserOverlayStyle } from '@typesApp/cmsPlacement/Placement'
import { ICMCollection } from '@typesApp/cmsPlacement/CMCollection'
import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'
import { IViewType } from '@typesApp/cmsPlacement/ViewType'
// Utils
import { parseHtmlText } from '../parseHtmlText'
import { teaserPropsByView } from '../../../../utils/placements'
import useBreakpoints from '../../../../hooks/useBreakpoints'

import { getDataElementId, trimText } from '@utils/common'
import { IProduct } from '@typesApp/product'
import { useCountdown } from '@hooks/useCountdown'
import { useSelector } from 'react-redux'
import { paymentMethodsSelector } from '@redux/selectors/site'
import { PaymentMethodImageWrapper } from '@layouts/Footer/components/PaymentMethod/PaymentMethod.style'
import { PaymentMethodsIcons } from '@layouts/Footer/components/PaymentMethod/PaymentMethod'
import { ICON_PAYMENT_METHODS } from '@constants/paymentMethods'
import countdownStyles from '../styles/Countdown.module.scss'
import moduleStyles from '../styles/TextModuleTeaser.module.scss'
import { ProductContextProvider } from '@components/PagesSeo/product/context/ProductContext'
import { TextToHtml } from '@components/TextToHtml'

export interface ITextModuleTeaser {
  item: ILXTeaser
  viewType: IViewType
  placementHasBackGroundColor?: boolean
  teaserIndex?: number
  placementCenter?: boolean
  placement?: IPlacement | ICMCollection
  options?: {
    iconColor?: string
    overlayStyle?: TeaserOverlayStyle
    ctaStyle?: string
    ctaAutoWidth?: boolean
  }
  sectionTitle?: boolean
}

export const TextModuleTeaser: React.FC<ITextModuleTeaser> = props => {
  const { item, viewType, placementHasBackGroundColor, teaserIndex, placementCenter, options, sectionTitle } = props
  const placementCounter = item.placementCounter
  const TextModuleWrapper = getTextModuleContainer(viewType || 'default')
  const { isDesktop, isMobile, isTabletWidthBelowOrEqualTo768, isTabletWidthAbove768 } = useBreakpoints()

  const termsAndConditionsVisible = !(item as ILXTeaser)?.targetsTermsAndConditions?.target?.detailText ? 0 : 1

  const media = item?.media?.[0] ?? undefined
  const isTextOverlay = !!media && isTextModuleOverlay(viewType || 'default', isDesktop, isTabletWidthAbove768) ? 1 : 0

  const { teaserTitle, teaserText, teaserOverlaySettings, teaserOverlayTextAlign, teaserOverlayStyle } =
    teaserPropsByView(viewType || 'default')

  const { promoteToH1, teaserLXCallToActionSettings, teaserPreTitle, teaserBackground, teaserIcon } = item

  const teaserTitleValue = item[teaserTitle]
  const teaserTextValue = parseHtmlText(item[teaserText])
  const teaserOverlaySettingsValue = item[teaserOverlaySettings]
  const teaserOverlayTextAlignValue = item[teaserOverlayTextAlign]
  const teaserOverlayStyleValue = options?.overlayStyle || item[teaserOverlayStyle]
  const teaserbgvalue = placementHasBackGroundColor ? '' : teaserBackground
  const relatedProduct = item.relatedProduct?.[0]
  const productData = { ...relatedProduct?.productData, placementCounter } as IProduct

  const { isCountDownVisible } = useCountdown(item || undefined)

  const overlayTextAlign = cmsApiService.getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)
  const noCTATopMargin = viewType === 'boards-with-fields-below' ? true : teaserTextValue ? false : true

  const showTeaserIcon = viewType !== 'top-page-banner' || isMobile || isTabletWidthBelowOrEqualTo768
  const isBannerType = isBannerAboveXS(['full-width-banner', 'landscape-banner', 'top-page-banner'], viewType)
  const isBlockCenter = teaserOverlaySettingsValue?.includes('center') ?? false
  const isLightColor =
    (isBlockCenter && !(isMobile || isTabletWidthBelowOrEqualTo768)) ||
    (viewType === 'full-width-banner' && (isMobile || isTabletWidthBelowOrEqualTo768))

  const iconPaymentMethods = useSelector(paymentMethodsSelector)
  const isIconPaymentMethodVisible = item.teaserIcon === ICON_PAYMENT_METHODS

  const content = (
    <>
      {teaserIcon && showTeaserIcon && (
        <ContentCmsIcon
          alignSelf={cmsApiService.getTeaserOverlayTextAlign(teaserOverlayTextAlignValue)}
          isCountDownVisible={isCountDownVisible}
          iconColor={options?.iconColor}
        >
          {isBannerType && isLightColor ? (
            <CmsIcon teaserIcon={teaserIcon} className={moduleStyles.lightColor} />
          ) : (
            <CmsIcon teaserIcon={teaserIcon} />
          )}
        </ContentCmsIcon>
      )}
      <CmsCountdown styleOverride={countdownStyles} teaser={item} />
      {isIconPaymentMethodVisible && (
        <PaymentMethodImageWrapper className={item.teaserIcon}>
          <PaymentMethodsIcons availablePaymentMethods={iconPaymentMethods} className={moduleStyles.paymentIcon} />
        </PaymentMethodImageWrapper>
      )}
      {teaserPreTitle && (
        <>
          <StyledPreTitle
            data-cm-metadata={'[{"_":"properties.teaserPreTitle"}]'}
            variant={promoteToH1 ? 'h1' : 'h2'}
            textAlign={(placementCenter && 'center') || overlayTextAlign}
            fontWeight={'600'}
            viewtype={viewType}
          >
            {teaserPreTitle}
          </StyledPreTitle>
        </>
      )}
      {teaserTitleValue && (
        <StyledTitle
          data-cm-metadata={`[{"_":"properties.${teaserTitle}"}]`}
          variant={promoteToH1 ? 'h1' : 'h2'}
          textAlign={(placementCenter && 'center') || overlayTextAlign}
          sectionTitle={sectionTitle}
          hasPreTitle={teaserPreTitle?.length > 0}
        >
          <TextToHtml text={teaserTitleValue} />
        </StyledTitle>
      )}
      {teaserTextValue && (
        <TextContainer
          data-cm-metadata={`[{"_":"properties.${teaserText}"}]`}
          dangerouslySetInnerHTML={{ __html: teaserTextValue }}
          textAlign={(placementCenter && 'center') || overlayTextAlign}
          teaserBackground={teaserbgvalue}
          viewtype={viewType}
        />
      )}
      {!!relatedProduct && !!relatedProduct.productData && (
        <ProductTileContentBoxWithMargin>
          <ProductContextProvider productData={{ product: productData }}>
            <CmsProductTile
              className={clsx(moduleStyles.productTileContainer, teaserbgvalue, teaserOverlayStyleValue)}
            />
          </ProductContextProvider>
        </ProductTileContentBoxWithMargin>
      )}
      {teaserLXCallToActionSettings.length > 0 && (
        <StyledCTAContainer
          autoWidth={options?.ctaAutoWidth}
          overlayTextAlign={overlayTextAlign}
          noTopMargin={noCTATopMargin}
          viewType={viewType}
        >
          {teaserLXCallToActionSettings.map((actionSettings, index) => {
            return (
              <CmsCta
                dataElementId={`${getDataElementId(
                  viewType,
                  {
                    placementIndex: placementCounter?.placementIndex,
                    tileIndex: teaserIndex || placementCounter?.tileIndex,
                    actionIndex: teaserLXCallToActionSettings.length > 1 ? index : '',
                  },
                  'CTA'
                )}`}
                dataDescription={`${trimText(actionSettings?.target?.name ?? '', '_')}${trimText(
                  actionSettings?.callToActionText ?? ''
                )}`}
                key={`cms-content__text-module--cta-${index}`}
                teaserCtaSetting={actionSettings}
              />
            )
          })}
        </StyledCTAContainer>
      )}
    </>
  )

  if ([teaserPreTitle, teaserTitleValue, teaserTextValue].every(text => !text)) {
    return null
  }

  return (
    <TextModuleWrapper
      className="TextModuleWrapper"
      overlaytxtalign={teaserOverlayTextAlignValue}
      overlaysettings={teaserOverlaySettingsValue}
      istextoverlay={isTextOverlay}
      teaseroverlaystyle={teaserOverlayStyleValue}
      teaserbackground={teaserbgvalue}
      termsconditionvisible={termsAndConditionsVisible}
      iscountdownvisible={isCountDownVisible ? isCountDownVisible : undefined}
      viewtype={viewType}
    >
      <StyledTextModuleContainer>{content}</StyledTextModuleContainer>
    </TextModuleWrapper>
  )
}

const isBannerAboveXS = (banners: IBannerType[], viewType: IViewType): boolean => {
  return banners?.includes(viewType as IBannerType) || false
}

const isTextModuleOverlay = (viewtype: IViewType, isDesktop?: boolean, isTabletWidthAbove768?: boolean): boolean => {
  switch (viewtype) {
    case 'full-width-banner':
    case 'landscape-banner':
    case 'top-page-banner':
      return !!isTabletWidthAbove768 || !!isDesktop
    case 'squat-banner':
      return true
    default:
      return false
  }
}

const ContentCmsIcon = styled('div', {
  name: 'ContentCmsIcon',
  slot: 'Icon',
  shouldForwardProp: prop => prop !== 'alignSelf' && prop !== 'isCountDownVisible' && prop !== 'iconColor',
})<{
  alignSelf: React.CSSProperties['alignSelf']
  isCountDownVisible: boolean
  iconColor?: string
}>(({ theme, alignSelf, isCountDownVisible, iconColor }) => ({
  display: 'flex',
  width: 136,
  'svg path': {
    fill: iconColor || undefined,
  },
  alignSelf: alignSelf === 'center' ? 'center' : alignSelf === 'left' ? 'flex-start' : 'flex-end',
  justifyContent: alignSelf === 'center' ? 'center' : alignSelf === 'left' ? 'flex-start' : 'flex-end',
  marginBottom: isCountDownVisible ? theme.spacing(2.5) : theme.spacing(9),
  [theme.breakpoints.up('md')]: {
    marginBottom: isCountDownVisible ? theme.spacing(2.5) : theme.spacing(13),
  },
}))

const getTextModuleContainer = (viewtype: IViewType) => {
  switch (viewtype) {
    case 'full-width-banner':
      return StyledTextModuleFullWidthBannerWrapper
    case 'landscape-banner':
    case 'top-page-banner':
    case 'squat-banner':
    case 'grid-of-boards-two-columns':
    case 'grid-of-boards-three-columns':
    case 'grid-of-boards-four-columns':
      return StyledTextModuleFullWidthWrapper
    default:
      return StyledTextModuleWrapper
  }
}
