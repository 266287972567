import React, { useEffect, useState } from 'react'
import { OrderItem } from '../../types/order'
import CurrencyService from '../../services/CurrencyService'
import { DiscountedPrice, OriginalPrice, StrokeOriginalPrice } from './OrderRecapItemPrice.style'
import { formatOrderRecapItemPrices } from '../../utils/order'
import { useSite } from '../../foundation/hooks/useSite'
import { toNumber } from 'lodash-es'
import { ADJUSTMENT_DISCOUNT } from '@constants/common'
import { ADJUSTMENT_DISPLAY_LEVEL } from '@constants/order'

type Props = {
  orderItem: OrderItem
  showDiscountPrice?: boolean
  quantity?: number
}
const OrderRecapItemPrice = ({ orderItem, quantity = 1, showDiscountPrice = false }: Props) => {
  const { mySite } = useSite()
  const [isShowDiscountPrice, setShowDiscountPrice] = useState(false)
  const [formattedOriginalPrice, setFormattedOriginalPrice] = useState('')
  const [formattedDiscountedPrice, setFormattedDiscountedPrice] = useState('')

  useEffect(() => {
    const { productOrderItemPrice, productOrderItemPriceWithRx } = formatOrderRecapItemPrices(orderItem)
    const orderItemPrice = productOrderItemPriceWithRx || productOrderItemPrice
    const discountAdjustments =
      quantity > 1
        ? Array.isArray(orderItem?.adjustment)
          ? orderItem?.adjustment
              ?.filter(
                adj => adj.usage === ADJUSTMENT_DISCOUNT && adj.displayLevel === ADJUSTMENT_DISPLAY_LEVEL.ORDER_ITEM
              )
              ?.reduce((acc, curr) => toNumber(curr.amount) + toNumber(acc), 0)
          : 0
        : 0

    const discountedPrice = Math.max(
      Number(orderItemPrice) * quantity + (discountAdjustments < 0 ? discountAdjustments : 0),
      0
    )
    setFormattedDiscountedPrice((Number.isFinite(discountedPrice) && discountedPrice.toFixed(2)) || '')
    setFormattedOriginalPrice(
      orderItemPrice ? (orderItemPrice * quantity).toFixed(2) : orderItemPrice?.toFixed(2) || ''
    )
    setShowDiscountPrice(
      showDiscountPrice && discountedPrice > 0 && Number(orderItemPrice) * quantity > discountedPrice
    )
  }, [quantity])

  return (
    <>
      {(isShowDiscountPrice && (
        <>
          <StrokeOriginalPrice>
            {CurrencyService.getFormattedPrice(mySite.locale, orderItem.currency, formattedOriginalPrice)}
          </StrokeOriginalPrice>
          <DiscountedPrice>
            {CurrencyService.getFormattedPrice(mySite.locale, orderItem.currency, formattedDiscountedPrice)}
          </DiscountedPrice>
        </>
      )) || (
        <OriginalPrice>
          {CurrencyService.getFormattedPrice(mySite.locale, orderItem.currency, formattedOriginalPrice)}
        </OriginalPrice>
      )}
    </>
  )
}

export default OrderRecapItemPrice
