import React from 'react'
import { useTranslation } from 'next-i18next'
import { Icon, PAYMENT_METHOD_PAYPALBA } from '../../../../../constants/paymentMethods'
import PaymentMethodsService from '../../../../../services/PaymentMethodsService'
import { CreditCardFormDataType, PaymentMethodType } from '@typesApp/checkout'
import { Cart } from '@typesApp/order'
import { PaymentMethodAdornment } from './Adornment'
import { CardPaymentForm } from './CardPaymentForm'
import { KlarnaPaymentMethodContent } from './KlarnaPaymentMethodContent'
import PaymentMethod from './PaymentMethod'
import { PaypalPaymentMethodContent } from './PaypalPaymentMethodContent'
import { SimplePaymentType, useSimplePaymentType } from './useSimplePaymentType'
import { usePaymentTotal } from './usePaymentTotal'
import { StyledApplePayCheckoutButton } from '@views/Cart/components/CartCheckoutButtonsContainer/CartCheckoutButtonsContainer.style'
import { CART_DATA_ELEMENT_IDS } from '@constants/checkout'
import { hasSubscribedItemsInOrder } from '@views/Subscription/helpers/subscriptionHelpers'
import { ApplePayTransparentIcon } from '@components/UI-CSS/SVGIcon/PaymentIcon'

export interface DefaultPaymentMethodsProps {
  selectedPayment: string
  setSelectedPayment: (id: string) => void
  paymentTermConditionId: string
  paymentsList: PaymentMethodType[]
  paymentProcessing: boolean
  klarnaPaymentRef?: React.RefObject<HTMLDivElement>
  cart: Cart
  onSubmit: (formData: CreditCardFormDataType | null, isUsingWallet?: boolean) => void
  togglePayOption: (paymentMethodId: string) => void
}

export const DefaultPaymentMethods: React.FC<DefaultPaymentMethodsProps> = ({
  selectedPayment,
  setSelectedPayment,
  paymentTermConditionId,
  paymentsList,
  paymentProcessing,
  klarnaPaymentRef,
  cart,
  onSubmit,
  togglePayOption,
}) => {
  const paymentTypeList: string[] = []
  const hasSubscriptionInCart = hasSubscribedItemsInOrder(cart, null)
  const filteredPaymentsList = paymentsList.filter(payment => {
    const type = useSimplePaymentType(payment, paymentTermConditionId)
    if (type === PAYMENT_METHOD_PAYPALBA) {
      return false;
    }
    let isPaymentTypeExist = false
    if (!paymentTypeList.includes(type)) {
      paymentTypeList.push(type)
      isPaymentTypeExist = true
    }
    return isPaymentTypeExist
  })

  const handleSubmit = (formData: CreditCardFormDataType | null) => {
    onSubmit(formData, false)
  }

  return (
    <>
      {filteredPaymentsList.map(paymentItem => {
        const type = useSimplePaymentType(paymentItem, paymentTermConditionId)
        const icon = PaymentMethodsService.getIconByChekoutName(paymentItem.paymentMethodName)
        if (type === 'card') {
          return (
            <CardPaymentMethod
              key={paymentItem.xumet_policyId}
              selectedPayment={selectedPayment}
              setSelectedPayment={setSelectedPayment}
              paymentMethod={paymentItem}
              paymentsList={paymentsList}
              onSubmit={handleSubmit}
              togglePayOption={togglePayOption}
              paymentProcessing={paymentProcessing}
              icon={icon}
            />
          )
        } else {
          return hasSubscriptionInCart ? null : (
            <SpecialPaymentMethod
              key={paymentItem.xumet_policyId}
              selectedPayment={selectedPayment}
              setSelectedPayment={setSelectedPayment}
              paymentMethod={paymentItem}
              onSubmit={handleSubmit}
              togglePayOption={togglePayOption}
              paymentProcessing={paymentProcessing}
              klarnaPaymentRef={klarnaPaymentRef}
              cart={cart}
              simplePaymentType={type}
            />
          )
        }
      })}
    </>
  )
}

export interface CardPaymentMethodProps {
  selectedPayment: string
  setSelectedPayment: (id: string) => void
  paymentMethod: PaymentMethodType
  paymentsList: PaymentMethodType[]
  onSubmit: (formData: CreditCardFormDataType | null) => void
  togglePayOption: (paymentMethodId: string) => void
  paymentProcessing: boolean
  icon: Icon | null
}

const CardPaymentMethod: React.FC<CardPaymentMethodProps> = ({
  selectedPayment,
  setSelectedPayment,
  paymentMethod,
  paymentsList,
  onSubmit,
  togglePayOption,
  paymentProcessing,
  icon,
}) => {
  const { t } = useTranslation()
  const currentItemId = paymentMethod.xumet_policyId
  const isSelected = currentItemId === selectedPayment

  return (
    <PaymentMethod
      onPaymentMethodSelected={() => {
        // No need to call togglePayOption on initial render as it will trigger re-render of
        // entire payment page. The credit card form will eventually call togglePayOption
        // once the credit card is entered
        setSelectedPayment(currentItemId)
      }}
      paymentIcon={icon}
      paymentDescription={t('PaymentMethodSelection.Labels.CreditCard')}
      paymentMethodName={paymentMethod.paymentMethodName}
      content={
        isSelected && (
          <CardPaymentForm
            paymentMethodId={paymentMethod.paymentMethodName}
            onSubmit={onSubmit}
            paymentsList={paymentsList}
            togglePayOption={paymentMethod => {
              togglePayOption(paymentMethod)
            }}
            paymentProcessing={paymentProcessing}
          />
        )
      }
      expanded={isSelected}
    />
  )
}

export interface SpecialPaymentMethodProps {
  simplePaymentType: SimplePaymentType
  selectedPayment: string
  setSelectedPayment: (id: string) => void
  paymentMethod: PaymentMethodType
  onSubmit: (formData: CreditCardFormDataType | null) => void
  togglePayOption: (paymentMethodId: string) => void
  paymentProcessing: boolean
  cart: Cart
  klarnaPaymentRef: React.RefObject<HTMLDivElement> | undefined
}

const SpecialPaymentMethod: React.FC<SpecialPaymentMethodProps> = ({
  simplePaymentType,
  selectedPayment,
  setSelectedPayment,
  paymentMethod,
  onSubmit,
  togglePayOption,
  paymentProcessing,
  cart,
  klarnaPaymentRef,
}) => {
  const { t } = useTranslation()
  const { totalKlarna, currency } = usePaymentTotal(cart)

  const currentItemId = paymentMethod.xumet_policyId
  const isSelected = currentItemId === selectedPayment
  const paymentIcon = PaymentMethodsService.getIconByChekoutName(paymentMethod.paymentMethodName)
  const paymentDescription = paymentMethod.description

  const renderContent = (): React.ReactNode => {
    if (simplePaymentType === 'klarna') {
      return (
        <KlarnaPaymentMethodContent
          isBusy={paymentProcessing}
          klarnaPaymentRef={klarnaPaymentRef}
          onSubmit={() => onSubmit && onSubmit(null)}
        />
      )
    }

    if (simplePaymentType === 'paypal') {
      return <PaypalPaymentMethodContent isBusy={paymentProcessing} onSubmit={() => onSubmit && onSubmit(null)} />
    }

    if (simplePaymentType === 'applepay') {
      return (
        <StyledApplePayCheckoutButton
          variant="primary"
          loading={paymentProcessing}
          disabled={paymentProcessing}
          data-element-id={CART_DATA_ELEMENT_IDS.APPLEPAY_CHECKOUT}
          data-description={`${t('CartCheckoutButtonsContainer.PayWith')} ApplePay`}
          onClick={() => onSubmit?.(null)}
        >
          <span>{t('CartCheckoutButtonsContainer.PayWith')} </span>
          <ApplePayTransparentIcon color={'white'} size={40} />
        </StyledApplePayCheckoutButton>
      )
    }

    return null
  }

  return (
    <PaymentMethod
      onPaymentMethodSelected={() => {
        togglePayOption(paymentMethod.paymentMethodName)
        setSelectedPayment(currentItemId)
      }}
      paymentIcon={paymentIcon}
      paymentAdorment={<PaymentMethodAdornment paymentMethod={paymentMethod} />}
      paymentDescription={
        simplePaymentType === 'klarna'
          ? t('PaymentMethodSelection.Labels.KlarnaDesc', { currency, totalKlarna, description: paymentDescription })
          : paymentDescription
      }
      paymentMethodName={paymentMethod.paymentMethodName}
      content={isSelected && renderContent()}
      expanded={isSelected}
    />
  )
}
