import React, { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'next-i18next'
import ForterPaymentMethod from './components/ForterPaymentMethod'
import FormattedPriceDisplay from '../../../../components/formatted-price-display'
import { PreLoader } from '../../../../components/UI'
import { PaymentInfoType, PaymentMethodType, PaymentMethodStatus, CreditCardFormDataType } from '@typesApp/checkout'
import {
  PaymentMethodTotalPrice,
  PaymentMethodSelectionWrapper,
  PaymentMethodSupportText,
} from '../../payment/Payment.style'
import { Cart } from '@typesApp/order'
import { usePaymentTotal } from './components/usePaymentTotal'
import { DefaultPaymentMethods } from './components/DefaultPaymentMethods'
import { BuyOneGiveOne } from './components/BuyOneGiveOne'
import { WalletPaymentMethods } from './components/WalletPaymentMethods'
import { HealthFundProvider } from './components/HealthFundProvider'
import { useSite } from '../../../../foundation/hooks/useSite'
import { useSearchParams } from 'next/navigation'
import { hasSubscribedItemsInOrder } from '@views/Subscription/helpers/subscriptionHelpers'
import { useGetMiniCartQuery } from '@features/order/query'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { EMPTY_OBJECT_STRING_REPRESENTATION } from '@constants/common'

export interface PaymentMethodSelectionProps {
  paymentMethodId: PaymentInfoType['payMethodId']
  paymentTermConditionId: string
  paymentsList: PaymentMethodType[]
  paymentProcessing: boolean
  klarnaPaymentRef?: React.RefObject<HTMLDivElement>
  paymentMethodStatus?: PaymentMethodStatus
  order: Cart
  webId: string
  setWebId: any
  onSubmit(formData: CreditCardFormDataType | null, isUsingWallet?: boolean): void
  togglePayOption(paymentMethodId: string): void
  children: ReactNode
}

/**
 * PaymentMethodSelection component
 * displays payment method type selection and credit card form
 */
export const PaymentMethodSelection: React.FC<PaymentMethodSelectionProps> = ({
  paymentTermConditionId = '',
  paymentsList,
  togglePayOption,
  paymentProcessing,
  onSubmit,
  klarnaPaymentRef,
  children,
  order: cart,
  webId,
  setWebId,
}) => {
  const { t } = useTranslation()
  const { mySite } = useSite()
  const { storeId } = useStoreIdentity()
  const searchParams = useSearchParams()
  const [forUserIdQueryParam, setForUserIdQueryParam] = useState('')
  const [loading, setLoading] = useState(true)
  const [selectedPayment, setSelectedPayment] = useState('')
  const { totalWithTax } = usePaymentTotal(cart)
  const { data } = useGetMiniCartQuery({ storeId })
  const { grandTotal, grandTotalCurrency } = data?.miniCart ?? {}
  const showBuyOneGiveOneSection = false
  const showHealthFundProviderService =
    mySite.xStoreCfg.HEALTH_FUND_PROVIDER_SERVICE_ENABLED && !hasSubscribedItemsInOrder(cart, null)

  useEffect(() => {
    const currentUserSession =
      searchParams.get('forUserId') ??
      JSON.parse(sessionStorage.getItem('HCL--forUserSession') || EMPTY_OBJECT_STRING_REPRESENTATION)
    const forUserIdQueryParam = currentUserSession ? currentUserSession.forUserId || '' : ''
    setForUserIdQueryParam(forUserIdQueryParam)

    if (paymentsList && paymentsList.length > 0) {
      setLoading(false)
    }
  }, [paymentsList])

  const renderWalletPaymentMethods = () => {
    return (
      <WalletPaymentMethods
        selectedPayment={selectedPayment}
        setSelectedPayment={setSelectedPayment}
        paymentProcessing={paymentProcessing}
        onSubmit={onSubmit}
        togglePayOption={togglePayOption}
      />
    )
  }

  const renderDefaultPaymentMethods = (): React.ReactNode => {
    return (
      <DefaultPaymentMethods
        selectedPayment={selectedPayment}
        setSelectedPayment={setSelectedPayment}
        paymentTermConditionId={paymentTermConditionId}
        paymentsList={paymentsList}
        paymentProcessing={paymentProcessing}
        cart={cart}
        onSubmit={onSubmit}
        togglePayOption={togglePayOption}
        klarnaPaymentRef={klarnaPaymentRef}
      />
    )
  }

  return (
    <>
      <PaymentMethodSupportText>{t('PaymentMethodSelection.SupportText')}</PaymentMethodSupportText>
      <PaymentMethodTotalPrice>
        {t('PaymentMethodSelection.Labels.Price')}{' '}
        {grandTotal && <FormattedPriceDisplay min={grandTotal} currency={grandTotalCurrency} />}
      </PaymentMethodTotalPrice>
      {loading ? (
        <PreLoader size={50} />
      ) : (
        <PaymentMethodSelectionWrapper>
          {showBuyOneGiveOneSection && <BuyOneGiveOne />}
          {showHealthFundProviderService && <HealthFundProvider orderId={cart.orderId} />}
          {renderWalletPaymentMethods()}
          {renderDefaultPaymentMethods()}
          {Boolean(forUserIdQueryParam) && <ForterPaymentMethod value={webId} onInputChange={setWebId} />}
        </PaymentMethodSelectionWrapper>
      )}

      {children}
    </>
  )
}
