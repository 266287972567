//Standard libraries
import React, { FC, useCallback, useMemo } from 'react'
//UI
import { ACCOUNT, ACCOUNT_CHILDREN, HOME, WISHLIST } from '../../constants/routes'
import AccountDashboard from '../AccountDashboard/AccountDashboard'
import OrderHistory from '@views/OrderHistory/OrderHistory'
import AccountSummary from '@views/AccountSummary/AccountSummary'
import PaymentMethod from '@views/PaymentMethods/PaymentMethod'
import AddressBook from '@views/AddressBook/AddressBook'
import AccountStores from '@views/AccountStores/AccountStores'
import { Referrals } from '@views/Referrals'
import Claims from '@views/Claims/Claims'
import Prescriptions from '@views/Prescriptions/Prescriptions'
import { useSite } from '../../foundation/hooks/useSite'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import Wishlist from '@views/Wishlist'
import { useRouter } from 'next/router'
import { formatPathOrUrl } from '@utils/common'

const ACCOUNT_PAGE_COMPONENT_MAP: Record<string, FC> = {
  [ACCOUNT_CHILDREN.DASHBOARD]: AccountDashboard,
  [ACCOUNT_CHILDREN.PERSONAL_INFORMATION]: AccountSummary,
  [ACCOUNT_CHILDREN.ADDRESS_BOOK]: AddressBook,
  [ACCOUNT_CHILDREN.STORES]: AccountStores,
  [ACCOUNT_CHILDREN.PAYMENT_METHODS]: PaymentMethod,
  [ACCOUNT_CHILDREN.REFERRALS]: Referrals,
  [ACCOUNT_CHILDREN.ORDER_HISTORY]: OrderHistory,
  [ACCOUNT_CHILDREN.MY_CLAIMS]: Claims,
  [ACCOUNT_CHILDREN.PRESCRIPTIONS]: Prescriptions,
}

const Account: FC = () => {
  const router = useRouter()
  const pathName = router.query.pageName?.[0]

  const { mySite } = useSite()
  const { langCode } = useStoreIdentity()

  const isMyStoreEnabled =
    mySite?.xStoreCfg?.locatorTenancy?.filter(tenancy => tenancy.langCode === langCode)[0]?.isEnabled === 'false'
      ? false
      : true

  const accountPageActiveComponents = useCallback(
    obj => {
      const asArray = Object.entries(obj)
      if (isMyStoreEnabled) return obj
      const filtered = asArray.filter(([key]) => key !== ACCOUNT_CHILDREN.STORES)
      return Object.fromEntries(filtered)
    },
    [isMyStoreEnabled]
  )

  const ActiveChildComponent = useMemo<React.FC | null>(() => {
    const NEW_ACCOUNT_PAGE_COMPONENT_MAP = accountPageActiveComponents(ACCOUNT_PAGE_COMPONENT_MAP)
    if (pathName && Object.prototype.hasOwnProperty.call(NEW_ACCOUNT_PAGE_COMPONENT_MAP, pathName)) {
      return NEW_ACCOUNT_PAGE_COMPONENT_MAP[pathName]
    }
    if (pathName?.toLowerCase() === WISHLIST) {
      return Wishlist
    }

    return null
  }, [accountPageActiveComponents, pathName])

  const ChildComponent = ActiveChildComponent || AccountDashboard

  return <ChildComponent />
}

/**
 * Generates path of children account page. (`/:country/account/:page`).
 * To get account page itself page param should be omitted.
 *
 * @param langCode string
 * @param page string. Name of children page path (e.g. address-book, wishlist, order-history)
 */
export const generateAccountPagePath = (langCode: string, page?: string, queryParams?: Record<string, string>) => {
  const query = queryParams ? `?${new URLSearchParams(queryParams).toString()}` : ''
  const path =
    page?.toLocaleLowerCase() !== WISHLIST.toLowerCase()
      ? `${HOME}${langCode}/${ACCOUNT}/${page}` + query
      : `${HOME}${langCode}/${WISHLIST}`
  return formatPathOrUrl(path)
}

export default Account
