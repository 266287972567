import React from 'react'
import { IssueDateInput } from './IssueDateInput'
import { useTranslation } from 'next-i18next'
import { DoctorNameInput } from './DoctorNameInput'
import { Controller, useFormContext } from 'react-hook-form'
import { useRxTaxExemption } from '@foundation/hooks/useRxTaxExemption'
import styles from '@components/PrescriptionDetailsForm/styles.module.scss'
import { TitleTooltip } from './TitleTooltip'
import clsx from 'clsx'
import { FormControl, FormHelperText } from '@mui/material'
import { StyledFormControlLabel, StyledCheckbox } from '@components/UI'

type PrescriptionDetailsProps = {
  direction?: 'row' | 'column'
  issueName?: string
  doctorName?: string
  show?: boolean
  showAcknowledgement?: boolean
  disabled?: boolean
}

export function PrescriptionDetailsForm(props: PrescriptionDetailsProps) {
  const {
    direction = 'row',
    issueName = 'issue',
    doctorName = 'doctor',
    show = true,
    disabled = false,
    showAcknowledgement,
  } = props
  const { t } = useTranslation(undefined, { keyPrefix: 'PrescriptionDetailsForm' })
  const { control, trigger } = useFormContext()
  const { isEnabled, showFullForm } = useRxTaxExemption()

  if (!isEnabled || !show) {
    return null
  }

  return (
    <div className={clsx({ [styles.container]: true, [styles.mt]: !showFullForm })}>
      {showFullForm && (
        <div className={styles.description}>
          {t('ExemptionDescription')}
          <TitleTooltip body={t('ExemptionInfo')} />
        </div>
      )}

      <div
        className={clsx({
          [styles.formFields]: true,
          [styles.row]: direction === 'row',
          [styles.column]: direction === 'column',
        })}
      >
        <Controller
          name={issueName}
          control={control}
          render={({ field, fieldState: { error, isDirty, invalid } }) => {
            const isValid = !error && !invalid && isDirty
            const hasError = !!error || (isDirty && invalid)
            const errorText = hasError ? t('Validation.DateOfPrescription') : ''

            return (
              <IssueDateInput
                name={issueName}
                label={t('DateOfPrescription')}
                message={errorText}
                required
                disabled={disabled}
                onChange={async value => {
                  field.onChange(value)

                  await trigger(issueName)
                }}
                isValid={isValid}
                isPristine={!isDirty}
                hasError={hasError}
                value={field.value ?? null}
              />
            )
          }}
        />
        <Controller
          name={doctorName}
          control={control}
          render={({ field, fieldState: { error, isDirty, invalid } }) => {
            const isValid = !error && !invalid && isDirty
            const hasError = !!error || (isDirty && invalid)
            const errorText = hasError ? t('Validation.NameOfDoctor') : ''

            return (
              <DoctorNameInput
                name={doctorName}
                value={field.value ?? null}
                label={t('DoctorName')}
                message={errorText}
                disabled={disabled}
                required
                onChange={async value => {
                  field.onChange(value)

                  await trigger(doctorName)
                }}
                isValid={isValid}
                isPristine={!isDirty}
                hasError={hasError}
              />
            )
          }}
        />
      </div>
      {showAcknowledgement && (
        <Controller
          name="isAcknowledged"
          control={control}
          render={({ field: { value, ref, ...rest }, fieldState: { invalid } }) => {
            return (
              <FormControl error={true}>
                <StyledFormControlLabel
                  className={styles.ackowledgementField}
                  {...rest}
                  control={
                    <StyledCheckbox
                      checked={value}
                      inputRef={ref}
                      {...rest}
                      onChange={async e => {
                        rest.onChange(e.target.checked === true)
                        await trigger('isAcknowledged')
                      }}
                    />
                  }
                  label={
                    <>
                      <div className={styles.acknowledgementLabel}>
                        {t('UserAcknowledgement')}
                        <TitleTooltip body={t('PrescriptionDisclaimer')} />
                      </div>
                    </>
                  }
                />
                {invalid && (
                  <FormHelperText error className={styles.confirmationError}>
                    {t('ConfirmPrescription')}
                  </FormHelperText>
                )}
              </FormControl>
            )
          }}
        />
      )}
    </div>
  )
}
