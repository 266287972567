import { PrescriptionPayload, useUpdatePrescriptionDetailsMutation } from '@features/order/query'
import { useSelector } from 'react-redux'
import { cartSelector } from '@features/order/selector'
import { RegisterFormParams } from '@views/Cart/contexts/CartContextProvider/CartContext'
import { isRxFrame, isRxLens } from '@utils/isRxOrder'
import { getRxLensItem } from '@utils/rx'
import { parse } from 'date-fns'
import { issueDateToISO, parseIssueDate } from '@utils/dateUtils'

export function useCreateRxConfiguration() {
  const cart = useSelector(cartSelector)

  const [updatePrescriptionMutation] = useUpdatePrescriptionDetailsMutation()

  const createPrescriptionDetails = async (data: RegisterFormParams[]) => {
    const promises = data.map(createPrescriptionDetail)
    await Promise.all(promises)
  }

  const createPrescriptionDetail = async (data: RegisterFormParams) => {
    const payload = createPayload(data)
    if (!payload) {
      return Promise.resolve()
    }

    return updatePrescriptionMutation(payload).unwrap
  }

  const createPayload = (data: RegisterFormParams): PrescriptionPayload | undefined => {
    if (!isRxFrame(data.orderItem['orderItemExtendAttribute'])) {
      return
    }

    const rxLensItem = getRxLensItem(data.orderItem)

    if (!rxLensItem) {
      return
    }

    const form = data.getValues()
    return {
      orderId: cart.orderId,
      orderItemId: rxLensItem?.orderItemId,
      prescription: {
        doctor: form.doctor ?? '',
        productType: 'frame',
        nickName: '',
        firstName: '',
        lastName: '',
        telephone: '',
        dateOfBirth: '',
        prescriptionState: '',
        issue: issueDateToISO(form.issue),
      },
    }
  }

  return [createPrescriptionDetails]
}
