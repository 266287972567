import { FC, useState } from 'react'
import { SearchClient } from 'algoliasearch'
import { useTheme } from '@mui/material'
import clsx from 'clsx'
import { v4 as uuid } from 'uuid'
// SWIPER
import SwiperCore, { A11y, Pagination } from 'swiper'
import { SwiperProps, SwiperSlide } from 'swiper/react'
// COMPONENTS
import { SuggestedProductTileProps } from '@components/SuggestedProductTile/SuggestedProductTile'
import { CmsCarouselProductTile } from './CmsCarouselProductTile/CmsCarouselProductTile'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'
// TYPES
import { IAlgoliaHit, IProduct } from '@typesApp/product'
import { ICMExternalProduct, isCMExternalProduct } from '@typesApp/teaser'
import { CSSModule } from '@styles/types'
// STYLES
import { StyledSwiper } from '@components/UI/Carousel/Carousel.style'
import styles from './styles/index.module.scss'
import { useWindowWidth } from '@hooks/useWindowWidth'
import { ProductContextProvider } from '@components/PagesSeo/product/context/ProductContext'

export interface CmsCarouselProps {
  categoryFilter?: string
  centeredSlides?: boolean
  currentSlide?: number
  description?: string
  isAlgolia?: boolean
  mainCarouselWidth?: number
  onNextClick?: () => void
  onPrevClick?: () => void
  onSlideChange?: (slideNumber: number | undefined) => void
  pdpdataloading?: boolean
  products?: unknown[] | ICMExternalProduct[] | IProduct[]
  productTileProps?: Partial<SuggestedProductTileProps> & {
    hidePriceFromLabel?: boolean
    verticalPriceLayout?: boolean
    alignPriceCenter?: boolean
    addToCartId?: string
    addToCartTestId?: string
    isAddToCartEnabled?: (product: IProduct | IAlgoliaHit) => void
  }
  searchClient?: SearchClient
  searchTerm?: string
  sliderProps?: SwiperProps
  slidesPerView?: number
  subtitle?: string
  title?: string
  /**
   * NOTE: default styling can be overridden by passing css modules into this component.
   * Class names in passed css modules must match those set up for this component.
   * Overrides must be passed as a tuple, structured as follows:
   * first: carousel overrides, second: product tile overrides
   */
  styleOverride?: [CSSModule | undefined, CSSModule | undefined]
}

export const DEFAULT_SLIDES_PER_VIEW = 4

export const CmsCarousel: FC<CmsCarouselProps> = (props: CmsCarouselProps) => {
  const windowWidth = useWindowWidth()
  const {
    products,
    sliderProps,
    onSlideChange,
    productTileProps,
    slidesPerView,
    styleOverride,
    isAlgolia,
    title,
    description,
    ...rest
  } = props
  const mobileView = windowWidth < 768
  const productsToDisplay = mobileView ? products?.slice(0, 7) : products
  const numberOfProducts = productsToDisplay?.length ?? 0
  const hasMoreThanOneSlide = numberOfProducts > (slidesPerView ?? DEFAULT_SLIDES_PER_VIEW)

  const theme = useTheme()

  const [swiper, setSwiper] = useState<SwiperCore>()
  const [carouselStyleOverride, tileStyleOverride] = styleOverride ?? []
  const [navEnabled, setNavEnabled] = useState<boolean>(mobileView && hasMoreThanOneSlide)
  const finalSlidesPerView = slidesPerView != null && slidesPerView > 0 ? slidesPerView : DEFAULT_SLIDES_PER_VIEW

  return !numberOfProducts ? null : (
    <div className={clsx(styles.wrapper, carouselStyleOverride?.wrapper)}>
      <div className={clsx(styles.textContainer)}>
        {title && <div className={clsx(styles.title, carouselStyleOverride?.title)}>{title}</div>}
        {description && <div className={clsx(styles.description)}>{description}</div>}
      </div>
      <div
        className={clsx(carouselStyleOverride?.sliderContainer, styles.sliderContainer, {
          [styles.sliderInactive]: !navEnabled,
        })}
        {...rest}
      >
        <>
          {navEnabled && (
            <div className={clsx(carouselStyleOverride?.navigationContainer, styles.navigationContainer)}>
              <div
                className={clsx(carouselStyleOverride?.navigationButton, styles.navigationButton)}
                onClick={() => {
                  swiper?.slidePrev()
                }}
              >
                <SVGIcon library="arrow" name="arrow-left" color={theme.palette.text.dark.primary} />
              </div>
            </div>
          )}

          <StyledSwiper
            modules={[Pagination, A11y]}
            onUnlock={() => {
              setNavEnabled(false)
            }}
            onSlideChange={() => onSlideChange && onSlideChange(swiper?.activeIndex)}
            onSwiper={setSwiper}
            pagination={{
              clickable: true,
            }}
            simulateTouch
            slidesPerGroup={1}
            slidesPerView={1.5}
            touchStartPreventDefault={false}
            breakpoints={{
              768: {
                slidesPerGroup: 2,
                slidesPerView: 2,
              },
              1024: {
                slidesPerGroup: 3,
                slidesPerView: 3,
              },
              1280: {
                slidesPerGroup: 4,
                slidesPerView: 4,
              },
              1440: {
                slidesPerGroup: finalSlidesPerView,
                slidesPerView: finalSlidesPerView,
              },
              1920: {
                slidesPerGroup: finalSlidesPerView,
                slidesPerView: finalSlidesPerView,
              },
            }}
            onResize={() => {
              if (swiper) {
                const breakpointParams = swiper.params?.breakpoints?.[swiper.currentBreakpoint]
                const hasMoreThanOneSlide = numberOfProducts > Number(breakpointParams?.slidesPerGroup)
                const shouldCenterSlides = windowWidth < 768 || !hasMoreThanOneSlide
                const swiperEl = swiper.el
                if (swiper.params.centeredSlides !== shouldCenterSlides) {
                  swiper.params.centeredSlides = shouldCenterSlides
                  swiper.update()
                }

                /**
                 * NOTE: There is a bug with Swiper library when
                 * updating the `centeredSlides` param to true/false.
                 * It doesn't add/remove the `swiper-centered` class name.
                 * So adding the code below to manually remove/add the class.
                 */
                if (shouldCenterSlides) {
                  swiperEl?.classList.add('swiper-centered')
                  setNavEnabled(false)
                } else {
                  swiperEl?.classList.remove('swiper-centered')
                  setNavEnabled(true)
                }
              }
            }}
            centeredSlides={mobileView || !hasMoreThanOneSlide}
            cssMode={true}
            className={clsx(carouselStyleOverride?.cmsSwiper, styles.cmsSwiper)}
            {...sliderProps}
          >
            {productsToDisplay?.map((product, index: number) => {
              const productData = isCMExternalProduct(product as object)
                ? (product as ICMExternalProduct).productData
                : (product as IProduct)
              return (
                <SwiperSlide key={`swiper-${uuid()}`}>
                  <ProductContextProvider productData={{ product: productData }}>
                    <CmsCarouselProductTile
                      isAlgolia={isAlgolia}
                      isClustered
                      isPDP
                      styleOverride={tileStyleOverride}
                      tileIndex={index}
                      {...productTileProps}
                    />
                  </ProductContextProvider>
                </SwiperSlide>
              )
            })}
          </StyledSwiper>

          {navEnabled && (
            <div className={clsx(carouselStyleOverride?.navigationContainer, styles.navigationContainer)}>
              <div
                className={clsx(carouselStyleOverride?.navigationButton, styles.navigationButton)}
                onClick={() => {
                  swiper?.slideNext()
                }}
              >
                <SVGIcon library="arrow" name="arrow-right" color={theme.palette.text.dark.primary} />
              </div>
            </div>
          )}
        </>
      </div>
    </div>
  )
}
