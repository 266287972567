import { StyledTextField as TextField } from '@views/Prescriptions/Prescriptions.style'
import styles from './styles.module.scss'
import { clsx } from 'clsx'
import React, { forwardRef, useRef } from 'react'
import { useTranslation } from 'next-i18next'
import { IMaskInput } from 'react-imask'
import { InputBaseComponentProps } from '@mui/material'
import { withShrinkableLabel } from './withShrinkableLabel'

type IssueDateInputProps = {
  shouldShrink?: boolean
  onFocus?: () => void
  onBlur?: () => void
  name: string
  message?: string
  required?: boolean
  label: string
  value?: string
  onChange?: (date: string | undefined) => void
  hasError?: boolean
  isValid?: boolean
  isPristine?: boolean
  errorText?: string
  disabled?: boolean
}

const _IssueDateInput = forwardRef<HTMLInputElement, IssueDateInputProps>(props => {
  const {
    shouldShrink,
    message,
    required,
    label,
    value,
    onChange,
    hasError,
    isValid,
    isPristine,
    disabled,
    onBlur,
    onFocus,
    ...rest
  } = props

  return (
    <div className={styles.inputField}>
      <TextField
        {...rest}
        required
        disabled={disabled}
        error={hasError}
        helperText={message}
        isvalid={isValid}
        ispristine={isPristine}
        showvalidationstatus
        placeholder="MM/YYYY"
        label={label}
        className={clsx({
          [styles.disabledField]: disabled,
        })}
        onInput={e => {
          onChange?.(e.target['value'])
        }}
        InputLabelProps={{
          shrink: shouldShrink,
        }}
        customInputProps={{
          value,
          inputComponent: MaskedInput,
        }}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  )
})

type MaskedInputProps = InputBaseComponentProps & {
  inputRef?: (ref: HTMLInputElement | null) => void
}

const MaskedInput = forwardRef<HTMLInputElement, MaskedInputProps>(function MaskedInput(props, ref) {
  const { inputRef, onChange } = props
  return (
    <IMaskInput
      {...props}
      mask="00/0000"
      radix="."
      unmask={true}
      ref={ref}
      inputRef={(el: HTMLInputElement | null) => {
        if (inputRef) inputRef(el)
        if (typeof ref === 'function') ref(el)
        else if (ref) (ref as React.MutableRefObject<HTMLInputElement | null>).current = el
      }}
    />
  )
})

export const IssueDateInput = withShrinkableLabel<IssueDateInputProps>(_IssueDateInput)
