import React, { FC } from 'react'
import FormattedPriceDisplay from '../formatted-price-display'
import {
  OrderTaxSummaryLineContainer,
  OrderTaxSummaryLineLabel,
  OrderTaxSummaryLineValue,
} from './OrderTaxSummary.style'

export interface OrderTaxSummaryLineProps {
  label: string
  tax: number
  labelFontSize?: number
  boldLabel?: boolean
  valueFontSize?: number
  currency?: string
}

const OrderTaxSummaryLine: FC<OrderTaxSummaryLineProps> = ({
  label,
  tax,
  labelFontSize,
  boldLabel = false,
  valueFontSize,
  currency,
}) => {
  return (
    <OrderTaxSummaryLineContainer>
      <OrderTaxSummaryLineLabel fontSize={labelFontSize} bolded={boldLabel}>
        {label}
      </OrderTaxSummaryLineLabel>
      <OrderTaxSummaryLineValue fontSize={valueFontSize} data-testid={'orderSalesTax'}>
        <FormattedPriceDisplay min={tax} currency={currency} />
      </OrderTaxSummaryLineValue>
    </OrderTaxSummaryLineContainer>
  )
}

export { OrderTaxSummaryLine }
