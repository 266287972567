import { useLazyGetPrescriptionDetailsQuery } from '@features/order/query'
import { useRxTaxExemption } from '@foundation/hooks/useRxTaxExemption'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CART } from '@constants/routes'
import { Cart, OrderItem, OrderItemWithRoxProps } from '@typesApp/order'
import { useRouter } from 'next/router'
import { getNormalizedProductType } from '@utils/productAttributes'
import { PRODUCT_TYPES_KEYS } from '@constants/product'
import { isNonPrescriptionColoredLens } from '@components/order-recap/helpers/isNonPrescriptionColoredLens'
import { getOrderItemClData } from '@hooks/useContactLensCartItemData/useContactLensCartItemData'
import { isContactLensesProduct } from '@utils/product'
import { parseOrderItems } from '@utils/isRxOrder'
import { getGroupedOrderItemsByAttrCorrelation } from '@utils/order'
import { getRxLensItem, isNonPrescription, isRxFrame } from '@utils/rx'
import { getRxValuesFromRxLens } from '@components/PrescriptionLenses/RxUtils'

type VerifyPrescriptionDetailsParams = {
  orderItems: OrderItem[] | OrderItemWithRoxProps[]
  cart: Cart
  langCode: string
  cartLoading: boolean
}

/**
 * Hook to verify prescription details for both RX frames and contact lenses
 * Redirects to cart if prescriptions are invalid
 */
export function useVerifyPrescriptionDetails(params: VerifyPrescriptionDetailsParams) {
  const { orderItems, cart, langCode, cartLoading } = params
  const router = useRouter()
  const rxTaxExemption = useRxTaxExemption()
  const [getPrescriptionDetails] = useLazyGetPrescriptionDetailsQuery()
  const [prescriptionDetailsLoading, setPrescriptionDetailsLoading] = useState(rxTaxExemption.isEnabled)

  /**
   * Validates if an individual RX frame order item has valid prescription details
   */
  const isRxFrameOrderItemValid = useCallback((orderItem: OrderItem): boolean => {
    if (isNonPrescription(orderItem)) return true

    const rxLensItem = getRxLensItem(orderItem)
    const rxValues = rxLensItem && getRxValuesFromRxLens(rxLensItem)
    if (!rxValues) return false

    if (rxTaxExemption.showFullForm) {
      return !!rxValues.issue && !!rxValues.doctor
    }

    return !!rxValues.issue
  }, [])

  /**
   * Validates all RX frames in the order have valid prescriptions
   */
  const allRxFramesItemsAreValid = useCallback((): boolean => {
    if (!rxTaxExemption?.isEnabled) return true

    const rxLensOrderItems = orderItems.filter(orderItem => isRxFrame(orderItem['orderItemExtendAttribute']))
    return rxLensOrderItems.every(isRxFrameOrderItemValid)
  }, [JSON.stringify(orderItems), isRxFrameOrderItemValid, rxTaxExemption?.isEnabled])

  /**
   * Gets prescription queries for contact lens items
   */
  const contactLensPrescriptionQueries = useMemo(() => {
    if (!rxTaxExemption?.isEnabled || !cart?.orderId) return []

    const orderItemsGroups =
      getGroupedOrderItemsByAttrCorrelation(
        orderItems?.filter(oi => isContactLensesProduct(oi)),
        'xitem_field1'
      ) ?? []

    return orderItemsGroups
      .filter(isContactLensesProduct)
      .filter(orderItem => {
        const clData = getOrderItemClData(orderItem)
        if (!clData) return false
        return !isNonPrescriptionColoredLens({ orderItemClData: clData })
      })
      .map(parsedOrderItem =>
        getPrescriptionDetails({
          orderId: cart.orderId,
          orderItemId: parsedOrderItem.orderItemId,
        })
      )
  }, [JSON.stringify(orderItems), getPrescriptionDetails, cart?.orderId, rxTaxExemption?.isEnabled])

  /**
   * Validates all contact lens prescriptions are valid
   */
  const allContactLensRxItemsAreValid = useCallback(async (): Promise<boolean> => {
    if (!rxTaxExemption?.isEnabled) return true

    const data = await Promise.all(contactLensPrescriptionQueries)
    const prescriptionDetails = data.map(d => d.data)
    return !prescriptionDetails.some(p => p?.['errorMessage'])
  }, [contactLensPrescriptionQueries, rxTaxExemption.isEnabled])

  useEffect(() => {
    if (!rxTaxExemption.isEnabled) {
      setPrescriptionDetailsLoading(false)
      return
    }

    if (cartLoading) return

    const validatePrescriptions = async () => {
      const contactLensAreValid = await allContactLensRxItemsAreValid()
      const rxFramesAreValid = allRxFramesItemsAreValid()

      if (!contactLensAreValid || !rxFramesAreValid) {
        router.replace(`/${langCode}/${CART}`)
        return
      }

      setPrescriptionDetailsLoading(false)
    }

    validatePrescriptions()
  }, [langCode, allContactLensRxItemsAreValid, allRxFramesItemsAreValid, router, rxTaxExemption.isEnabled, cartLoading])

  return { prescriptionDetailsLoading }
}
