import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Contact, RegistrationFormData } from '../../../types/user'
import { useYupValidationResolver } from '../../../utils/validationResolver'
import { buildYupValidationSchema } from '../../../constants/form'
import { REGISTRATION_FORM_FIELDS } from '../../../constants/user'
import Axios, { Canceler } from 'axios'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  firstAvailableContactInformationSelector,
  guestStatusSelector,
  loginStatusSelector,
  pendingRegistrationStatusSelector,
} from '../../../redux/selectors/user'
import * as userAction from '../../../redux/actions/user'
import { Trans, useTranslation } from 'next-i18next'
import { useSite } from '../../../foundation/hooks/useSite'
import { orderItemsSelector } from '../../../features/order/selector'
import { Button, NewPassword, StyledCheckbox, TextField } from '../../UI'
import { isEmpty } from 'lodash-es'
import {
  RegistrationFormControlLabel,
  StyledPolicyLink,
  StyledPrivacyCheckboxes,
  StyledPersonalInfo,
} from '../../../views/SignUp/styles/RegistrationLayout.style'
import { ContentButton, ContentTextField, StyledForm, WrapperRegistration } from './RegistrationFormDialog.style'
import { ToggleShowPassword } from '../../UI/ToggleShowPassword/ToggleShowPassword'
import { PasswordValidationMessages } from '../../UI/NewPassword'
import { ACCOUNT, ACCOUNT_CHILDREN, HOME } from '../../../constants/routes'
import { useRouter } from 'next/navigation'
import { useStoreIdentity } from '../../../foundation/hooks/useStoreIdentity'
import { setOpenModalRegistration } from '../../../features/ui/action'
import { useCheckoutSteps } from '@hooks/useCheckoutSteps'
import { CHECKOUT_STEPS, COUNTRY_MAP } from '@constants/common'
import { storeCfgServiceLinksSelector } from '../../../redux/selectors/site'

const defaultValues: RegistrationFormData = {
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  email: '',
  email2: '',
  password1: '',
  password2: '',
  privacy1: false,
  privacy2: false,
}

const RegistrationFormDialog: React.FC = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { t } = useTranslation()
  const { mySite } = useSite()
  const { langCode } = useStoreIdentity()
  const isLoggedIn = useSelector(loginStatusSelector)
  const { activeStep } = useCheckoutSteps()
  const isConfirmationPage = CHECKOUT_STEPS.ORDER_CONFIRMATION === activeStep
  const pendingRegistrationStatus = useSelector(pendingRegistrationStatusSelector)
  const orderItems = useSelector(orderItemsSelector)
  const userIsGuest = !!useSelector(guestStatusSelector)
  const contactInformation: Contact = useSelector(firstAvailableContactInformationSelector, shallowEqual)
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [inputType, setInputType] = useState<string>('password')
  const [passwordValid, setPasswordValid] = useState(false)
  const [bodyData, setBodyData] = useState<object | undefined>()
  const isANZ = mySite?.country === COUNTRY_MAP.AUSTRALIA || mySite.country === COUNTRY_MAP.NEW_ZEALAND

  const storeId: string = mySite ? mySite.storeID : ''
  const catalogId: string = mySite ? mySite.catalogID : ''
  const preferredLanguage: string = mySite ? mySite.defaultLanguageID : ''
  const serviceLinks = useSelector(storeCfgServiceLinksSelector)
  const privacyLink = `/${langCode}${serviceLinks?.privacy}`
  const termsLink = `/${langCode}${serviceLinks?.terms}`

  const optInBoxes: boolean[] | undefined = mySite ? mySite.xStoreCfg.optInBoxes : []
  const [checkPrivacy, checkNewsLetter, checkProfiling] = optInBoxes || []

  const passwordValidationMessages = {
    empty: t('RegistrationLayout.Msgs.Empty.newPassword'),
    invalid: t('RegistrationLayout.Msgs.InvalidNewPassword'),
    requirements: t('RegistrationLayout.Msgs.Requirements', { returnObjects: true }),
  } as PasswordValidationMessages

  const {
    setValue,
    handleSubmit,
    control,
    register,
    formState: { errors },
    watch,
  } = useForm<RegistrationFormData>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onBlur',
    resolver: useYupValidationResolver(
      buildYupValidationSchema({
        formFields: REGISTRATION_FORM_FIELDS,
        i18nInvalidMsgBase: 'RegistrationLayout.Msgs.',
        i18nFormFieldsInvalidMsgs: {},
        t,
      })
    ),
  })

  const payloadBase = useMemo(() => {
    const CancelToken = Axios.CancelToken
    let cancels: Canceler[] = []

    return {
      cancelToken: new CancelToken(function executor(c) {
        cancels.push(c)
      }),
    }
  }, [])

  useEffect(() => {
    if (bodyData) {
      dispatch(
        userAction.registrationAction({
          body: bodyData,
          ...payloadBase,
        })
      )
    }
  }, [bodyData, dispatch, payloadBase])

  useEffect(() => {
    setInputType(showPassword ? 'text' : 'password')
  }, [showPassword])

  useEffect(() => {
    if (contactInformation) {
      const { lastName, firstName, email1 } = contactInformation
      setValue('lastName', lastName)
      setValue('firstName', firstName)
      setValue('email', email1, { shouldValidate: true })
    }
  }, [contactInformation, setValue])

  useEffect(() => {
    if (orderItems && orderItems[0] && userIsGuest) {
      const { lastName, firstName, email1 } = orderItems[0]
      !!lastName && setValue('lastName', lastName)
      !!firstName && setValue('firstName', firstName)
      setValue('email', email1, { shouldValidate: true })
    }

    if (isANZ) {
      setValue('privacy1', true)
    }
  }, [isANZ, orderItems, setValue, userIsGuest])

  const onSubmit = (data: RegistrationFormData) => {
    if (!passwordValid) return

    const { firstName, lastName, password1, password2, email, receiveEmail, privacy1, privacy2, privacy3 } = data

    const body = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      logonId: email,
      logonPassword: password1,
      logonPasswordVerify: password2,
      registerType: 'G',
      profileType: 'C',
      email1: email,
      dateOfBirth: '',
      phone1: '',
      storeId,
      catalogId,
      preferredLanguage,
      preferredCurrency: '',
      receiveEmail: receiveEmail,
      receiveEmailPreference: [
        {
          value: receiveEmail,
          storeID: storeId,
        },
      ],
      challengeQuestion: '-',
      challengeAnswer: '-',
      x_optIn: {
        x_privacy: !checkPrivacy ? true : privacy1,
        x_newsLetter: !checkNewsLetter ? true : privacy2,
        x_profiling: !checkProfiling ? true : privacy3,
      },
    }
    setTimeout(() => setBodyData(body), 100)
  }
  if (isLoggedIn) {
    dispatch(setOpenModalRegistration(false))
    if (isConfirmationPage) {
      router.push(`${HOME}${langCode}`)
    } else {
      router.push(`${HOME}${langCode}/${ACCOUNT}/${ACCOUNT_CHILDREN.DASHBOARD}`)
    }
    return null
  } else
    return (
      <WrapperRegistration>
        <StyledForm
          noValidate
          name="registrationForm"
          id={'registration_form_5_sign-in-registration-page-new-registration'}
          onSubmit={handleSubmit(onSubmit)}
        >
          <ContentTextField>
            <Controller
              render={({ field: { value, ref, ...rest }, fieldState: { error } }) => (
                <TextField
                  {...rest}
                  inputRef={ref}
                  value={value}
                  inputProps={{ maxLength: 20 }}
                  autoFocus
                  required
                  fullWidth
                  label={t('RegistrationLayout.FirstName')}
                  error={(error && !!value?.length) || (error && isEmpty(value))}
                  helperText={
                    ((error && !!value?.length) || (error && isEmpty(value))) && t(errors.firstName?.message ?? '')
                  }
                  showvalidationstatus
                  isvalid={!error && !!value?.length}
                  ispristine={!value?.length}
                />
              )}
              name="firstName"
              control={control}
              defaultValue=""
            />
            <Controller
              render={({ field: { value, ref, ...rest }, fieldState: { error } }) => (
                <TextField
                  {...rest}
                  inputRef={ref}
                  value={value}
                  inputProps={{ maxLength: 20 }}
                  required
                  fullWidth
                  label={t('RegistrationLayout.LastName')}
                  error={(error && !!value?.length) || (error && isEmpty(value))}
                  helperText={
                    ((error && !!value?.length) || (error && isEmpty(value))) && t(errors.lastName?.message ?? '')
                  }
                  showvalidationstatus
                  isvalid={!error && !!value?.length}
                  ispristine={!value?.length}
                />
              )}
              name="lastName"
              control={control}
              defaultValue=""
            />
            <Controller
              render={({ field: { value, ref, ...rest }, fieldState: { error } }) => (
                <TextField
                  {...rest}
                  inputRef={ref}
                  value={value}
                  required
                  fullWidth
                  label={t('RegistrationLayout.Email')}
                  autoComplete="email"
                  inputProps={{
                    maxLength: 100,
                    type: 'email',
                    placeholder: 'name@domain.com',
                  }}
                  error={!!error}
                  helperText={errors && t(errors.email?.message ?? '')}
                  showvalidationstatus
                  isvalid={!error && !!value?.length}
                  ispristine={!value?.length}
                />
              )}
              name="email"
              control={control}
              defaultValue=""
            />
            <Controller
              render={({ field: { value, ref, ...rest }, fieldState: { error } }) => (
                <TextField
                  {...rest}
                  inputRef={ref}
                  value={value}
                  required
                  fullWidth
                  label={t('RegistrationLayout.ConfirmEmail')}
                  autoComplete="email2"
                  inputProps={{
                    maxLength: 100,
                    type: 'email',
                    placeholder: 'name@domain.com',
                  }}
                  error={(error && !!value?.length) || (error && isEmpty(value))}
                  helperText={
                    ((error && !!value?.length) || (error && isEmpty(value))) && t(errors.email2?.message ?? '')
                  }
                  showvalidationstatus
                  isvalid={!error && !!value?.length}
                  ispristine={!value?.length}
                />
              )}
              name="email2"
              control={control}
              defaultValue=""
            />
            <Controller
              render={({ field }) => (
                <NewPassword
                  {...field}
                  error={!!errors.password1}
                  label={t('RegistrationLayout.Password')}
                  description={t('RegistrationLayout.Password-description')}
                  messages={passwordValidationMessages}
                  onValidationChange={(isValid: boolean) => setPasswordValid(isValid)}
                />
              )}
              name="password1"
              control={control}
              defaultValue=""
            />
            <Controller
              render={({ field: { value, ref, ...rest }, fieldState: { error } }) => (
                <TextField
                  {...rest}
                  inputRef={ref}
                  value={value}
                  fullWidth
                  label={t('RegistrationLayout.VerifyPassword')}
                  autoComplete="new-password"
                  inputProps={{
                    maxLength: 50,
                  }}
                  type={inputType}
                  customInputProps={{
                    endAdornment: (
                      <ToggleShowPassword showPassword={showPassword} onClick={() => setShowPassword(!showPassword)} />
                    ),
                  }}
                  error={(error && !!value?.length) || (error && isEmpty(value))}
                  helperText={
                    ((error && !!value?.length) || (error && isEmpty(value))) && t(errors.password2?.message ?? '')
                  }
                  showvalidationstatus
                  isvalid={!error && !!value?.length}
                  ispristine={!value?.length}
                />
              )}
              name="password2"
              control={control}
              defaultValue=""
            />

            {!isANZ ? (
              <>
                {checkPrivacy && (
                  <RegistrationFormControlLabel
                    {...register('privacy1')}
                    control={<StyledCheckbox color="primary" onChange={e => setValue('privacy1', e.target.checked)} />}
                    label={
                      <StyledPrivacyCheckboxes variant="caption">
                        <Trans i18nKey="RegistrationLayout.PrivacyPolicy1">
                          <StyledPolicyLink href={privacyLink} />
                        </Trans>
                      </StyledPrivacyCheckboxes>
                    }
                  />
                )}
                {checkNewsLetter && (
                  <div style={{ paddingBottom: '10px' }}>
                    <RegistrationFormControlLabel
                      {...register('privacy2')}
                      control={
                        <StyledCheckbox color="primary" onChange={e => setValue('privacy2', e.target.checked)} />
                      }
                      label={
                        <StyledPrivacyCheckboxes variant="caption">
                          <div>{t('RegistrationLayout.PrivacyPolicy2')}</div>
                          <Trans i18nKey="RegistrationLayout.PrivacyPolicy2CTA">
                            <StyledPolicyLink href={privacyLink} />
                          </Trans>
                        </StyledPrivacyCheckboxes>
                      }
                    />
                  </div>
                )}
              </>
            ) : (
              <>
                {checkNewsLetter && (
                  <div>
                    <RegistrationFormControlLabel
                      {...register('privacy2')}
                      control={
                        <StyledCheckbox color="primary" onChange={e => setValue('privacy2', e.target.checked)} />
                      }
                      label={
                        <StyledPrivacyCheckboxes variant="caption">
                          <Trans i18nKey="RegistrationLayout.PrivacyPolicyAndTerms">
                            <StyledPolicyLink href={termsLink} />
                            <StyledPolicyLink href={privacyLink} />
                          </Trans>
                        </StyledPrivacyCheckboxes>
                      }
                    />
                  </div>
                )}
              </>
            )}
          </ContentTextField>
          <ContentButton>
            <Button
              data-element-id="X_X_AccountCreate_Submit"
              data-testid={'createAccountSubmit'}
              type="submit"
              loading={pendingRegistrationStatus}
              variant="primary"
              disabled={
                !isANZ
                  ? pendingRegistrationStatus || (checkPrivacy ? !watch().privacy1 : false)
                  : pendingRegistrationStatus || false
              }
            >
              {t('RegistrationLayout.Submit')}
            </Button>
          </ContentButton>
          {isANZ && (
            <StyledPersonalInfo>
              <Trans i18nKey="RegistrationLayout.PrivacyPolicyPersonalInfo">
                <StyledPolicyLink href={privacyLink} />
                <StyledPolicyLink href={termsLink} />
              </Trans>
            </StyledPersonalInfo>
          )}
        </StyledForm>
      </WrapperRegistration>
    )
}

export default RegistrationFormDialog
