import React, { forwardRef } from 'react'
import { useTranslation } from 'next-i18next'
import { StyledRelativeBtn } from './CtaPdp.style'
import { PreLoader } from '../../../../components/UI'
import { getModelName } from '../../../../utils/productAttributes'
import { LoaderProps } from '../../../../components/UI/Loader/preloader.type'
import { IProduct } from '@typesApp/product'

type ParentBGType = 'light' | 'dark' | 'lightVariant' | 'darkVariant' | undefined

export interface AddLensesCtaProps {
  id: string
  product: IProduct
  onClick: () => void
  disabled: boolean
  busy: boolean
  error: boolean | undefined
  parentbg?: ParentBGType
  variant?: 'primary' | 'secondary' | 'tertiary'
  fullwidth?: boolean
  dataTestid?: string
}

export const AddLensesCta = forwardRef<HTMLDivElement, AddLensesCtaProps>((props, ref) => {
  const { id, product, disabled, fullwidth, busy, onClick, variant, parentbg, dataTestid } = props
  const { t } = useTranslation()
  const modelName = getModelName(product)
  const spinnerFill: LoaderProps['fill'] = variant !== 'secondary' ? 'light' : 'dark'
  return (
    <StyledRelativeBtn
      id={id}
      disabled={disabled}
      data-testid={dataTestid}
      data-id={id}
      data-element-id={id}
      data-description={`${modelName}_${product?.partNumber}`}
      className="prescription"
      onClick={onClick}
      customBg
      customBorderColor
      variant={variant || 'tertiary'}
      fullwidth={!!fullwidth}
      parentbg={parentbg}
    >
      {busy && <PreLoader fill={spinnerFill} withButton />}
      {t('ProductDetails.Suggested.Actions.Prescription')}
    </StyledRelativeBtn>
  )
})
