import { ACCOUNT_CHILDREN } from '../../constants/routes'
import { generateAccountPagePath } from '../Account/Account'
import {
  OrderDetailsHeader,
  OrderHistoryContainerWrapper,
  OrderHistoryEmptyCTAContainer,
  OrderHistoryEmptyWrapper,
  OrderHistoryHeader,
  OrderHistoryStyledButtonCTA,
  OrderHistoryTitle,
  OrderHistoryWrapper,
  StyledBackToOrderHistoryWrapper,
  OrderHistoryEmptyText,
} from './OrderHistory.style'
import React, { useEffect, useMemo, useState } from 'react'
import { Order } from '../../types/order'
import OrderDetails from './components/OrderDetails'
import NewOrderDetails from './components/NewOrderDetails'
import { sendMyAccountEvent } from '../../foundation/analytics/tealium/lib'
import { useSearchParams } from 'next/navigation'
import { useSelector } from 'react-redux'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import { useTranslation } from 'next-i18next'
import { userIdSelector } from '../../redux/selectors/user'
import { OrderItem } from './components/OrderItem'
import { NewOrderItem } from './components/NewOrderItem'
import { OrderFilter } from './components/OrderFilter'
import { Loader } from '../../components/UI'
import { OrderHistoryArgs, useGetOrderHistoryQuery } from '../../features/orderhistory/query'
import { FilterOption, filterOptionToDate } from './components/orderhistoryfilter'
import { useAnalyticsData } from '../../foundation/hooks/useAnalyticsData'
import config from '@configs/config.base'
import { SVGIcon } from '@components/UI-CSS/SVGIcon/SVGIcon'

/**
 * `@name OrderHistory`
 *
 * Component that is rendered at `/account/order-history` route. Routing is managed by `Account` component.
 */
const OrderHistory: React.FC = () => {
  const searchParams = useSearchParams()
  const { langCode } = useStoreIdentity()
  const userId = useSelector(userIdSelector)
  const [filterOption, setFilterOption] = useState<FilterOption>(FilterOption.LAST_6_MONTHS)
  const [queryParams, setQueryParams] = useState<OrderHistoryArgs>({
    userId,
    dateBefore: filterOptionToDate(FilterOption.LAST_6_MONTHS, langCode),
  })
  const { data: orders = [], isLoading, isFetching } = useGetOrderHistoryQuery(queryParams)
  const isOneClickReorderEnabled = config.isOneClickReorderEnabled
  const isBusy = isLoading || isFetching
  const analyticsData = useAnalyticsData('accountHistory')
  const orderIdToShowDetails = useMemo<string>(() => searchParams.get('orderId') || '', [searchParams])

  useEffect(() => {
    const params = {
      userId,
      dateBefore: filterOptionToDate(filterOption, langCode),
    }
    setQueryParams(params)
  }, [filterOption, userId])

  const onFilterOptionChange = (option: FilterOption) => {
    setFilterOption(option)
  }

  useEffect(() => {
    sendMyAccountEvent({
      common: analyticsData,
      Page_Section2: 'History',
    })
  }, [filterOption])

  if (isBusy) {
    return <Loader />
  }

  return orderIdToShowDetails ? (
    // TODO: remove isOneClickReorderEnabled when published
    <DetailsView orderId={orderIdToShowDetails} isOneClickReorderEnabled={isOneClickReorderEnabled} />
  ) : (
    <OrderHistoryWrapper>
      <Header
        isLoading={isBusy}
        orders={orders}
        filterOption={filterOption}
        onFilterOptionChange={option => onFilterOptionChange(option)}
      />
      {orders?.length === 0 ? (
        <EmptyOrdersView />
      ) : isOneClickReorderEnabled ? (
        <NewOrdersView orders={orders} />
      ) : (
        <OrdersView orders={orders} />
      )}
    </OrderHistoryWrapper>
  )
}

interface DetailsViewProps {
  orderId: string
  // TODO: remove when published
  isOneClickReorderEnabled: boolean
}

const DetailsView: React.FC<DetailsViewProps> = ({ orderId, isOneClickReorderEnabled = false }) => {
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()

  return (
    <OrderHistoryWrapper>
      <StyledBackToOrderHistoryWrapper href={generateAccountPagePath(langCode, ACCOUNT_CHILDREN.ORDER_HISTORY)}>
        <SVGIcon library="arrow" name="arrow-left" />
        {t('Order.BackToOrderHistory')}
      </StyledBackToOrderHistoryWrapper>
      <OrderDetailsHeader>{t('Order.OrderDetails')}</OrderDetailsHeader>
      {isOneClickReorderEnabled ? <NewOrderDetails orderId={orderId} /> : <OrderDetails orderId={orderId} />}
    </OrderHistoryWrapper>
  )
}

export interface HeaderProps {
  isLoading: boolean
  orders: Order[]
  filterOption: FilterOption
  onFilterOptionChange: (option: FilterOption) => void
}

const Header: React.FC<HeaderProps> = ({ isLoading, orders, filterOption, onFilterOptionChange }) => {
  const { t } = useTranslation()

  return (
    <OrderHistoryHeader>
      <OrderHistoryTitle data-testid={'orderHistoryTitle'}>
        {t('Order.OrderHistory')}
        {!isLoading && orders.length > 0 && t('Order.OrderHistoryCount', { count: orders.length })}
      </OrderHistoryTitle>
      {!isLoading && <OrderFilter orders={orders} selectedOption={filterOption} onChange={onFilterOptionChange} />}
    </OrderHistoryHeader>
  )
}

const EmptyOrdersView: React.FC = ({}) => {
  const { t } = useTranslation()
  const { langCode } = useStoreIdentity()

  return (
    <OrderHistoryEmptyWrapper>
      <OrderHistoryEmptyText>{t('Order.NoRecord')}</OrderHistoryEmptyText>
      <OrderHistoryEmptyCTAContainer>
        {['Glasses', 'Sunglasses', 'Contacts'].map(productType => (
          <OrderHistoryStyledButtonCTA
            fullwidth
            href={`/${langCode}/${t(`Common.Shop.${productType}.Url`)}`}
            key={productType}
          >
            {t(`Common.Shop.${productType}.Cta`)}
          </OrderHistoryStyledButtonCTA>
        ))}
      </OrderHistoryEmptyCTAContainer>
    </OrderHistoryEmptyWrapper>
  )
}

interface OrdersViewProps {
  orders: Order[]
}

const OrdersView: React.FC<OrdersViewProps> = ({ orders }) => {
  return (
    <OrderHistoryContainerWrapper>
      {orders.map(order => (
        <OrderItem key={order.orderId} order={order} />
      ))}
    </OrderHistoryContainerWrapper>
  )
}

const NewOrdersView: React.FC<OrdersViewProps> = ({ orders }) => {
  return (
    <OrderHistoryContainerWrapper>
      {orders.map((order, index) => (
        <NewOrderItem key={order.orderId} order={order} index={index} />
      ))}
    </OrderHistoryContainerWrapper>
  )
}

export default OrderHistory
