import React, { FC, useEffect, useState } from 'react'

import ProductSummary from '../Cart/components/CartRecap/ProductSummary'
import { IProduct } from '../../types/product'
import ProductCtaContainer from '../ProductDetails/components/ProductCtaContainer'
import rxService from '../../foundation/apis/rx-config/rx.service'
import { useStoreIdentity } from '../../foundation/hooks/useStoreIdentity'
import useBreakpoints from '../../hooks/useBreakpoints'
import { useProductSoldOutState } from '../ProductDetails/useProductSoldOutState'
import { useCurrentProductSelection } from '../../hooks/useCurrentProductSelection'
import { getProductAlgoliaPrice } from '../../foundation/algolia/algoliaPrice'
import { wishlistItemSelector } from '@features/wishList/selector'
import { useSelector } from 'react-redux'
import { useCustomerSegmentsUtil } from '@utils/Cookies'

type WishlistItemProps = {
  product: IProduct
  removeFromWishlist: (productId) => {}
  ctaRef: React.RefObject<HTMLDivElement>
}

export const WishlistItem: FC<WishlistItemProps> = props => {
  const { product, removeFromWishlist, ctaRef } = props
  const { isRXEnabled } = useStoreIdentity()
  const { isMobile } = useBreakpoints()
  const isProductRoxable = rxService.isProductRoxable(isRXEnabled, product)
  const productInCart = useCurrentProductSelection({ currentProduct: product, partNumber: product?.partNumber })
  const customerSegment = useCustomerSegmentsUtil()
  const [itemIdWishList, setItemIdWishList] = useState<string>('')
  const { soldOutStatus } = useProductSoldOutState({
    currentProductPartNumber: product.partNumber,
    product: product,
  })

  const algoliaPrices = getProductAlgoliaPrice(customerSegment, product)

  const getItemWishList = useSelector(wishlistItemSelector(product?.partNumber ?? ''))

  useEffect(() => {
    if (product) {
      if (getItemWishList && getItemWishList.partNumber === product.partNumber) {
        setItemIdWishList(getItemWishList!.giftListItemID)
      } else {
        setItemIdWishList('')
      }
    }
  }, [product, getItemWishList])

  return (
    <ProductSummary
      bottomChildren={
        <ProductCtaContainer
          productInCart={productInCart}
          isLoading={false}
          soldOutStatus={soldOutStatus}
          currentProduct={product}
          ctaRef={ctaRef}
          addToCartFillType={'outline'}
          roxable={isProductRoxable}
          hideNeedPrescriptionText
          variant="primary"
          algoliaPrices={algoliaPrices}
        />
      }
      soldOutStatus={soldOutStatus}
      alignBottomChildren={isMobile ? 'center' : 'end'}
      product={product}
      hideProductTotal={true}
      isWishListPage={true}
      onRemoveLabelClick={() => removeFromWishlist(itemIdWishList || product.partNumber)}
    />
  )
}
