import React from 'react'
import {
  StyledFieldLabel,
  StyledFieldText
} from './Field.style'

export interface FieldProps {
  label: string
  value: string
  renderFn?: () => React.ReactNode
}

export const Field: React.FC<FieldProps> = ({ label, value, renderFn }) => {
  return (
    <div>
      <StyledFieldLabel>{label}</StyledFieldLabel>
      <StyledFieldText>{value}</StyledFieldText>
      {renderFn && <span>{renderFn()}</span>}
    </div>
  )
}
