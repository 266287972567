import { useContext, useMemo, useState } from 'react'
import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { cloneDeep, first, isEmpty } from 'lodash-es'
import { useSelector } from 'react-redux'

import config from '@configs/index'
import { PRODUCT_TYPES_MAP } from '@constants/product'
import { setLastSelectedProduct } from '@features/ui/action'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { useAppDispatch } from '@hooks/redux'
import useBreakpoints from '@hooks/useBreakpoints'
import { plpBadgesSelector } from '@redux/selectors/site'
// utils
import { isAccessories, isCLAccessories, isContactLenses } from '@utils/product'
import { getProductImageAltLabel } from '@utils/productImage'
import { getBadges, getBrand, getCLBrand, getModelCode, getModelName, getProductType } from '@utils/productAttributes'
import { getDataElementIdByModule } from './helpers'
import { transformAttachmentsToImage } from '@features/plp/algoliaUtils'
// components
import { Anchor } from '@components/UI-CSS/Anchor'
import ProductImage, {
  PLP_PRODUCT_EYEWEAR_IMAGE_FRONT_VIEW_SEQUENCE,
  PLP_PRODUCT_EYEWEAR_IMAGE_THREE_QUARTERS_VIEW_SEQUENCE,
  ProductImageProps,
} from '@components/ProductImage/ProductImage'
import { ProductBadges } from '@components/features/ProductBadges'
import { ProductPriceAlgolia } from '@views/ProductDetails/components/ProductPriceAlgolia'
import { ContextWrapperData, PlacementContext } from '../../PlacementContextWrapper/PlacementContextWrapper'
// types
import { ICMPlaceholder } from '@typesApp/cmsPlacement/CMPlaceholder'
import { IAlgoliaHit, IProduct, ProductImageUsage, isProduct } from '@typesApp/product'
import { isCMExternalProduct } from '@typesApp/teaser'
import { PlacementContextType } from '../../PlacementContextWrapper/types/PlacementContextWrapper.types'
import { ProductContext, ProductContextType } from '@components/PagesSeo/product/context/ProductContext'
// styles
import { CSSModule } from '@styles/types'
import styles from './styles/index.module.scss'
import { AddToCartCta } from '@views/ProductDetails/components/cta/AddToCartCta'
import { useAddOrderItem } from '@views/ProductDetails/hooks/useAddOrderItem'
import { useCurrentProductSelection } from '@hooks/useCurrentProductSelection'
import { addToCartBusySelector } from '@features/cartui/cartuiSlice'
import { OrderItem } from '@typesApp/order'

interface CmsCarouselProductTileProps {
  isAddToCartEnabled?: (product: IProduct | IAlgoliaHit) => void
  isAlgolia?: boolean
  isClustered?: boolean
  isPDP?: boolean
  onClick?: () => void
  styleOverride?: CSSModule
  tileIndex: number
  variant?: string
  hidePriceFromLabel?: boolean
  verticalPriceLayout?: boolean
  addToCartId?: string
  addToCartTestId?: string
  alignPriceCenter?: boolean
}

const CmsCarouselProductTile = (props: CmsCarouselProductTileProps) => {
  const {
    isAddToCartEnabled,
    isAlgolia,
    isClustered,
    isPDP,
    onClick,
    styleOverride,
    tileIndex,
    variant,
    hidePriceFromLabel,
    verticalPriceLayout,
    addToCartId,
    addToCartTestId,
    alignPriceCenter,
  } = props

  const productContext = useContext<ProductContextType>(ProductContext)
  const product = productContext?.product

  const isExternal = isCMExternalProduct(product)
  const isProductValid = isProduct(product)

  const baseProduct: IProduct = useMemo(() => {
    if (isExternal) return product.productData
    if (isProductValid) return product
    return {} as IProduct
  }, [product])

  let productUrl = ''
  if (isAlgolia) {
    productUrl = (product as IAlgoliaHit).url ?? ''
  } else if (isExternal) {
    productUrl = product.formattedUrl
  } else if (isProductValid) {
    productUrl = product.seo?.href ?? ''
  }

  const context = useContext<ContextWrapperData>(PlacementContext)
  const { data: contextData } = context as ContextWrapperData<PlacementContextType>
  const placement = contextData?.placement
  const firstPlacementItem = 'items' in placement ? placement.items?.[0] : null
  const viewType = firstPlacementItem?.viewtype ?? ''
  const idAction = (firstPlacementItem as ICMPlaceholder)?.idAction
  const siteName = config.name

  const dispatch = useAppDispatch()
  const { t: translate } = useTranslation()
  const { isRXEnabled } = useStoreIdentity()
  const { isViewportWidthUnder426, isMobile, isTablet, isDesktop, isViewportWidthAbove1440 } = useBreakpoints()
  const badgeSelector = useSelector(plpBadgesSelector)
  const isAddToCartBusy = useSelector(addToCartBusySelector)

  const clusters = useMemo(
    () =>
      cloneDeep<IProduct[]>(
        (baseProduct?.cluster ?? (isClustered ? baseProduct?.cluster : baseProduct?.cluster?.slice(0, 1))) || []
      ),
    [baseProduct?.cluster, isClustered]
  )

  const selectedViewCluster = useMemo(() => (clusters?.length ? clusters[0] : baseProduct), [clusters, baseProduct])

  const imgSequenceProps = {
    sequence: PLP_PRODUCT_EYEWEAR_IMAGE_THREE_QUARTERS_VIEW_SEQUENCE,
    usage: 'PLP' as ProductImageUsage,
  }
  const imgHoverSequenceProps = {
    sequence: PLP_PRODUCT_EYEWEAR_IMAGE_FRONT_VIEW_SEQUENCE,
    usage: 'PLP' as ProductImageUsage,
  }

  const [hoverImage, setHoverImage] = useState<ProductImageProps>(imgSequenceProps)
  const productType =
    (!isEmpty(baseProduct) && PRODUCT_TYPES_MAP[getProductType(baseProduct).toLowerCase()]) || 'frames'

  const clusterLength = clusters?.length ?? 0
  const name = getModelName(baseProduct)
  const brand = getBrand(baseProduct)
  const clBrand = getCLBrand(baseProduct)
  const modelCode = getModelCode(baseProduct)

  const productImageWidth = useMemo<number>(() => {
    const isCMS = variant === 'cms-products-module'
    switch (true) {
      case isCMS:
        return 400
      case isViewportWidthUnder426:
        return 213
      case isMobile:
        return 296
      case isTablet:
      case isViewportWidthAbove1440:
      case isDesktop:
        return 600
      default:
        return 260
    }
  }, [isViewportWidthUnder426, isMobile, isTablet, isDesktop, isViewportWidthAbove1440, variant])

  const productImageProps = useMemo(
    () => ({
      alt: getProductImageAltLabel(baseProduct, true),
      draggable: false,
      sequence: PLP_PRODUCT_EYEWEAR_IMAGE_THREE_QUARTERS_VIEW_SEQUENCE,
      usage: 'PLP' as ProductImageUsage,
      width: productImageWidth,
      onClick: () => {
        dispatch(setLastSelectedProduct(baseProduct?.id || ''))
      },
    }),
    [baseProduct, dispatch, productImageWidth]
  )

  const productImageAttachments = useMemo(() => {
    const attachments = selectedViewCluster?.attachments
    if (isAlgolia) {
      return transformAttachmentsToImage(attachments)
    }
    return attachments
  }, [isAlgolia, selectedViewCluster])

  const badgeList = useMemo(() => {
    let currentSku: IProduct | undefined = { ...baseProduct }
    if (clusterLength) {
      const items = selectedViewCluster?.sKUs != null ? selectedViewCluster?.sKUs : selectedViewCluster?.items
      if (items) {
        currentSku = first(items)
      } else {
        currentSku = baseProduct
      }
    }

    if (!currentSku) {
      return []
    }

    const badges = getBadges(currentSku, translate, badgeSelector, isRXEnabled)
    return [badges.primaryBadge, badges.secondaryBadges]
  }, [badgeSelector, baseProduct, clusterLength, isRXEnabled, selectedViewCluster, translate])

  const [primaryBadge, secondaryBadge] = badgeList

  const ariaLabel = !isEmpty(primaryBadge) ? primaryBadge : `${name}_${modelCode}`
  const ariaLabelFooter = !isEmpty(primaryBadge) ? primaryBadge : name

  const tileDataElementId = getDataElementIdByModule({ idAction, tileIndex, viewType })
  const tileDataDescription = `${siteName}_${name}_${modelCode}`

  const shouldZoomOnHover = isContactLenses(productType) || isCLAccessories(productType) || isAccessories(productType)

  const onProductTileMouseEnter = () => {
    if (isViewportWidthUnder426 || isMobile || isTablet) return
    if (selectedViewCluster && !shouldZoomOnHover) {
      setHoverImage(imgHoverSequenceProps)
    }
  }

  const onProductTileMouseLeave = () => {
    if (isViewportWidthUnder426 || isMobile || isTablet) return
    if (selectedViewCluster) {
      setHoverImage(imgSequenceProps)
    }
  }

  const currentOrderItem = useCurrentProductSelection({
    currentProduct: product,
    partNumber: (product as IAlgoliaHit)?.partnumberId ?? '',
  }) ?? baseProduct as unknown as OrderItem

  const { addToCart } = useAddOrderItem(currentOrderItem, product, (product as IAlgoliaHit)?.partnumberId)

  if (isEmpty(baseProduct) || product == null) return null

  return (
    <div
      className={clsx(styles.wrapper, styleOverride && styleOverride.wrapper)}
      onMouseEnter={onProductTileMouseEnter}
      onMouseLeave={onProductTileMouseLeave}
      aria-label={ariaLabel}
      data-description={tileDataDescription}
      data-element-id={tileDataElementId}
    >
      <div className={clsx(styleOverride && styleOverride.subwrapper, styles.subwrapper)} onClick={onClick}>
        <ProductBadges
          primaryBadge={primaryBadge}
          secondaryBadges={secondaryBadge}
          styleOverride={{ ...styles, ...(styleOverride ?? {}) }}
        />

        <div className={clsx(styleOverride && styleOverride.imageContainer, styles.imageContainer)}>
          <Anchor
            aria-label={ariaLabel}
            className={styles.imageAnchor}
            data-description={tileDataDescription}
            data-element-id={tileDataElementId}
            href={productUrl}
          >
            <div
              className={clsx(styleOverride && styleOverride.imageWrapper, styles.imageWrapper, {
                [styles.imageZoomOnHover]: shouldZoomOnHover,
              })}
            >
              <ProductImage attachments={productImageAttachments} {...productImageProps} {...hoverImage} />
            </div>
          </Anchor>
        </div>
      </div>
      <div
        className={clsx(styleOverride && styleOverride.footerWrapper, styles.footerWrapper)}
        product-type={productType}
      >
        <Anchor
          aria-label={ariaLabelFooter}
          className={styles.imageAnchor}
          data-description={tileDataDescription}
          data-element-id={tileDataElementId}
          href={productUrl}
        >
          <div className={clsx(styleOverride && styleOverride.footer, styles.footer)}>
            {!isContactLenses(productType) && !isCLAccessories(productType) ? (
              <div className={clsx(styleOverride && styleOverride.description, styles.description, !!isPDP && 'isPDP')}>
                <>
                  <div
                    className={clsx(styleOverride && styleOverride.productName, styles.productName)}
                    product-type={productType}
                  >
                    {name ? name : '\u00A0'}
                  </div>
                  <div className={clsx(styleOverride && styleOverride.brandName, styles.brandName)}>{brand}</div>
                </>
                <ProductPriceAlgolia
                  isCompact
                  isVerticalLayout={!!isMobile && !verticalPriceLayout}
                  ignorePageType
                  hideFromLabel={hidePriceFromLabel}
                  alignCenter={alignPriceCenter}
                />
              </div>
            ) : (
              <div className={clsx(styleOverride && styleOverride.description, styles.description, !!isPDP && 'isPDP')}>
                <div
                  className={clsx(styleOverride && styleOverride.productName, styles.productName)}
                  product-type={productType}
                >
                  {clBrand}
                </div>

                <div className={clsx(styleOverride && styleOverride.brandName, styles.brandName)}>
                  {name ? name : '\u00A0'}
                </div>

                <ProductPriceAlgolia
                  isCompact
                  isVerticalLayout={!!isMobile && !verticalPriceLayout}
                  ignorePageType
                  alignCenter={alignPriceCenter}
                />
              </div>
            )}
          </div>
        </Anchor>

        {!!isAddToCartEnabled?.(product) && (
          <AddToCartCta
            id={addToCartId ?? ''}
            addToCartFillType="fill"
            busy={isAddToCartBusy}
            dataTestid={addToCartTestId ?? ''}
            disabled={isAddToCartBusy}
            product={product}
            onClick={addToCart}
            variant="secondary"
          />
        )}
      </div>
    </div>
  )
}

CmsCarouselProductTile.defaultProps = {
  isAlgolia: false,
  isClustered: true,
}

export { CmsCarouselProductTile }
