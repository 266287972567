import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'next-i18next'
import { Checkbox } from '@mui/material'
import {
  SubscriptionButton,
  SubscriptionFormControlLabel,
  SubscriptionButtonContainer,
  SubscriptionFormContainer,
  SubscriptionTitle,
  SubscriptionStatusText,
} from './SubscriptionForm.style'
import { useStoreIdentity } from '../../../../foundation/hooks/useStoreIdentity'
import { UnsubscribeModal } from './UnsubscribeModal'
import { SubmitAction } from '../SubmitAction/SubmitAction'
import { StyledAnchor } from '../../../../components/UI'
import { SubmitSuccessMessage } from '../SubmitSuccessMessage/SubmitSuccessMessage'

export interface SubscriptionFormProps {
  subscribed?: boolean
  email?: string
  onSubscribe: () => void
  onUnsubscribe: () => void
  onReset: () => void
  isBusy: boolean
  isSubmitSuccess: boolean
}

export const SubscriptionForm: React.FC<SubscriptionFormProps> = ({
  subscribed = false,
  email,
  onSubscribe,
  onUnsubscribe,
  onReset,
  isBusy,
  isSubmitSuccess,
}) => {
  const [selected, setSelected] = useState<boolean>(false)
  const { t: translate } = useTranslation('', { keyPrefix: 'SubscriptionSection' })

  useEffect(() => {
    if (!subscribed) {
      setSelected(false)
    }
  }, [subscribed])

  const onSelectionChange = () => {
    setSelected(!selected)
    onReset()
  }

  return (
    <SubscriptionFormContainer>
      <SubscriptionTitle>{translate(`Title${subscribed ? 'Subscribed' : ''}`)}</SubscriptionTitle>
      <SubscriptionStatusText>
        {translate(subscribed ? 'SubscribedMessage' : 'NotSubscribedMessage')}
      </SubscriptionStatusText>

      {subscribed && <SubscribedSection isBusy={isBusy} email={email} onSubmit={onUnsubscribe} />}

      {!subscribed && (
        <NotSubscribedSection
          isBusy={isBusy}
          isSubmitSuccess={isSubmitSuccess}
          selected={selected}
          onSelectionChange={onSelectionChange}
          onSubmit={onSubscribe}
        />
      )}

      {isSubmitSuccess && <SubmitSuccessMessage />}
    </SubscriptionFormContainer>
  )
}

export interface SubscribedSectionProps {
  email?: string
  isBusy: boolean
  onSubmit: () => void
}

const SubscribedSection: React.FC<SubscribedSectionProps> = ({ email, isBusy, onSubmit }) => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const { t: translate } = useTranslation()

  const handleSubmit = () => {
    setOpenModal(false)
    onSubmit()
  }

  return (
    <SubscriptionButtonContainer>
      <SubscriptionStatusText>{email}</SubscriptionStatusText>
      <SubscriptionButton
        data-testid={'emailSubscribe'}
        disabled={isBusy}
        onClick={() => setOpenModal(true)}
        variant="secondary"
      >
        {translate('SubscriptionSection.Labels.UnsubscribeCTA')}
      </SubscriptionButton>
      <UnsubscribeModal
        data-testid={'emailUnsubscribe'}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleConfirm={handleSubmit}
      />
    </SubscriptionButtonContainer>
  )
}

export interface NotSubscribedSectionProps {
  selected: boolean
  isBusy: boolean
  isSubmitSuccess: boolean
  onSubmit: () => void
  onSelectionChange: () => void
}

const NotSubscribedSection: React.FC<NotSubscribedSectionProps> = ({
  selected,
  isBusy,
  isSubmitSuccess,
  onSubmit,
  onSelectionChange,
}) => {
  const { langCode } = useStoreIdentity()
  const { t: translate } = useTranslation('', { keyPrefix: 'SubscriptionSection' })

  return (
    <>
      <SubscriptionFormControlLabel
        control={<Checkbox onChange={onSelectionChange} />}
        label={
          <Trans i18nKey="SubscriptionSection.Labels.SubscribeCheckbox">
            <StyledAnchor href={translate('TermsAndConditionsLink', { langCode })} target="_blank" external />
            <StyledAnchor href={translate('PrivacyLink', { langCode })} target="_blank" external />
          </Trans>
        }
      />

      <SubscriptionButtonContainer>
        <SubmitAction
          isBusy={isBusy}
          isSuccess={isSubmitSuccess}
          disabled={!selected}
          onClick={onSubmit}
          label={translate('Labels.SubscribeCTA')}
        />
      </SubscriptionButtonContainer>
    </>
  )
}
