import { doneEditingCartItem } from '@features/cartui/cartuiSlice'
import { CART } from '@constants/routes'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { useStoreIdentity } from '@foundation/hooks/useStoreIdentity'
import { useCreatePrescriptionDetailsMutation } from '@features/order/query'
import { productContactLensesDataSelector, prescriptionDetailsSelector } from '@features/product/selector'
import { CartOrder } from '@features/order/thunks/addContactLenses'
import { useRxTaxExemption } from '@foundation/hooks/useRxTaxExemption'

type OnContactLensAddToCartProps = {
  order?: CartOrder
}

type OnContactLensAddToCart = (props: OnContactLensAddToCartProps) => void

export function useHandleContactLensCallback() {
  const router = useRouter()
  const dispatch = useDispatch()
  const { langCode } = useStoreIdentity()

  const onContactLensAddToCart: OnContactLensAddToCart = async data => {
    const { order } = data

    if (!order) return

    dispatch(doneEditingCartItem())

    router.push(`/${langCode}/${CART}`)
  }

  return { onContactLensAddToCart }
}
