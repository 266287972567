import { OrderItemContactLensData } from '@typesApp/cart'

type IsNonPrescriptionColoredLensParams = {
  orderItemClData: OrderItemContactLensData
}

export function isNonPrescriptionColoredLens(params: IsNonPrescriptionColoredLensParams) {
  const { orderItemClData } = params

  if (orderItemClData['left']?.['data']?.['x_color'] || orderItemClData['right']?.['data']?.['x_color']) {
    if (orderItemClData['left'].orderItemId === null) {
      let isRightNonRx = orderItemClData['right'].data?.['x_spherePower'] === '+0.00'
      return isRightNonRx
    }

    if (orderItemClData['right'].orderItemId === null) {
      let isleftNonRx = orderItemClData['left'].data?.['x_spherePower'] === '+0.00'
      return isleftNonRx
    }

    let isRightNonRx = orderItemClData['right'].data?.['x_spherePower'] === '+0.00'
    let isleftNonRx = orderItemClData['left'].data?.['x_spherePower'] === '+0.00'

    return isleftNonRx && isRightNonRx
  }

  return false
}
